
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import url from '../../configs/url';


const json = {
  1: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P41-E1',
    audio: '',
    video: url.cdnUrl + '/Animation - Unit 3 grammar 1 trang 41.mp4',
    component: D1,
    height: 40,
    fontSize: 19,
    exerciseKey: 'img/FriendsPlus/Page41/E1/Key/Key.png',
    titleImage: '',
    titleQuestion: [{ color: '#11C3DE', num: '1', title: 'Write the superlative form of the adjectives in the table.' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page41/E1/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page41/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page41/E1/3.jpg', input: true, answer: 'the weirdest' },
        { url: 'img/FriendsPlus/Page41/E1/4.jpg', input: true, answer: 'the slowest' },
        { url: 'img/FriendsPlus/Page41/E1/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page41/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page41/E1/7.jpg', input: true, answer: 'the rarest' },
        { url: 'img/FriendsPlus/Page41/E1/8.jpg', input: true, answer: 'the safest' },
        { url: 'img/FriendsPlus/Page41/E1/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page41/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page41/E1/11.jpg', input: true, answer: 'the ugliest' },
        { url: 'img/FriendsPlus/Page41/E1/12.jpg', input: true, answer: 'the prettiest' },
        { url: 'img/FriendsPlus/Page41/E1/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page41/E1/14.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page41/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page41/E1/16.jpg', input: true, answer: 'the biggest' },
        { url: 'img/FriendsPlus/Page41/E1/17.jpg', input: true, answer: 'the hottest' },
        { url: 'img/FriendsPlus/Page41/E1/18.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page41/E1/19.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page41/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page41/E1/21.jpg', input: true, answer: 'the most common' },
        { url: 'img/FriendsPlus/Page41/E1/22.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page41/E1/23.jpg' },
        { url: 'img/FriendsPlus/Page41/E1/24.jpg', input: true, answer: 'the most expensive' },
        { url: 'img/FriendsPlus/Page41/E1/25.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page41/E1/26.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page41/E1/27.jpg' },
        { url: 'img/FriendsPlus/Page41/E1/28.jpg', input: true, answer: 'the best' },
        { url: 'img/FriendsPlus/Page41/E1/29.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page41/E1/30.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  2: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P41-E2',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page41/E2/Key/1.png',
    // typeInput:'center',
    titleImage: 'img/FriendsPlus/Page41/E2/title.png',
    titleQuestion: [{ num: '2', title: ' Complete the text using the superlative form of the adjectives in brackets. ' }],
    questionImage: [ // Row

      [
        // Column
        { url: 'img/FriendsPlus/Page41/E2/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page41/E2/2.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page41/E2/3.jpg' },
        { url: 'img/FriendsPlus/Page41/E2/4.jpg', input: true, answer: 'hottest' },
        { url: 'img/FriendsPlus/Page41/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page41/E2/6.jpg', input: true, answer: 'warmest' },
        { url: 'img/FriendsPlus/Page41/E2/7.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page41/E2/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page41/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page41/E2/10.jpg', input: true, answer: 'coldest' },
        { url: 'img/FriendsPlus/Page41/E2/11.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page41/E2/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page41/E2/13.jpg' },
        { url: 'img/FriendsPlus/Page41/E2/14.jpg', input: true, answer: 'best' },
        { url: 'img/FriendsPlus/Page41/E2/15.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page41/E2/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page41/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page41/E2/18.jpg', input: true, answer: 'highest' },
        { url: 'img/FriendsPlus/Page41/E2/19.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page41/E2/20.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page41/E2/21.jpg' },
        { url: 'img/FriendsPlus/Page41/E2/22.jpg', input: true, answer: 'the most interesting' },
        { url: 'img/FriendsPlus/Page41/E2/23.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page41/E2/24.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  3: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P41-E3',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page41/E3/1.png",
    // titleQuestion: [{ num: '3', title: 'Read the study strategy. Then write five sentences with your ideas. Use the adjectives and the topics in the boxes. Compare with a partner.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P41-E4',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page41/E4/1.png",
    // titleQuestion: [{ num: '3', title: 'Read the study strategy. Then write five sentences with your ideas. Use the adjectives and the topics in the boxes. Compare with a partner.' }],
    questionImage: [],
    questions: []
  },
}
export default json