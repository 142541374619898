import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';


const json = {

  1: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P94-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page94/E1/Key/answerKey.png',
    // questionImage: [],
    questionImage: [
      [//1
        { url: 'img/FriendsPlus/Page94/E1/1.jpg' },
      ],
      [//2
        { url: 'img/FriendsPlus/Page94/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page94/E1/3.jpg', input: true, answer: 'the dark' },
        { url: 'img/FriendsPlus/Page94/E1/4.jpg' },
      ],
      [//3
        { url: 'img/FriendsPlus/Page94/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page94/E1/6.jpg', input: true, answer: 'run away' },
        { url: 'img/FriendsPlus/Page94/E1/7.jpg' },
      ],
      [//4
        { url: 'img/FriendsPlus/Page94/E1/8.jpg' },
      ],
      [//5
        { url: 'img/FriendsPlus/Page94/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page94/E1/10.jpg', input: true, answer: 'pipe' },
        { url: 'img/FriendsPlus/Page94/E1/11.jpg' },
      ],
      [//6
        { url: 'img/FriendsPlus/Page94/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page94/E1/13.jpg', input: true, answer: 'sleep out' },
        { url: 'img/FriendsPlus/Page94/E1/14.jpg' },
      ],
      [//7
        { url: 'img/FriendsPlus/Page94/E1/15.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P94-E2',
    audio: '',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page94/E2/Key/answerKey.png',
    // inputSize: 500,
    titleImage: "img/FriendsPlus/Page94/E2/title.jpg",
    imgSize: '80%',
    // inputSize: 400,
    // textCenter: true,
    titleQuestion: [{ color: '#BF215C', num: '2', title: 'Read the text. Then answer the questions.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> Why couldn’t Tom sleep? #. </p>" +
          "<p><span style='font-weight:600'>2</span> Why did Tom want to go to Jackson’s Island? #. </p>" +
          "<p><span style='font-weight:600'>3</span> How many boys went to the island? #. </p>" +
          "<p><span style='font-weight:600'>4</span> Where did they make a fire? #. </p>" +
          "<p><span style='font-weight:600'>5</span> What did they think of their dinner? #. </p>",
        answer: [
          "He was scared / He saw Injun Joe's face in the dark",
          "He wanted to get away / He wanted to do something exciting",
          'Three',
          "On Jackson's Island",
          "They thought it was very good / They thought it was the best dinner in the world"
        ],
        type: 'longAnwser'
      },
    ]
  },

  3: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P94-E3',
    audio: 'img/FriendsPlus/Page94/E3/Audio/G6 SB Track 2.30.WAV',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page94/E3/Key/answerKey.png',
    titleImage: '',
    // titleQuestion: [{ num: '1', title: 'CLook at the questions about Robert Wadlow in the text on page 90 again and complete the table. Which word is in all of the questions?', color: '#553f9f' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page94/E3/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page94/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page94/E3/3.jpg', input: true, answer: 'Muff Potter/Injun Joe' },
        { url: 'img/FriendsPlus/Page94/E3/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page94/E3/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page94/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page94/E3/7.jpg', input: true, answer: 'Mississippi River' },
        { url: 'img/FriendsPlus/Page94/E3/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page94/E3/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page94/E3/10.jpg' },
        { url: 'img/FriendsPlus/Page94/E3/11.jpg', input: true, answer: "Jackson's Island" },
        { url: 'img/FriendsPlus/Page94/E3/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page94/E3/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page94/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page94/E3/15.jpg', input: true, answer: 'Huck Finn' },
        { url: 'img/FriendsPlus/Page94/E3/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page94/E3/17.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P94-E4',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page94/E4/1.png",
    // imgSize: '90%',
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json