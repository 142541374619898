import T2 from "../../components/ExcerciseTypes/TypeIn/T2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import LT1 from "../../components/ExcerciseTypes/LineTo/LT1";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/UnderLine";

const json = {
  1: {
    // Exercise num

    unit: "Option Song 1",
    id: "SB6-OPS1-P118-E1",
    audio: "img/FriendsPlus/Page118/E1/Audio/G6 SB Track 3.21.mp3",
    video: "",
    component: T6,
    // inputSize: 500,
    // titleImage: "img/FriendsPlus/Page79/E2/1.png",
    imgSize: "60%",
    exerciseKey: "img/FriendsPlus/Page118/E1/Key/answerKey.png",
    // inputSize:400,
    titleQuestion: [
      {
        num: "1",
        title: "Listen to the song. How many places are mentioned?",
        color: "#BF2A32",
      },
    ],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'> How many places are mentioned?</span> </p>" +
          "<p># </p>",
        answer: ["eighteen / 18"],
        type: "longAnwser",
      },
    ],
  },

  2: {
    // Exercise num

    unit: "Option Song 1",
    id: "SB6-OPS1-P118-E2",
    audio: "img/FriendsPlus/Page118/E1/Audio/G6 SB Track 3.21.mp3",
    video: "",
    component: DesignUnderLine,
    totalInput: 7,
    // titleImage: 'img/FriendsPlus/Page110/E2/1.png',
    exerciseKey: "img/FriendsPlus/Page118/E2/Key/answerKey.png",
    // titleQuestion: [{ num: '2', title: 'Check the meanings of the words in blue. Then choose the correct words in the quiz.' }],
    questionImage: [
      // Row

      [{ url: "img/FriendsPlus/Page118/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page118/E2/2.jpg" },
        { url: "img/FriendsPlus/Page118/E2/3.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page118/E2/4.jpg" },
        { url: "img/FriendsPlus/Page118/E2/5.jpg", input: 1, isCorrect: false },
        { url: "img/FriendsPlus/Page118/E2/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page118/E2/7.jpg" }],
      [
        { url: "img/FriendsPlus/Page118/E2/8.jpg" },
        { url: "img/FriendsPlus/Page118/E2/9.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page118/E2/10.jpg" },
        {
          url: "img/FriendsPlus/Page118/E2/11.jpg",
          input: 2,
          isCorrect: false,
        },
        { url: "img/FriendsPlus/Page118/E2/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page118/E2/13.jpg" }],
      [
        { url: "img/FriendsPlus/Page118/E2/14.jpg" },
        { url: "img/FriendsPlus/Page118/E2/15.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page118/E2/16.jpg" },
        {
          url: "img/FriendsPlus/Page118/E2/17.jpg",
          input: 3,
          isCorrect: false,
        },
        { url: "img/FriendsPlus/Page118/E2/18.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page118/E2/19.jpg" }],
      [
        { url: "img/FriendsPlus/Page118/E2/20.jpg" },
        { url: "img/FriendsPlus/Page118/E2/21.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page118/E2/22.jpg" },
        {
          url: "img/FriendsPlus/Page118/E2/23.jpg",
          input: 4,
          isCorrect: false,
        },
        { url: "img/FriendsPlus/Page118/E2/24.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page118/E2/25.jpg" }],
      [
        { url: "img/FriendsPlus/Page118/E2/26.jpg" },
        { url: "img/FriendsPlus/Page118/E2/27.jpg", input: 5, isCorrect: true },
        {
          url: "img/FriendsPlus/Page118/E2/28.jpg",
          input: 5,
          isCorrect: false,
        },
        { url: "img/FriendsPlus/Page118/E2/29.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page118/E2/30.jpg" }],
      [
        { url: "img/FriendsPlus/Page118/E2/31.jpg" },
        { url: "img/FriendsPlus/Page118/E2/32.jpg", input: 6, isCorrect: true },
        { url: "img/FriendsPlus/Page118/E2/33.jpg" },
        {
          url: "img/FriendsPlus/Page118/E2/34.jpg",
          input: 6,
          isCorrect: false,
        },
        { url: "img/FriendsPlus/Page118/E2/35.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page118/E2/36.jpg" }],
      [
        { url: "img/FriendsPlus/Page118/E2/37.jpg" },
        { url: "img/FriendsPlus/Page118/E2/38.jpg", input: 7, isCorrect: true },
        { url: "img/FriendsPlus/Page118/E2/39.jpg" },
        {
          url: "img/FriendsPlus/Page118/E2/40.jpg",
          input: 7,
          isCorrect: false,
        },
        { url: "img/FriendsPlus/Page118/E2/41.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page118/E2/42.jpg" }],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },

  3: {
    // Exercise num

    unit: "Option Song 1",
    id: "SB6-OPS1-P118-E3",
    audio:
      "img/FriendsPlus/Page118/E3/Audio/Friends Plus for Vietnam G6 SB Track 3.21.mp3",
    video: "",
    component: LT1,
    exerciseKey: "img/FriendsPlus/Page118/E3/Key/answerKey.png",
    titleImage: "img/FriendsPlus/Page118/E3/title.jpg",
    // titleQuestion: [{ num: '2', title: 'Listen again and choose the correct words in the song.', color: '#db323d' }],
    questionImage: [],
    questions: [
      {
        right: [
          {
            id: 1,
            text: "1. I’m popular",
          },
          {
            id: 2,
            text: "2. The station is near",
          },
          {
            id: 3,
            text: "3. There’s a park",
          },
          {
            id: 4,
            text: "4. My city",
          },
          {
            id: 5,
            text: "5. I’m no",
          },
        ],
        left: [
          {
            id: 1,
            text: "a. is friendly.",
          },
          {
            id: 2,
            text: "b. my house.",
          },
          {
            id: 3,
            text: "c. at the school.",
          },
          {
            id: 4,
            text: "d. fool.",
          },
          {
            id: 5,
            text: "e. where we play.",
          },
        ],
        answer: [
          {
            right: 1,
            left: 3,
          },
          {
            right: 2,
            left: 2,
          },
          {
            right: 3,
            left: 5,
          },
          {
            right: 4,
            left: 1,
          },
          {
            right: 5,
            left: 4,
          },
        ],
        type: "",
      },
    ],
  },

  4: {
    // Exercise num

    unit: "Option Song 1",
    id: "SB6-OPS1-P118-E4",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page118/E4/Key/answerKey.png",
    // inputSize: 500,
    // titleImage: "img/FriendsPlus/Page79/E2/1.png",
    imgSize: "60%",
    inputSize: 200,
    titleQuestion: [
      {
        num: "4",
        title:
          "Look at the song again.Are the sentences True or False? Correct the false sentences.",
        color: "#BF2A32",
      },
    ],
    questionImage: [],
    questions: [
      {
        title:
          "<p>I take a bus to the square.</p>" +
          "<p style='font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px'>False. I walk to the square.</p>" +
          "<p><span style='font-weight:600'>1</span> I see my friends at the shops. # </p>" +
          "<p><span style='font-weight:600'>2</span> I live between the market and the park. # </p>" +
          "<p><span style='font-weight:600'>3</span> My friends are quiet. # </p>" +
          "<p><span style='font-weight:600'>4</span> Tea is cheaper in the restaurant. # </p>" +
          "<p><span style='font-weight:600'>5</span> I walk from the station to my house. # </p>",
        answer: ["True", "True", "False", "False", "True"],
        type: "longAnwser",
      },
    ],
  },
  5: {
    // Exercise num

    unit: "Option Song 1",
    id: "SB6-OPS1-P118-E5",
    audio: "",
    video: "",
    component: T2,
    titleImage: "img/FriendsPlus/Page118/E5/1.png",
    imgSize: "70%",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: "longAnwser",
      },
    ],
  },
};
export default json;
