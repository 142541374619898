// import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import S7 from '../../components/ExcerciseTypes/Select/Select7';

const json = {
  1: { // Exercise num
    unit: 'Unit 4',
    id: 'SB6-U4-P48-E1',
    audio: 'img/FriendsPlus/Page48/E1/Audio/G6 SB Track 1.39.mp3',
    video: '',
    component: S7,
    width: 180,
    exerciseKey: 'img/FriendsPlus/Page48/E1/Key/Key.png',
    titleImage: 'img/FriendsPlus/Page48/E1/title.jpg',
    // titleQuestion: [{ num: '1', title: 'Match the words in the box with pictures 1–16. Then listen and check. Which sports do you like?', color: '#115D98'}],
    questions: [
      {
        no: "1",
        text: "PE",
        image: "img/FriendsPlus/Page48/E1/1.jpg",
      },
      {
        no: "2",
        text: "geography",
        image: "img/FriendsPlus/Page48/E1/2.jpg",
      },
      {
        no: "3",
        text: "maths",
        image: "img/FriendsPlus/Page48/E1/3.jpg",
      },
      {
        no: "4",
        text: "science",
        image: "img/FriendsPlus/Page48/E1/4.jpg",
      },
      {
        no: "5",
        text: "ICT",
        image: "img/FriendsPlus/Page48/E1/5.jpg",
      },
      {
        no: "6",
        text: "Spanish",
        image: "img/FriendsPlus/Page48/E1/6.jpg",
      },
      {
        no: "7",
        text: "history",
        image: "img/FriendsPlus/Page48/E1/7.jpg",
      },
      {
        no: "8",
        text: "English",
        image: "img/FriendsPlus/Page48/E1/8.jpg",
      },
      {
        no: "9",
        text: "art",
        image: "img/FriendsPlus/Page48/E1/9.jpg",
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 4',
    id: 'SB6-U4-P48-E2',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page48/E2/1.png",
    imgSize: '100%',
    titleQuestion: [{ color: '#005293', num: '2', title: 'Work in pairs. Ask and answer the questions in the School questionnaire.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json