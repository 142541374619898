import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/UnderLine";
import T2 from "../../components/ExcerciseTypes/TypeIn/T2";

const json = {
  1: {
    // Exercise num
    unit: "Option 1",
    id: "SB6-OPE1-P110-E1",
    audio: "img/FriendsPlus/Page110/E1/Audio/G6 SB Track 3.02.mp3",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page110/E1/Key/1.png",
    titleImage: "",
    titleQuestion: [
      {
        num: "1",
        title: "Listen to the dialogue and write the correct answers.",
        color: "#f67f24",
      },
    ],
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page110/E1/1.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page110/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page110/E1/3.jpg",
          input: true,
          answer: "Kate",
        },
        { url: "img/FriendsPlus/Page110/E1/4.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page110/E1/5.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page110/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page110/E1/7.jpg",
          input: true,
          answer: "Brown",
        },
        { url: "img/FriendsPlus/Page110/E1/8.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page110/E1/9.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page110/E1/10.jpg" },
        {
          url: "img/FriendsPlus/Page110/E1/11.jpg",
          input: true,
          answer: "England",
        },
        { url: "img/FriendsPlus/Page110/E1/12.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page110/E1/13.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page110/E1/14.jpg" },
        {
          url: "img/FriendsPlus/Page110/E1/15.jpg",
          input: true,
          answer: "January",
        },
        { url: "img/FriendsPlus/Page110/E1/16.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page110/E1/17.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page110/E1/18.jpg" },
        {
          url: "img/FriendsPlus/Page110/E1/19.jpg",
          input: true,
          answer: "Eleven",
        },
        { url: "img/FriendsPlus/Page110/E1/20.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page110/E1/21.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page110/E1/22.jpg" },
        {
          url: "img/FriendsPlus/Page110/E1/23.jpg",
          input: true,
          answer: "four",
        },
        { url: "img/FriendsPlus/Page110/E1/24.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page110/E1/25.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },

  2: {
    // Exercise num
    unit: "Option 1",
    id: "SB6-OPE1-P110-E2",
    audio: "img/FriendsPlus/Page110/E2/Audio/G6 SB Track 3.03.mp3",
    video: "",
    component: DesignUnderLine,
    totalInput: 5,
    // titleImage: 'img/FriendsPlus/Page110/E2/1.png',
    exerciseKey: "img/FriendsPlus/Page110/E2/Key/1.png",
    // titleQuestion: [{ num: '2', title: 'Check the meanings of the words in blue. Then choose the correct words in the quiz.' }],
    questionImage: [
      // Row

      [{ url: "img/FriendsPlus/Page110/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page110/E2/2.jpg" },
        { url: "img/FriendsPlus/Page110/E2/3.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page110/E2/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page110/E2/5.jpg" },
        { url: "img/FriendsPlus/Page110/E2/6.jpg", input: 2, isCorrect: false },
        { url: "img/FriendsPlus/Page110/E2/7.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page110/E2/8.jpg" },
        { url: "img/FriendsPlus/Page110/E2/9.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page110/E2/10.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page110/E2/11.jpg" },
        { url: "img/FriendsPlus/Page110/E2/12.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page110/E2/13.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page110/E2/14.jpg" },
        {
          url: "img/FriendsPlus/Page110/E2/15.jpg",
          input: 5,
          isCorrect: false,
        },
        { url: "img/FriendsPlus/Page110/E2/16.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page110/E2/17.jpg" },
        { url: "img/FriendsPlus/Page110/E2/18.jpg", input: 6, isCorrect: true },
        { url: "img/FriendsPlus/Page110/E2/19.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page110/E2/20.jpg" },
        { url: "img/FriendsPlus/Page110/E2/21.jpg", input: 7, isCorrect: true },
        { url: "img/FriendsPlus/Page110/E2/22.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page110/E2/23.jpg" }],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },

  3: {
    // Exercise num
    unit: "Option 1",
    id: "SB6-OPE1-P110-E3",
    // audio: 'img/FriendsPlus/Page110/E1/Audio/G6 SB Track 3.02.mp3',
    video: "",
    component: D1,
    fontSize: 24,
    height: 27,
    exerciseKey: "img/FriendsPlus/Page110/E3/Key/1.png",
    titleImage: "",
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen to the conversation again and complete the table about Tobias.",
        color: "#fa8013",
      },
    ],
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page110/E3/1.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page110/E3/2.jpg" },
        {
          url: "img/FriendsPlus/Page110/E3/3.jpg",
          input: true,
          answer: "twelve",
        },
        { url: "img/FriendsPlus/Page110/E3/4.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page110/E3/5.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page110/E3/6.jpg" },
        {
          url: "img/FriendsPlus/Page110/E3/7.jpg",
          input: true,
          answer: "Munich in Germany",
        },
        { url: "img/FriendsPlus/Page110/E3/8.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page110/E3/9.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page110/E3/10.jpg" },
        {
          url: "img/FriendsPlus/Page110/E3/11.jpg",
          input: true,
          answer: "quite big, but very friendly",
        },
        { url: "img/FriendsPlus/Page110/E3/12.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page110/E3/13.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page110/E3/14.jpg" },
        {
          url: "img/FriendsPlus/Page110/E3/15.jpg",
          input: true,
          answer: "modern, near the city centre, quite small, but very nice",
        },
        { url: "img/FriendsPlus/Page110/E3/16.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page110/E3/17.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page110/E3/18.jpg" },
        {
          url: "img/FriendsPlus/Page110/E3/19.jpg",
          input: true,
          answer: "brother",
        },
        { url: "img/FriendsPlus/Page110/E3/20.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page110/E3/21.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page110/E3/22.jpg" },
        {
          url: "img/FriendsPlus/Page110/E3/23.jpg",
          input: true,
          answer: "Peter",
        },
        { url: "img/FriendsPlus/Page110/E3/24.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page110/E3/25.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Option 1",
    id: "SB6-OPE1-P110-E4",
    audio: "img/FriendsPlus/Page110/E4/Audio/G6 SB Track 3.04.mp3",
    video: "",
    component: T2,
    titleImage: "img/FriendsPlus/Page110/E4/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title: "",
        answer: [],
        type: "longAnwser",
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Option 1",
    id: "SB6-OPE1-P110-E5",
    audio: "",
    video: "",
    component: T2,
    titleImage: "img/FriendsPlus/Page110/E5/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: "longAnwser",
      },
    ],
  },
};

export default json;
