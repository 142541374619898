import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import url from '../../configs/url';


const json = {
  3: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P73-E3',
    audio: '',
    video: 'https://cloud.softech.vn/storage/SachSo/Videos/P73E3_4.mp4',
    videoSub: url.cdnUrl + '/P73E3_4.vtt',
    component: T6,
    inputSize: 500,
    exerciseKey: 'img/FriendsPlus/Page73/E3/Key/answerKey.png',
    // titleImage: "img/FriendsPlus/Page68/E6/1.png",
    titleQuestion: [{ color: '#005293', num: '3', title: 'Watch or listen and answer the questions.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1.</span><span style='color:#048BB8'> Does Liane</span> do any sports? #. </p>" +
          "<p><span style='font-weight:600'>2.</span> What sport<span style='color:#048BB8'> Does Lewis</span> watch on TV? #. </p>" +
          "<p><span style='font-weight:600'>3.</span> Are there any sports <span style='color:#048BB8'> Ray wants </span> to try? #. </p>",

        answer: ['Yes, she does swimming', 'He watches football on TV', 'He wants to try skiing'],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P73-E4',
    audio: 'img/FriendsPlus/Page73/E4/Audio/G6 SB Track 2.13.WAV',
    video: 'https://cloud.softech.vn/storage/SachSo/Videos/P73E3_4.mp4',
    videoSub: url.cdnUrl + '/P73E3_4.vtt',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page73/E4/Key/answerKey.png',
    //titleImage: 'http://',
    // titleQuestion: [{ num: '2', title: 'Read the introduction. Then listen to the interviews. What are the people learning? Use the languages in the box.' }],
    // typeInput: 'center',
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page73/E4/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page73/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page73/E4/3.jpg', input: true, answer: "swimming" },
        { url: 'img/FriendsPlus/Page73/E4/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page73/E4/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page73/E4/6.jpg' },
        { url: 'img/FriendsPlus/Page73/E4/7.jpg', input: true, answer: "getting up early every morning" },
      ],
      [
        { url: 'img/FriendsPlus/Page73/E4/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page73/E4/9.jpg' },
        { url: 'img/FriendsPlus/Page73/E4/10.jpg', input: true, answer: "school" },
        { url: 'img/FriendsPlus/Page73/E4/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page73/E4/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page73/E4/13.jpg' },
        { url: 'img/FriendsPlus/Page73/E4/14.jpg', input: true, answer: "basketball" },
        { url: 'img/FriendsPlus/Page73/E4/15.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page73/E4/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page73/E4/17.jpg' },
        { url: 'img/FriendsPlus/Page73/E4/18.jpg', input: true, answer: "skiing" },
        { url: 'img/FriendsPlus/Page73/E4/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page73/E4/20.jpg' },
      ]
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P73-E5',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page73/E5/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json;
