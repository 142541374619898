/* eslint-disable no-undef */
import React from "react";
import LineTo from 'react-lineto';
import { useDrop } from "react-dnd";
import update from "immutability-helper";
import { Container, Row, Col } from "reactstrap";

import { Card } from "./Card.js";
import { ItemTypes } from "./ItemTypes";
import styles from './styles.module.css';
import FooterModal from "../../../FooterModal/index.js";
import TitleQuestion from "../../../TitleQuestion/index.js";
import moment from 'moment';
import 'moment/locale/vi';

moment.locale('vi');

const LT1 = (props) => {
  const [cardsLeft, setCardsLeft] = React.useState([]);
  const [cardsRight, setCardsRight] = React.useState([]);

  const [state, setState] = React.useState({
    answer: null,
    isDoing: true,
    recordURL: null,
    matchedPairs: [],
    booleanArray: [],
    selectedLeft: null,
    selectedRight: null,
    studentAnswer: null,
    isDisabledRetry: true,
    isDisabledSubmit: true,
  });

  React.useEffect(() => {
    const answer = props.questions[0].answer;
    const cardsLeft = props.questions[0].left;
    const cardsRight = props.questions[0].right;
    setCardsLeft(cardsLeft);
    setCardsRight(cardsRight);
    setState((prevState) => ({ ...prevState, answer, isDisabledSubmit: true }));
  }, [props.questions]);

  const findCardRight = React.useCallback((id) => {
    const card = cardsRight.filter((c) => `${c.id}` === id)[0];
    return {
      card,
      index: cardsRight.indexOf(card),
    };
  }, [cardsRight]);

  const moveCardRight = React.useCallback((id, atIndex) => {
    const { card, index } = findCardRight(id);
    setCardsRight(
      update(cardsRight, {
        $splice: [
          [index, 1],
          [atIndex, 0, card],
        ],
      })
    );
  }, [cardsRight, findCardRight]);

  const [, dropRight] = useDrop({ accept: ItemTypes.CARD });

  // Card left
  const findCardLeft = React.useCallback((id) => {
    const card = cardsLeft.filter((c) => `${c.id}` === id)[0];
    return {
      card,
      index: cardsLeft.indexOf(card),
    };
  }, [cardsLeft]);

  const moveCardLeft = React.useCallback((id, atIndex) => {
    const { card, index } = findCardLeft(id);
    setCardsLeft(
      update(cardsLeft, {
        $splice: [
          [index, 1],
          [atIndex, 0, card],
        ],
      })
    );
  }, [cardsLeft, findCardLeft]);

  const [, dropLeft] = useDrop({ accept: ItemTypes.CARD });

  const checkAnswer = React.useCallback(() => {

    const { matchedPairs, answer } = state;

    let studentAnswer = []

    matchedPairs?.forEach((item, index) => {
      const answerItem = answer.find((x) => x.left === item.left.id);
      const isCorrect = item.right.id === answerItem.right;
      matchedPairs[index].isCorrect = isCorrect;
      // state.booleanArray.push(isCorrect);
      studentAnswer.push(answer)
    });
    matchedPairs.forEach(x => state.booleanArray.push(x.isCorrect))
    let correctAnswer = 0;
    state.booleanArray.forEach((item, index) => {
      if (item) {
        correctAnswer++
      }
    })
    const percent = parseInt(correctAnswer / state.booleanArray.length * 100)
    // const resultString = `${correctAnswer}/${state.booleanArray.length} (${percent}%)`
    const result_String = `${correctAnswer}/${state.booleanArray.length}`

    const params = {
      score: percent,
      unit: props.unit,
      results: result_String,
      exerciseId: props.id,
      sreatedDate: moment().format(),
      studentAnswer: JSON.stringify(studentAnswer),
    }
    props.postAnswerToApi(params)
    setState((prevState) => ({
      ...prevState,
      isDoing: false,
      matchedPairs,
      isDisabledSubmit: true,
      isDisabledRetry: false,
    }));

  }, [props, state]);

  const tryAgain = React.useCallback(() => {
    setState((preState) => ({
      ...preState,
      booleanArray: [],
      isDoing: true,
      matchedPairs: [],
      selectedLeft: null,
      selectedRight: null,
      isDisabledSubmit: true,
      isDisabledRetry: true,
    }));
  }, []);

  const onClickItem = React.useCallback((item, index, side) => () => {
    setState((prevState) => {
      const stateModel = { [`selected${side}`]: item };

      if ((side === 'Left' && prevState.selectedRight) || (side === 'Right' && prevState.selectedLeft)) {
        stateModel.matchedPairs = prevState.matchedPairs;
        const left = prevState.selectedLeft || stateModel.selectedLeft;
        const right = prevState.selectedRight || stateModel.selectedRight;
        const leftIndex = stateModel.matchedPairs?.findIndex((x) => x.left.id === left.id);
        const rightIndex = stateModel.matchedPairs?.findIndex((x) => x.right.id === right.id);

        if (leftIndex === -1 && rightIndex === -1) {
          stateModel.matchedPairs?.push({ left, right });
        } else {
          if (leftIndex > -1) {
            stateModel.matchedPairs?.splice(leftIndex, 1);
          }

          if (rightIndex > -1) {
            stateModel.matchedPairs?.splice(rightIndex, 1);
          }

          stateModel.matchedPairs?.push({ left, right });
        }

        stateModel.selectedLeft = null;
        stateModel.selectedRight = null;
      }

      return ({
        ...prevState,
        ...stateModel,
      });
    });
  }, []);

  const renderLine = React.useCallback((item, index) => {

    const isSubmit = (state.matchedPairs?.length === props.questions[0].answer.length) && state.isDoing === true; //total

    let lineColor = 'black';

    if (typeof item.isCorrect === 'boolean') {
      lineColor = item.isCorrect ? 'green' : undefined;
    }

    if (isSubmit && state.isDisabledSubmit) {
      setState((prevState) => ({ ...prevState, isDisabledSubmit: false }));
    }

    return (
      <LineTo
        key={index}
        within='container_LineTo'
        // zIndex={1100}
        borderWidth={2}
        toAnchor="27% 48%"
        fromAnchor="73% 48%"
        borderColor={lineColor}
        to={`left-${item.left.id}`}
        from={`right-${item.right.id}`}
      />
    );
  }, [props.questions, state.isDisabledSubmit, state.isDoing, state.matchedPairs?.length]);

  const renderRight = React.useCallback((item, index) => {
    let customStyles = {};

    if (item.id === state.selectedRight?.id) {
      customStyles = {
      };
    }

    return (
      <div
        style={{
          ...customStyles,
          display: 'flex',
          justifyContent: 'center', alignItems: 'center'
        }}
        className={`right-${item.id}`}
        onClick={onClickItem(item, index, 'Right')}
      >
        <Card
          key={item.id}
          id={`${item.id}`}
          text={item.text}
          moveCard={moveCardRight}
          findCard={findCardRight}
        />
      </div>
    )
  }, [onClickItem, state.selectedRight, moveCardRight, findCardRight]);

  const renderLeft = React.useCallback((item, index) => {
    let customStyles = {};

    if (item.id === state.selectedLeft?.id) {
      customStyles = {
        borderWidth: 1,
        borderColor: '',
      };
    }

    return (
      <div
        style={{
          ...customStyles,
          display: 'flex',
          justifyContent: 'center', alignItems: 'center'
        }}
        className={`left-${item.id}`}
        onClick={onClickItem(item, index, 'Left')}
      >
        <Card
          key={item.id}
          id={`${item.id}`}
          text={item.text}
          moveCard={moveCardLeft}
          findCard={findCardLeft}
        />
      </div>
    )
  }, [onClickItem, state.selectedLeft, findCardLeft, moveCardLeft]);

  if (!cardsLeft || !cardsRight) return null;

  return (
    <Container id="gfx_holder" className="fluid">

      <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <TitleQuestion titleQuestion={props.titleQuestion} />
      </Row>
      <Row style={{ justifyContent: 'center' }}>
        {!!props.titleImage && <img alt="" src={props.titleImage} className={styles.titleImage} />}
      </Row>
      <Row className="d-flex align-items-center flex-row container_LineTo" style={{ position: 'relative', minWidth: 1000 }}>
        <Col ref={dropRight}>
          {cardsRight?.map(renderRight)}
        </Col>
        <Col style={{ marginLeft: 10 }} ref={dropLeft}>
          {cardsLeft?.map(renderLeft)}
        </Col>
        {state.matchedPairs?.map(renderLine)}
      </Row>

      <FooterModal
        onRetry={tryAgain}
        onSubmit={checkAnswer}
        audioUrl={props.audio}
        result={state?.booleanArray}
        exerciseKey={props.exerciseKey}
        isDisabledRetry={state.isDisabledRetry}
        isDisabledSubmit={state.isDisabledSubmit}
      />

    </Container>
  );
};
export default LT1;
