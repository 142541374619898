import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {

  1: { // Exercise num
    unit: 'Unit 8',
    id: 'SB6-U8-P98-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page98/E1/Key/answerKey.png',
    titleImage: '',
    titleQuestion: [{ num: '1', title: 'Read the text and write C (Ciara), M (Martin) or J (Jason).', color: '#DC3E46' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/page98/E1/title.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/page98/E1/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/page98/E1/2.jpg' },
        { url: 'img/FriendsPlus/page98/E1/3.jpg', input: true, answer: 'C' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/page98/E1/4.jpg' },
        { url: 'img/FriendsPlus/page98/E1/5.jpg', input: true, answer: 'M' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/page98/E1/6.jpg' },
        { url: 'img/FriendsPlus/page98/E1/7.jpg', input: true, answer: 'J' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/page98/E1/8.jpg' },
        { url: 'img/FriendsPlus/page98/E1/9.jpg', input: true, answer: 'M' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/page98/E1/10.jpg' },
        { url: 'img/FriendsPlus/page98/E1/11.jpg', input: true, answer: 'J' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/page98/E1/12.jpg' },

      ],
      [
        // Column
        { url: 'img/FriendsPlus/page98/E1/13.jpg', input: true, answer: 'C' },
        { url: 'img/FriendsPlus/page98/E1/14.jpg' },
      ],


    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 8',
    id: 'SB6-U8-P98-E2',
    audio: 'img/FriendsPlus/page98/E2/Audio/G6 SB Track 2.33.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/page98/E2/Key/answerKey.png',
    titleImage: "img/FriendsPlus/Page98/E2/title.jpg",
    imgSize: 900,
    stylesTextInput: {
      textTransform: 'capitalize'
    },
    // imgSize: '100%',
    titleQuestion: [{ color: '#B82830', num: '2', title: 'Read and listen to the text and write <i>True</i> or <i>False</i>.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> Ciara is going to Paris next week. # </p>" +
          "<p><span style='font-weight:600'>2</span> Martin plans to teach some of his new friends</p>" +
          "<p>how to play tennis. # </p>" +
          "<p><span style='font-weight:600'>3</span> Jason likes to take amazing photos with his</p>" +
          "<p>friends. # </p>",
        answer: [
          "False", "False", "True",
        ],
        questions: [
          {
            title: 'The dictionaries are # the shelf',
            answer: ['table']
          },
        ]
      },
    ],
  },
  3: { // Exercise num
    unit: 'Unit 8',
    id: 'SB6-U8-P98-E3',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page98/E3/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 8',
    id: 'SB6-U8-P98-E4',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page98/E4/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },

}
export default json