import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';


const json = {
  1: { // Exercise num
    unit : 'Unit 8',
    id : 'SB6-U8-P103-E1',
    // audio: 'img/FriendsPlus/Page9/E2/Audio/07- Starter Unit - Track 07.mp3',
    video: '',
    component: T1,
    titleImage: 'img/FriendsPlus/Page103/E1/1.png',
    imgSize: '90%',
    exerciseKey: 'img/FriendsPlus/Page103/E1/Key/answerKey.png',
    titleQuestion: [{ num: '1', title: 'Read the postcard. Where is Adele from? Where is she now? What is she doing?', color: '#009241' }],
    questionImage: [
    ],
    questions: [
      {
        title:
          '<p><span style="font-weight:600">1</span> Where is Adele from? #.  </p>' +
          '<p><span style="font-weight:600">2</span> Where is she now? #. </p>' +
          '<p><span style="font-weight:600">3</span> What is she doing? #. </p>',
        answer: [
          'Adele is from the UK',
          'She is in Hà Nội now',
          'She is visiting the Hoàn Kiếm Lake'
        ],
        type: 'longAnwser'

      },
    ]
  },
  2: { // Exercise num
    unit : 'Unit 8',
    id : 'SB6-U8-P103-E2',
    audio: '',
    video: '',
    component: D1,
    fontSize: 27,
    height: 40,
    exerciseKey: 'img/FriendsPlus/Page103/E2/Key/key.png',
    titleImage: '',
    titleQuestion: [{ num: '2', title: 'Complete the Key Phrases. Then look at the postcard and check your answers.', color: '#02a84f' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page103/E2/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page103/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page103/E2/3.jpg', input: true, answer: 'a great time here' },
        { url: 'img/FriendsPlus/Page103/E2/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page103/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page103/E2/6.jpg', input: true, answer: 'visiting the Hoàn Kiếm Lake' },
        { url: 'img/FriendsPlus/Page103/E2/7.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page103/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page103/E2/9.jpg', input: true, answer: 'to the Tây Lake to go boating and enjoy bánh tôm' },
        { url: 'img/FriendsPlus/Page103/E2/10.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page103/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page103/E2/12.jpg', input: true, answer: 'cool and wet' },
        { url: 'img/FriendsPlus/Page103/E2/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page103/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page103/E2/15.jpg', input: true, answer: 'see you again in three days' },
        { url: 'img/FriendsPlus/Page103/E2/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page103/E2/17.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    unit : 'Unit 8',
    id : 'SB6-U8-P103-E3',
    // audio: 'img/FriendsPlus/Page9/E2/Audio/07- Starter Unit - Track 07.mp3',
    video: '',
    component: T1,
    // titleImage: 'img/FriendsPlus/Page114/E4/1.jpg',
    imgSize: '60%',
    exerciseKey: 'img/FriendsPlus/Page103/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Study the example. Match sentences 1–5 with reasons a–e. Then combine the sentences using because.', color: '#009241' }],
    questionImage: [
    ],
    questions: [
      {
        title:
          "<p style='font-family:OneStrokeScriptStd-Bold;color:gray'>It’s cool and wet because it’s in autumn. </p>" +
          '<p><span style="font-weight:600">1</span> I hope it will be hot tomorrow. #.  </p>' +
          '<p><span style="font-weight:600">2</span> We’re going to pack our suitcases. #. </p>' +
          '<p><span style="font-weight:600">3</span> My brother wants to buy a guidebook. #. </p>' +
          '<p><span style="font-weight:600">4</span> Samir lives in France. #. </p>' +
          '<p><span style="font-weight:600">5</span> We’re going to take our waterproofs. #. </p>' +
          '<p><span style="font-weight:600">a</span>  His dad works there. </p>' +
          '<p><span style="font-weight:600">b</span> We’re going to go away tomorrow. </p>' +
          '<p><span style="font-weight:600">c</span> It will be rainy on Saturday.</p>' +
          '<p><span style="font-weight:600">d</span> He’s going to travel to Japan in the summer. </p>' +
          '<p><span style="font-weight:600">e</span> I want to go to the beach. </p>',
        answer: [
          "e I hope it will be hot tomorrow because I want to go to the beach",
          "b We're going to pack our suitcases because we're going to go away tomorrow",
          "d My brother wants to buy a guidebook because he's going to travel to Japan in the summer",
          "a Samir lives in France because his dad works there",
          "c We're going to take our waterproofs because it will be rainy on Saturday"
        ],
        type: 'longAnwser'

      },
    ]
  },
  4: { // Exercise num
    unit : 'Unit 8',
    id : 'SB6-U8-P103-E4',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page103/E4/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json