import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import U1 from '../../components/ExcerciseTypes/UnderLine/U1'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {

  1: { // Exercise num
    unit: 'Unit 2',
    id: 'SB6-U2-P31-E1',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page31/E1/1.jpg",
    imgSize: '80%',
    titleQuestion: [{ num: '1', title: 'Check the meanings of the words in the box. Then describe the photo using the words.', color: '#009241' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 2',
    id: 'SB6-U2-P31-E2',
    audio: '',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page31/E2/Key/answerKey.png',
    titleImage: "img/FriendsPlus/Page31/E2/1.png",
    titleQuestion: [{ color: '#009241', num: '2', title: 'Read the description. Where and when is the carnival? What do people do?' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1.</span>  # </p>" +
          "<p><span style='font-weight:600'>2.</span>  # </p>",
        answer: ['The carnival is in Notting Hill, London in August.', 'People wear costumes, play music and dance.'],
        type: 'longAnwser'
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 2',
    id: 'SB6-U2-P31-E3',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page31/E3/Key/Key.png',
    titleImage: 'img/FriendsPlus/Page31/E3/title.png',
    titleQuestion: [{ num: '3', title: 'Look at the text and complete. The Key Phrases.' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page31/E1/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page31/E3/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page31/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page31/E3/3.jpg', input: true, answer: 'the Notting Hill Carnival' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page31/E3/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page31/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page31/E3/6.jpg', input: true, answer: 'Caribbean culture' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page31/E3/7.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page31/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page31/E3/9.jpg', input: true, answer: 'big parades' },
        { url: 'img/FriendsPlus/Page31/E3/10.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page31/E3/11.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page31/E3/12.jpg' },
        { url: 'img/FriendsPlus/Page31/E3/13.jpg', input: true, answer: 'fun' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page31/E3/14.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 2',
    id: 'SB6-U2-P31-E4',
    // audio: 'img/FriendsPlus/Page9/E3/Audio/07- Starter Unit - Track 07.mp3',
    video: '',
    component: U1,
    titleImage: '',
    exerciseKey: 'img/FriendsPlus/Page31/E4/Key/1.png',
    titleQuestion: [{ color: '#009241', num: '4', title: 'Look at the words in <span style="color:#33B1D6;">blue</span> in the text. How do you say <i style="color:#5f5a5a;">also</i> in your language?<br/> Then choose the correct words.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> We buy presents. # / # a family meal.</p>" +
          "<p><span style='font-weight:600'>2</span> There are fireworks. # / # a concert.</p>" +
          "<p> <span style='font-weight:600'>3</span> I like school,but # / # the holidays.</p>" +
          "<p><span style='font-weight:600'>4</span> My birthday is in April. My sister’s birthday # / # in April.</p>",
        answerString: ['We have also ', "We also have", 'There’s also', 'There also is', 'I also like', 'I like also', 'also is ', 'is also '],
        answer: ["We also have", 'There’s also', 'I also like', 'is also'],
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 2',
    id: 'SB6-U2-P31-E5',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page31/E5/1.png",
    titleQuestion: '',
    questionImage: [],
    questions: [
      {
        title:
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },

}
export default json;
