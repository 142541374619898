
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';

const json = {

  1: { // Exercise num
    unit : 'Unit 4',
    id : 'SB6-U4-P56-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page56/E1/Key/1.png',
    titleImage: "img/FriendsPlus/Page56/E1/1.png",
    titleQuestion: [{ num: '1', title: "Look at the first photo and answer the questions. Then read the text and check your answers." }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>1. Where is Ben? #. </p>" +
          "<p>2. What is he doing? #. </p>",

        answer: ["He's at home", "He's studying"],
        type: 'longAnwser'
      },
    ]
  },
  2: { // Exercise num
    unit : 'Unit 4',
    id : 'SB6-U4-P56-E2',
    audio: 'img/FriendsPlus/Page56/E2/Audio/G6 SB Track 1.47.wav',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page56/E2/Key/1.png',
    titleImage: "img/FriendsPlus/Page56/E1/1.png",
    titleQuestion: [{ num: '2', title: "Read and listen to the text and answer the questions." }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1.</span> Why doesn’t Ben go to school? #. </p>" +
          "<p><span style='font-weight:600'>2.</span> What is different about Ben's home education? #. </p>" +
          "<p><span style='font-weight:600'>3.</span> What does Ben do when he isn’t studying? #. </p>" +
          "<p><span style='font-weight:600'>4.</span> Does Ben feel lonely studying at home? Why / Why not? #. </p>" +
          "<p><span style='font-weight:600'>5.</span> In your opinion, is Ben's life interesting? Why / Why not? #. </p>",

        answer: [
          "He studies at home because he's a tennis player and needs time to practise and play in tournaments",
          "He can choose what subjects he studies. He can study at his own speed",
          "When he isn't studying, he practices tennis or travels to other countries to play in tournaments, plays football and chats to his friends online",
          "No, he doesn't. He chats to them online and they sometimes play football",
          ""
        ],
        type: 'longAnwser'
      },
    ]
  },
  3: { // Exercise num
    unit : 'Unit 4',
    id : 'SB6-U4-P56-E3',
    audio: '',
    video: '',
    component: T1,
    // titleImage: "img/FriendsPlus/Page56/E1/1.png",
    titleQuestion: [{ num: '3', title: "YOUR CULTURE Answer the questions." }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1.</span> At what age do children usually start school in your country? # </p>" +
          "<p><span style='font-weight:600'>2.</span>  At what age can you leave school? # </p>" +
          "<p><span style='font-weight:600'>3.</span>  Can children be home schooled in your country?# </p>" ,

        answer: [
          "",
          "",
          ""
        ],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    unit : 'Unit 4',
    id : 'SB6-U4-P56-E4',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page56/E4/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json;
