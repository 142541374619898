import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T3 from '../../components/ExcerciseTypes/TypeIn/T3';
// import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import LT1 from '../../components/ExcerciseTypes/LineTo/LT1'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import url from '../../configs/url';


const json = {
  1: { // Exercise num
    unit: 'Unit 4',
    id: 'SB6-U4-P51-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page51/E1/Key/answerKey.png',
    // titleImage: "img/FriendsPlus/Page50/E1/1.png",
    titleQuestion: [{ num: '1', title: 'Complete the examples from the text on page 50. Then choose the correct words in rules 1–3.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> These students # studying.</p>" +
          "<p><span style='font-weight:600'>2</span> The boat # moving.</p>" +
          "<p><span style='font-weight:600'>3</span> Her students # home and</p>" +
          "<p> # the lessons on television.</p>" +
          "<p><span style='font-weight:600'>4</span> I’m # the dancers.</p>",
        answer: [
          'are',
          "isn't",
          'are staying home',
          'learning',
          'watching',
        ],
        type: 'longAnwser'
      },
    ]
  },
  '1-1': {
    unit: 'Unit 4',
    id: 'SB6-U4-P51-E1.1',
    audio: '',
    video: url.cdnUrl + '/Animation - Unit 4 grammar 1 trang 51.mp4',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: 'img/FriendsPlus/Page51/E1/Key/Key.png',
    // titleQuestion: [{ color: '#14BCD4', num: '1', title: "Choose the correct answers in rules 1–4." }],
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page51/E1/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page51/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page51/E1/3.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page51/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page51/E1/5.jpg', input: 1, isCorrect: false },
        { url: 'img/FriendsPlus/Page51/E1/6.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page51/E1/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page51/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page51/E1/9.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page51/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page51/E1/11.jpg', input: 2, isCorrect: false },
        { url: 'img/FriendsPlus/Page51/E1/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page51/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page51/E1/14.jpg', input: 3, isCorrect: false },
        { url: 'img/FriendsPlus/Page51/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page51/E1/16.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page51/E1/17.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page51/E1/18.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 4',
    id: 'SB6-U4-P51-E2',
    audio: '',
    video: '',
    component: T3,
    exerciseKey: 'img/FriendsPlus/Page51/E2/Key/1.png',
    titleImage: "img/FriendsPlus/Page51/E2/1.png",
    imgSize: '50%',
    titleQuestion: [{ num: '2', title: 'What are the present continuous forms of verbs 1–5? Read the spelling rules and match the verbs to rules 1–3.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> wear # </p>" +
          "<p><span style='font-weight:600'>2</span> have # </p>" +
          "<p><span style='font-weight:600'>3</span> do # </p>" +
          "<p><span style='font-weight:600'>4</span> dance # </p>" +
          "<p><span style='font-weight:600'>5</span> sit # </p>",
        answer: [
          "wearing",
          'having',
          'doing',
          'dancing',
          'sitting'
        ],
        type: 'longAnwser'
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 4',
    id: 'SB6-U4-P51-E3',
    audio: 'img/FriendsPlus/Page51/E3/Audio/Friends Plus for Vietnam G6 SB Track 1.42.mp3',
    video: '',
    component: LT1,
    exerciseKey: 'img/FriendsPlus/Page51/E3/Key/answerKey.png',
    titleImage: 'img/FriendsPlus/Page51/E3/1.png',
    titleQuestion: [{ num: '3', title: 'Listen to the sounds. Match the people in column A with the actions in column B. Then write sentences using the present continuous.' }],
    questionImage: [],
    questions: [
      {
        right: [
          {
            id: 1,
            text: "1. A young child",
          },
          {
            id: 2,
            text: "2. Two women",
          },
          {
            id: 3,
            text: "3. Two men",
          },
          {
            id: 4,
            text: "4. A girl",
          },
          {
            id: 5,
            text: "5. A boy",
          },
          {
            id: 6,
            text: "6. A man",
          },
        ],
        left: [
          {
            id: 1,
            text: "a. (watch) TV.",
          },
          {
            id: 2,
            text: "b. (have) dinner.",
          },
          {
            id: 3,
            text: "c. (run) in the park.",
          },
          {
            id: 4,
            text: "d. (speak) to his mother.",
          },
          {
            id: 5,
            text: "e. (look) for a mosquito.",
          },
          {
            id: 6,
            text: "f. (play) a musical instrument. ",
          },
        ],
        answer: [
          {
            right: 1,
            left: 4,
          },
          {
            right: 2,
            left: 3,
          },
          {
            right: 3,
            left: 2,
          },
          {
            right: 4,
            left: 6,
          },
          {
            right: 5,
            left: 1,
          },
          {
            right: 6,
            left: 5,
          },
        ],
        type: ''
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 4',
    id: 'SB6-U4-P51-E4',
    audio: '',
    video: '',
    component: T6,
    inputSize: 500,
    exerciseKey: 'img/FriendsPlus/Page51/E4/Key/1.png',
    titleImage: "img/FriendsPlus/Page51/E4/1.png",
    imgSize: '65%',
    titleQuestion: [{ num: '4', title: 'Find six differences between the pictures. Write three affirmative and three negative sentences about picture B. Use the present continuous.' }],
    questionImage: [],
    questions: [
      {
        title:

          "<p><span style='font-weight:600'>1</span> #. </p>" +
          "<p><span style='font-weight:600'>2</span> #. </p>" +
          "<p><span style='font-weight:600'>3</span> #. </p>" +
          "<p><span style='font-weight:600'>4</span> #. </p>" +
          "<p><span style='font-weight:600'>5</span> #. </p>" +
          "<p><span style='font-weight:600'>6</span> #. </p>",
        answer: [
          "The boy is sitting next to the teacher",
          'The birds are flying',
          'The people on the poster are dancing',
          "The teacher isn't reading",
          "The girl isn't eating",
          "The students aren't wearing uniforms"
        ],
        type: 'longAnwserInLine'
      },
    ]
  },

  5: { // Exercise num
    unit: 'Unit 4',
    id: 'SB6-U4-P51-E5',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page51/E5/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json