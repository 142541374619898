import UI from "../../components/ExcerciseTypes/UseIt";
import U1 from "../../components/ExcerciseTypes/UnderLine/U1";
import T1 from "../../components/ExcerciseTypes/TypeIn/T1";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DD2 from "../../components/ExcerciseTypes/DragAndDrop/DD2";

const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "SB6-U1-P21-E1",
    audio: "",
    video: "",
    color: "#009647",
    component: DD2,
    imgSize: "90%",
    exerciseKey: "img/FriendsPlus/Page21/E1/Key/1.png",
    titleImage: "img/FriendsPlus/Page21/E1/1.jpg",
    titleQuestion: [
      {
        num: "1",
        title:
          "Read the description of Hồ Chí Minh City. Which words in the THINK! exercise are in the text?",
        color: "#00A84E",
      },
    ],
    questionImage: [{ url: "img/FriendsPlus/Page21/E1/1.png" }],
    questions: [
      {
        text: "buildings",
        correctIndex: 0,
      },
      {
        text: "a zoo",
      },
      {
        text: "amusement parks",
        correctIndex: 1,
      },
      {
        text: "shopping malls",
        correctIndex: 2,
      },
      {
        text: "factories",
      },
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 1",
    id: "SB6-U1-P21-E2",
    audio: "",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page21/E2/Key/Key.png",
    fontSize: 25,
    height: 31,
    titleQuestion: [
      {
        num: "2",
        title: "Complete the key phrases with words from the text.",
        color: "#00A84E",
      },
    ],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page21/E1/1.jpg" }],
      [{ url: "img/FriendsPlus/Page21/E2/1.jpg" }],
      [
        // Column
        { url: "img/FriendsPlus/Page21/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page21/E2/3.jpg",
          input: true,
          answer: "south",
        },
        { url: "img/FriendsPlus/Page21/E2/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page21/E2/5.jpg" },
        {
          url: "img/FriendsPlus/Page21/E2/6.jpg",
          input: true,
          answer: "Việt Nam",
        },
        { url: "img/FriendsPlus/Page21/E2/7.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page21/E2/8.jpg" },
        {
          url: "img/FriendsPlus/Page21/E2/9.jpg",
          input: true,
          answer: "9,000,000 / Nine million",
        },
        { url: "img/FriendsPlus/Page21/E2/10.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page21/E2/11.jpg" }],
      [
        { url: "img/FriendsPlus/Page21/E2/12.jpg" },
        {
          url: "img/FriendsPlus/Page21/E2/13.jpg",
          input: true,
          answer: "modern",
        },
        { url: "img/FriendsPlus/Page21/E2/14.jpg" },
        {
          url: "img/FriendsPlus/Page21/E2/15.jpg",
          input: true,
          answer: "really friendly",
        },
        { url: "img/FriendsPlus/Page21/E2/16.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page21/E2/17.jpg" }],
      [
        { url: "img/FriendsPlus/Page21/E2/18.jpg" },
        {
          url: "img/FriendsPlus/Page21/E2/19.jpg",
          input: true,
          answer: "many good amusement parks, shopping malls",
        },
        { url: "img/FriendsPlus/Page21/E2/20.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page21/E2/21.jpg" },
        {
          url: "img/FriendsPlus/Page21/E2/22.jpg",
          input: true,
          answer: "buildings",
        },
        { url: "img/FriendsPlus/Page21/E2/23.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page21/E2/24.jpg" },
        {
          url: "img/FriendsPlus/Page21/E2/25.jpg",
          input: true,
          answer: "sometimes visit Đà Lạt, Vũng Tàu ...",
        },
        { url: "img/FriendsPlus/Page21/E2/26.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page21/E2/27.jpg" },
        {
          url: "img/FriendsPlus/Page21/E2/28.jpg",
          input: true,
          answer: "Hồ Chí Minh City is more interesting",
        },
        { url: "img/FriendsPlus/Page21/E2/29.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page21/E2/30.jpg" }],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  // 2: { // Exercise num

  //   audio: '',
  //   video: '',
  //   component: T6,
  //   titleImage: '',
  //   inputSize: 570,
  //   titleQuestion: [{ num: '2', title: 'Complete the key phrases with words from the text.', color: '#00A84E' }],
  //   questionImage: [],
  //   exerciseKey: 'img/FriendsPlus/Page21/E2/Key/Key.jpg',
  //   questions: [
  //     {
  //       title: "<p style='color:green;font-weight:600'>Describing a town or city</p>"
  //         + "<p>1. It's the north / # / west / east / centre of # </p>"
  //         + "<p>2. It’s got a population of about # </p>"
  //         + "<p>3. It’s very # and the people are # </p>"
  //         + "<p>4. There are # and # </p>"
  //         + "<p>5. I # but # </p>",
  //       answer: [
  //         "South",
  //         'Việt Nam',
  //         "9,000,000/Nine million",
  //         'modern', 'really friendly',
  //         'many good amusement parks, shopping malls', 'buildings',
  //         'sometimes visit Đà Lạt, Vũng Tàu ...', 'Hồ Chí Minh City is more interesting'
  //       ],
  //       type: 'longAnwserInLine'
  //     },
  //   ]
  // },
  3: {
    // Exercise num
    unit: "Unit 1",
    id: "SB6-U1-P21-E3",
    audio: "",
    video: "",
    component: U1,
    imgSize: "70%",
    exerciseKey: "img/FriendsPlus/Page21/E3/Key/1.png",
    titleImage: "img/FriendsPlus/Page21/E3/1.jpg",
    titleImagePosition: "top",
    titleQuestion: [
      {
        num: "3",
        title:
          "Look at the phrases in blue in the text. Then choose the correct words and underline them.",
        color: "#00A84E",
      },
    ],
    questionImage: [],
    questions: [
      {
        title:
          "<p>1. When an adjective and noun are together, the adjective is # /# the noun.</p><p>2. We use <i>really</i>, <i>very</i> and <i>quite</i> # / # the adjective.",
        answerString: ["before", "after", "before", "after"],
        answer: ["before", "before"],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 1",
    id: "SB6-U1-P21-E4",
    audio: "",
    video: "",
    component: U1,
    titleImage: "",
    exerciseKey: "img/FriendsPlus/Page21/E4/Key/1.png",
    titleImagePosition: "top",
    titleQuestion: [
      {
        num: "4",
        title:
          "Compound sentences.<br/> Look at the underlined sentences in the text. Then circle the correct words.",
        color: "#00A84E",
      },
    ],
    questionImage: [],
    questions: [
      {
        title:
          "<p>1. We use <i>and</i> to join # /# independent clauses in a compound sentence.</p><p>2. We use <i>but</i> to join # / # independent clauses in a compound sentence.",
        answerString: ["similar", "opposite", "similar", "opposite"],
        answer: ["similar", "opposite"],
      },
    ],
  },
  "4-2": {
    // Exercise num
    unit: "Unit 1",
    id: "SB6-U1-P21-E4.2",
    audio: "",
    video: "",
    component: T1,
    titleImage: "",
    exerciseKey: "img/FriendsPlus/Page21/E4-2/Key/1.png",
    titleQuestion: [
      {
        num: "4",
        title: "Now order the words to make sentences.",
        color: "#00A84E",
      },
    ],
    questionImage: [],
    questions: [
      {
        title:
          "<p>3. old town / 152,160 / Hội An Town / and / is / got / a population / it’s / of about / an #. </p><p>4. sometimes / stays in Hà Nội / Mr Nam / but / in Hồ Chí Minh City / lives / he #. </p>",
        answer: [
          "Hội An Town is an old town, and it's got a population of about 152,160",
          "Mr Nam sometimes stays in Hà Nội, but he lives in Hồ Chí Minh City",
        ],
        type: "longAnwser",
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 1",
    id: "SB6-U1-P21-E5",
    audio: "",
    video: "",
    component: UI,
    //titleImage: 'http://',
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page21/E5/1.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page21/E5/2.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page21/E5/3.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page21/E5/4.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page21/E5/5.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page21/E5/6.jpg" },
      ],
    ],
  },
};

export default json;
