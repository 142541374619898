import UI from "../../components/ExcerciseTypes/UseIt";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import LT2 from "../../components/ExcerciseTypes/LineTo/LT2";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/UnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P12-E1",
    audio:
      "img/FriendsPlus/Page12/E1/Audio/Friends Plus for Vietnam G6 SB Track 1.10.mp3",
    video: "",
    component: LT2,
    titleImage: "",
    total: 12,
    exerciseKey: "img/FriendsPlus/Page12/E1/Key/1.png",
    titleQuestion: [
      { num: "1", title: "Match the countries with the continents." },
    ],
    questionImage: [],
    questions: [
      {
        right: [
          {
            id: 1,
            text: "Canada",
          },
          {
            id: 2,
            text: "Egypt",
          },
          {
            id: 3,
            text: "Italy",
          },
          {
            id: 4,
            text: "Japan",
          },
          {
            id: 5,
            text: "Morocco",
          },
          {
            id: 6,
            text: "New Zealand",
          },
          {
            id: 7,
            text: "Spain",
          },
          {
            id: 8,
            text: "Thailand",
          },
          {
            id: 9,
            text: "The Philippines",
          },
          {
            id: 10,
            text: "The UK",
          },
          {
            id: 11,
            text: "The USA",
          },
          {
            id: 12,
            text: "Việt Nam",
          },
        ],
        left: [
          {
            id: 1,
            text: "Africa",
          },
          {
            id: 2,
            text: "America",
          },
          {
            id: 3,
            text: "Asia",
          },
          {
            id: 4,
            text: "Australasia",
          },
          {
            id: 5,
            text: "Europe",
          },
        ],
        answer: [
          {
            right: 2,
            left: 1,
          },
          {
            right: 5,
            left: 1,
          },
          {
            right: 1,
            left: 2,
          },
          {
            right: 11,
            left: 2,
          },
          {
            right: 4,
            left: 3,
          },
          {
            right: 8,
            left: 3,
          },
          {
            right: 9,
            left: 3,
          },
          {
            right: 12,
            left: 3,
          },
          {
            right: 6,
            left: 4,
          },
          {
            right: 3,
            left: 5,
          },
          {
            right: 7,
            left: 5,
          },
          {
            right: 10,
            left: 5,
          },
        ],
        type: "",
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P12-E2",
    // audio: 'img/FriendsPlus/Page9/E2/Audio/07- Starter Unit - Track 07.mp3',
    video: "",
    component: DesignUnderLine,
    titleImage: "img/FriendsPlus/Page12/E2/04.jpg",
    exerciseKey: "img/FriendsPlus/Page12/E2/Key/answerKey.png",
    totalInput: 6,
    questionImage: [
      // Row

      [{ url: "img/FriendsPlus/Page12/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page12/E2/2.jpg" },
        { url: "img/FriendsPlus/Page12/E2/3.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page12/E2/4.jpg" },
        { url: "img/FriendsPlus/Page12/E2/5.jpg", input: 1, isCorrect: false },
        { url: "img/FriendsPlus/Page12/E2/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page12/E2/7.jpg" }],
      [
        { url: "img/FriendsPlus/Page12/E2/8.jpg" },
        { url: "img/FriendsPlus/Page12/E2/9.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page12/E2/10.jpg" },
        { url: "img/FriendsPlus/Page12/E2/11.jpg", input: 2, isCorrect: false },
        { url: "img/FriendsPlus/Page12/E2/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page12/E2/13.jpg" }],
      [
        { url: "img/FriendsPlus/Page12/E2/14.jpg" },
        { url: "img/FriendsPlus/Page12/E2/15.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page12/E2/16.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page12/E2/17.jpg" },
        { url: "img/FriendsPlus/Page12/E2/18.jpg", input: 3, isCorrect: false },
        { url: "img/FriendsPlus/Page12/E2/19.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page12/E2/20.jpg" }],
      [
        { url: "img/FriendsPlus/Page12/E2/21.jpg" },
        { url: "img/FriendsPlus/Page12/E2/22.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page12/E2/23.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page12/E2/24.jpg" },
        { url: "img/FriendsPlus/Page12/E2/25.jpg", input: 4, isCorrect: false },
        { url: "img/FriendsPlus/Page12/E2/26.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page12/E2/27.jpg" }],
      [
        { url: "img/FriendsPlus/Page12/E2/28.jpg" },
        { url: "img/FriendsPlus/Page12/E2/29.jpg", input: 5, isCorrect: true },
        { url: "img/FriendsPlus/Page12/E2/30.jpg" },
        { url: "img/FriendsPlus/Page12/E2/31.jpg", input: 5, isCorrect: false },
        { url: "img/FriendsPlus/Page12/E2/32.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page12/E2/33.jpg" }],
      [
        { url: "img/FriendsPlus/Page12/E2/34.jpg" },
        { url: "img/FriendsPlus/Page12/E2/35.jpg", input: 6, isCorrect: false },
        { url: "img/FriendsPlus/Page12/E2/36.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page12/E2/37.jpg" },
        { url: "img/FriendsPlus/Page12/E2/38.jpg", input: 6, isCorrect: true },
        { url: "img/FriendsPlus/Page12/E2/39.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page12/E2/40.jpg" }],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P12-E3",
    audio: "",
    video: "",
    component: T6,
    titleImage: "",
    exerciseKey: "img/FriendsPlus/Page12/E3/Key/1.png",
    titleQuestion: [
      {
        num: "3",
        title:
          "Write nationalities for the countries in exercise 1.Use words from the quiz in exercise 2.",
      },
    ],
    questionImage: [],
    questions: [
      {
        title:
          "<p>1. The USA - American</p><p>2. Canada - # </p><p>3. Egypt - # </p><p>4. Italy - # </p><p>5. Japan - # </p><p>6. Morocco - # </p><p>7. New Zealand - # </p><p>8. Spain - # </p><p>9. Thailand - # </p><p>10. The Philippines - # </p><p>11. The UK - # </p><p>12. Việt Nam - # </p>",
        answer: [
          "Canadian",
          "Egyptian",
          "Italian",
          "Japanese",
          "Moroccan",
          "New Zealander",
          "Spanish",
          "Thai",
          "Filipino",
          "British",
          "Vietnamese",
        ],
        type: "longAnwser",
      },
    ],
  },
  // 4: { // Exercise num
  unit: "Starter Unit",
  //   audio: '',
  //   video: '',
  //   component: UI,
  //   //titleImage: 'http://',
  //   questionImage: [ // Row
  //     [
  //       { url: 'img/FriendsPlus/Page12/E4/1.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page12/E4/2.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page12/E4/3.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page12/E4/4.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page12/E4/5.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page12/E4/6.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page12/E4/7.jpg' },
  //     ],
  //   ],
  //   questions: [
  //     {
  //       title: 'The dictionaries are # the shelf',
  //       answer: ['table']
  //     },
  //   ]
  // },

  4: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P12-E4",
    audio: "",
    video: "",
    component: T6,
    inputSize: 200,
    titleImage: "img/FriendsPlus/Page12/E4/title.jpg",
    // exerciseKey: 'img/FriendsPlus/Page12/E3/Key/1.png',
    // titleQuestion: [{ num: '3', title: 'Write nationalities for the countries in exercise 1.Use words from the quiz in exercise 2.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600;margin-left:80px'>1 </span> # is a / an (nationality) star.</p>" +
          "<p><span style='font-weight:600;margin-left:80px'>2 </span> # is the (nationality) capital.</p>" +
          "<p><span style='font-weight:600;margin-left:80px'>3 </span> The (nationality) flag is # .</p>" +
          "<p><span style='font-weight:600;margin-left:80px'>4 </span> # is / are (nationality).</p>" +
          "<p><span style='font-weight:600;margin-left:80px'>5 </span> The # is the (nationality) currency.</p>",
        answer: [],
        type: "longAnwser",
      },
    ],
  },

  5: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P12-E5",
    audio: "",
    video: "",
    component: UI,
    //titleImage: 'http://',
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page12/E5/1.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page12/E5/2.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
};

export default json;
