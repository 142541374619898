import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import MC3 from '../../components/ExcerciseTypes/MultipleChoice/MC3';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T3 from '../../components/ExcerciseTypes/TypeIn/T3';


const json = {
  1: { // Exercise num
    unit: 'Unit 8',
    id: 'SB6-U8-P100-E1',
    audio: 'img/FriendsPlus/Page100/E1/Audio/Friends Plus for Vietnam G6 SB Track 2.36.mp3',
    video: '',
    component: T3,
    exerciseKey: 'img/FriendsPlus/Page100/E1/Key/answerKey.png',
    titleImage: "img/FriendsPlus/Page100/E1/1.png",
    imgSize: '100%',
    titleQuestion: [{ color: '#E26D28', num: '1', title: 'Check the meanings of the words in the box. What words can you match to photos 1–4? Listen and check.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> # </p>" +
          "<p><span style='font-weight:600'>2</span> # </p>" +
          "<p><span style='font-weight:600'>3</span> # </p>" +
          "<p><span style='font-weight:600'>4</span> # </p>",
        answer: [
          "rainy, cloudy/cloudy, rainy",
          "hot, sunny/sunny/ hot",
          "cold, snowy, icy",
          "foggy, cloudy, cold"
        ],
        // type: 'longAnwser'
      },
    ]
  },

  2: { // Exercise num
    unit: 'Unit 8',
    id: 'SB6-U8-P100-E2',
    audio: '',
    video: '',
    fontSize: 20,
    component: D1,
    paddingLeft: 8,
    exerciseKey: 'img/FriendsPlus/Page100/E2/Key/answerKey.png',
    titleImage: '',
    // titleQuestion: [{ num: '2', title: 'Complete the text with the words.', color: '#553f9f' }],
    questionImage: [ // Row

      [
        // Column
        { url: 'img/FriendsPlus/Page100/E2/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page100/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page100/E2/3.jpg', input: true, answer: 'rainy' },
        { url: 'img/FriendsPlus/Page100/E2/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page100/E2/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page100/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page100/E2/7.jpg', input: true, answer: 'hot' },
        { url: 'img/FriendsPlus/Page100/E2/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page100/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page100/E2/10.jpg', input: true, answer: 'cold' },
        { url: 'img/FriendsPlus/Page100/E2/11.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page100/E2/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page100/E2/13.jpg' },
        { url: 'img/FriendsPlus/Page100/E2/14.jpg', input: true, answer: 'icy' },
        { url: 'img/FriendsPlus/Page100/E2/15.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page100/E2/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page100/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page100/E2/18.jpg', input: true, answer: 'windy' },
        { url: 'img/FriendsPlus/Page100/E2/19.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page100/E2/20.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page100/E2/21.jpg' },
        { url: 'img/FriendsPlus/Page100/E2/22.jpg', input: true, answer: 'foggy' },
        { url: 'img/FriendsPlus/Page100/E2/23.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page100/E2/24.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page100/E2/25.jpg' },
        { url: 'img/FriendsPlus/Page100/E2/26.jpg', input: true, answer: 'stormy' },
        { url: 'img/FriendsPlus/Page100/E2/27.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page100/E2/28.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 8',
    id: 'SB6-U8-P100-E3',
    audio: 'img/FriendsPlus/Page100/E3/Audio/G6 SB Track 2.36.mp3',
    video: '',
    component: T1,
    // titleImage: "img/FriendsPlus/Page100/E4/1.png",
    exerciseKey: 'img/FriendsPlus/Page100/E3/Key/answerKey.png',
    titleQuestion: [{ color: '#E26D28', num: '3', title: 'Listen to an interview with explorer Stephanie Lowe. What do you think she is going to do in Antarctica? Listen and check your answer.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>What do you think she is going to do in Antarctica? #. </p>",
        answer: [
          "She's going to walk to the South Pole and study photos of the ice in Antarctica/She's going to walk to the South Pole and take photos of the ice in Antarctica"
        ],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 8',
    id: 'SB6-U8-P100-E4',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page100/E4/title.jpg",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },


  5: { // Exercise num
    unit: 'Unit 8',
    id: 'SB6-U8-P100-E5',
    audio: 'img/FriendsPlus/Page100/E5/Audio/G6 SB Track 2.36.mp3',
    video: '',
    component: MC3,
    exerciseKey: 'img/FriendsPlus/Page100/E5/Key/answerKey.png',
    titleQuestion: [{ num: '5', title: 'Listen again and choose the correct answers.', color: '#D97C40' }],
    //titleImage: 'http://',
    questionImage: [ // Row
      [
        // Column
        { url: 'http://' },
        { url: 'http://', input: true },
        { url: 'http://' },
      ],
    ],
    questions: [
      {
        title: 'Stephanie is also a ....... .',
        answers: [
          { image: "", isCorrect: false, right: "", text: "teacher" },
          { image: "", isCorrect: true, right: "", text: "photographer" },
          { image: "", isCorrect: false, right: "", text: "doctor" },
        ],
        no: 1,
        question: '',
        type: '',
      },
      {
        title: '98% of Antarctica is ....... .',
        answers: [
          { image: "", isCorrect: true, right: "", text: "ice" },
          { image: "", isCorrect: false, right: "", text: "snow" },
          { image: "", isCorrect: false, right: "", text: "water" },
        ],
        no: 2,
        question: '',
        type: '',
      },
      {
        title: 'In Antarctica Stephanie will see ....... .',
        answers: [
          { image: "", isCorrect: false, right: "", text: "polar bears" },
          { image: "", isCorrect: false, right: "", text: "polar bears and penguins" },
          { image: "", isCorrect: true, right: "", text: "penguins and whales" },
        ],
        no: 3,
        question: '',
        type: '',
      },
      {
        title: 'The weather in November will be ....... .',
        answers: [
          { image: "", isCorrect: true, right: "", text: "sunny, windy and cold" },
          { image: "", isCorrect: false, right: "", text: "sunny and cold" },
          { image: "", isCorrect: false, right: "", text: "cold and rainy" },
        ],
        no: 4,
        question: '',
        type: '',
      },
      {
        title: `The temperature will be about ........ .`,
        answers: [
          { image: "", isCorrect: false, right: "", text: "-13°C" },
          { image: "", isCorrect: false, right: "", text: "-33°C" },
          { image: "", isCorrect: true, right: "", text: "-30°C" },
        ],
        no: 5,
        question: '',
        type: '',
      },
      {
        title: `She’ll communicate with her friends and family with a special ........ .`,
        answers: [
          { image: "", isCorrect: false, right: "", text: "mobile phone" },
          { image: "", isCorrect: true, right: "", text: "laptop" },
          { image: "", isCorrect: false, right: "", text: "radio" },
        ],
        no: 6,
        question: '',
        type: '',
      },
    ]
  },
  6: { // Exercise num
    unit: 'Unit 8',
    id: 'SB6-U8-P100-E6',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page100/E6/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },

}
export default json