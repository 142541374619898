import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  5: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P39-E5',
    audio: 'img/FriendsPlus/Page39/E5/Audio/G6 SB Track 1.32.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page39/E5/Key/1.png',
    // titleImage: "img/FriendsPlus/Page40/E4/1.png",
    titleQuestion: [{ color: '#0D5F9B', num: '5', title: "Listen and identify the animal sounds. Compare your ideas with a partner's. Use the key phrases." }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span>#</p>" +
          "<p><span style='font-weight:600'>2</span>#</p>" +
          "<p><span style='font-weight:600'>3</span>#</p>" +
          "<p><span style='font-weight:600'>4</span>#</p>" +
          "<p><span style='font-weight:600'>5</span>#</p>" +
          "<p><span style='font-weight:600'>6</span>#</p>",
        answer: ['camel', 'whale', 'monkey', 'eagle', 'frog', 'bat'],
        type: 'longAnwser'
      },
    ]
  },
  6: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P39-E6',
    audio: '',
    video: '',
    component: T6,
    imgSize: '75%',
    exerciseKey: 'img/FriendsPlus/Page39/E6/Key/1.png',
    titleImage: "img/FriendsPlus/Page39/E6/1.jpg",
    titleQuestion: [{ color: '#0D5F9B', num: '6', title: "Look at the questions and check the meanings of the words in <span style='color:#33B1D6;'>blue</span>. What do you think the animal is?." }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> What do you think the animal is? #</p>",

        answer: ['a crocodile'],
        type: 'longAnwser'
      },
    ]
  },
  7: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P39-E7',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page39/E7/1.png",
    // titleQuestion: [{ num: '3', title: 'Read the study strategy. Then write five sentences with your ideas. Use the adjectives and the topics in the boxes. Compare with a partner.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}

export default json