import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import url from '../../configs/url';

const json = {

  1: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P68-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page68/E1/Key/answerKey.png',
    titleImage: '',
    // titleQuestion: [{ color: '#4F3A96', num: '1', title: 'Complete the words in the text.' }],
    questionImage: [ // Row

      [
        // Column
        { url: 'img/FriendsPlus/Page68/E1/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/3.jpg', input: true, answer: 'heese' },
        { url: 'img/FriendsPlus/Page68/E1/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E1/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/7.jpg', input: true, answer: 'hicken' },
        { url: 'img/FriendsPlus/Page68/E1/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E1/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/11.jpg', input: true, answer: 'hips' },
        { url: 'img/FriendsPlus/Page68/E1/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E1/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/15.jpg', input: true, answer: 'urger' },
        { url: 'img/FriendsPlus/Page68/E1/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E1/17.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/19.jpg', input: true, answer: 'uice' },
        { url: 'img/FriendsPlus/Page68/E1/20.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E1/21.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  2: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P68-E2',
    audio: 'img/FriendsPlus/Page68/E2/Audio/G6 SB Track 2.08.mp3',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page68/E2/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title: '',
        // "<p> # </p>" +
        // "<p> # </p>" +
        // "<p> # </p>" +
        // "<p> # </p>" +
        // "<p> # </p>" +
        // "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P68-E3',
    audio: 'img/FriendsPlus/Page68/E3/Audio/G6 SB Track 2.09.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page68/E3/Key/answerKey.png',
    titleImage: "img/FriendsPlus/Page68/E3/title.jpg",
    imgSize: '100%',
    // titleQuestion: [{ color: '#C43167', track: '2.09', num: '3', title: 'Read and say the prices. Listen and check.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1.</span> £2.25 # </p>" +
          "<p><span style='font-weight:600'>2.</span> €1.00 # </p>" +
          "<p><span style='font-weight:600'>3.</span> €0.50 # </p>" +
          "<p><span style='font-weight:600'>4.</span> $3.85 # </p>" +
          "<p><span style='font-weight:600'>5.</span> $7.20 # </p>" +
          "<p><span style='font-weight:600'>6.</span> £0.99 # </p>",
        answer: ['two pounds twenty-five', 'one euro', 'fifty cents', 'three dollars eighty-five', 'seven dollars twenty', 'ninety-nine pence'],
        type: 'longAnwser'
      },
    ]
  },

  4: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P68-E4',
    audio: 'img/FriendsPlus/Page68/E4/Audio/Friends Plus for Vietnam G6 SB Track 2.10.mp3',
    video: 'https://cloud.softech.vn/storage/SachSo/Videos/P68E4.mp4',
    videoSub: url.cdnUrl + '/P68E4.vtt',
    component: D1,
    imgSize: '70%',
    exerciseKey: 'img/FriendsPlus/Page68/E4/Key/answerKey.png',
    titleImage: 'img/FriendsPlus/Page68/E4/title.jpg',
    // titleQuestion: [{ color: '#4F3A96', num: '1', title: 'Complete the words in the text.' }],
    questionImage: [ // Row

      // [
      //   { url: 'img/FriendsPlus/Page68/E4/3.jpg' },
      //   { url: 'img/FriendsPlus/Page68/E4/4.jpg', videoUrl: 'img/FriendsPlus/Page68/E4/Video/EP1 Speaking - Unit 5 - FINAL.mp4' },
      //   { url: 'img/FriendsPlus/Page68/E4/5.jpg' },
      //   { url: 'img/FriendsPlus/Page68/E4/6.jpg' },
      // ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E4/7.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E4/8.jpg' },
        { url: 'img/FriendsPlus/Page68/E4/9.jpg' },
        { url: 'img/FriendsPlus/Page68/E4/10.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E4/11.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E4/12.jpg' },
        { url: 'img/FriendsPlus/Page68/E4/13.jpg' },
        { url: 'img/FriendsPlus/Page68/E4/14.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E4/15.jpg' },
        { url: 'img/FriendsPlus/Page68/E4/16.jpg' },
        { url: 'img/FriendsPlus/Page68/E4/17.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E4/18.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E4/19.jpg' },
        { url: 'img/FriendsPlus/Page68/E4/20.jpg', input: true, answer: '5.50' },
        { url: 'img/FriendsPlus/Page68/E4/21.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E4/22.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E4/23.jpg' },
        { url: 'img/FriendsPlus/Page68/E4/24.jpg', input: true, answer: "His meal isn't very healthy" },
        { url: 'img/FriendsPlus/Page68/E4/25.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E4/26.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P68-E5',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page68/E5/Key/answerKey.png',
    titleImage: '',
    // titleQuestion: [{ color: '#4F3A96', num: '1', title: 'Complete the words in the text.' }],
    questionImage: [ // Row

      [
        // Column
        { url: 'img/FriendsPlus/Page68/E5/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E5/2.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E5/3.jpg' },
        { url: 'img/FriendsPlus/Page68/E5/4.jpg', input: true, answer: 'Can' },
        { url: 'img/FriendsPlus/Page68/E5/5.jpg' },

      ],

      [
        // Column
        { url: 'img/FriendsPlus/Page68/E5/6.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E5/7.jpg' },
        { url: 'img/FriendsPlus/Page68/E5/8.jpg', input: true, answer: 'have a cheese sandwich' },
        { url: 'img/FriendsPlus/Page68/E5/9.jpg' },

      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E5/10.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E5/11.jpg' },
        { url: 'img/FriendsPlus/Page68/E5/12.jpg', input: true, answer: 'like salad or chips' },
        { url: 'img/FriendsPlus/Page68/E5/13.jpg' },

      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E5/14.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E5/15.jpg' },
        { url: 'img/FriendsPlus/Page68/E5/16.jpg', input: true, answer: 'Anything' },
        { url: 'img/FriendsPlus/Page68/E5/17.jpg' },

      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E5/18.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E5/19.jpg' },
        { url: 'img/FriendsPlus/Page68/E5/20.jpg', input: true, answer: 'have a cola' },
        { url: 'img/FriendsPlus/Page68/E5/21.jpg' },

      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E5/22.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E5/23.jpg' },
        { url: 'img/FriendsPlus/Page68/E5/24.jpg', input: true, answer: "That's 5.50" },
        { url: 'img/FriendsPlus/Page68/E5/25.jpg' },

      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page68/E5/26.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  6: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P68-E6',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page68/E6/title.jpg",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },

}
export default json