import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
// import T4 from '../../components/ExcerciseTypes/TypeIn/T4';

const json = {
  1: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P93-E1',
    audio: '',
    video: '',
    component: T1,
    titleImage: "img/FriendsPlus/Page93/E1/1.png",
    exerciseKey: 'img/FriendsPlus/Page93/E1/Key/answerKey.png',
    imgSize: '90%',
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          //   "<p> # </p>" +
          //   "<p> # </p>" +
          //   "<p> # </p>" +
          //   "<p> # </p>" +
          //   "<p> # </p>" +
          "<p> # </p>",
        answer: [
          "To describe a person's physical appearance and behaviour/To describe a prson",

        ],
        type: 'longAnwser'
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P93-E2',
    audio: '',
    video: '',
    component: D1,
    fontSize: 19,
    heigh: 30,
    isHiddenCheck: true,
    exerciseKey: 'img/FriendsPlus/Page93/E2/Key/1.png',
    titleImage: '',
    titleQuestion: [{ num: '2', title: 'Complete the Key Phrases with words in the description.', color: '#0caa50' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page93/E2/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page93/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page93/E2/3.jpg', input: true, answer: 'famous' },
        { url: 'img/FriendsPlus/Page93/E2/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page93/E2/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page93/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page93/E2/7.jpg', input: true, answer: 'best-known' },
        { url: 'img/FriendsPlus/Page93/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page93/E2/9.jpg', input: true, answer: 'the role(s) in ...' },
        { url: 'img/FriendsPlus/Page93/E2/10.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page93/E2/11.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page93/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page93/E2/13.jpg', input: true, answer: 'tall' },
        { url: 'img/FriendsPlus/Page93/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page93/E2/15.jpg', input: true, answer: 'strong' },
        { url: 'img/FriendsPlus/Page93/E2/16.jpg' },
        { url: 'img/FriendsPlus/Page93/E2/17.jpg', input: true, answer: 'good-looking' },
        { url: 'img/FriendsPlus/Page93/E2/18.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page93/E2/19.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page93/E2/20.jpg' },
        { url: 'img/FriendsPlus/Page93/E2/21.jpg', input: true, answer: 'fair hair, blue eyes, a beard and a moustache' },
        { url: 'img/FriendsPlus/Page93/E2/22.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page93/E2/23.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page93/E2/24.jpg' },
        { url: 'img/FriendsPlus/Page93/E2/25.jpg', input: true, answer: 'busy' },
        { url: 'img/FriendsPlus/Page93/E2/26.jpg' },
        { url: 'img/FriendsPlus/Page93/E2/27.jpg', input: true, answer: 'spends a lot of time outdoors with ...' },
        { url: 'img/FriendsPlus/Page93/E2/28.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page93/E2/29.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page93/E2/30.jpg' },
        { url: 'img/FriendsPlus/Page93/E2/31.jpg', input: true, answer: 'superhero' },
        { url: 'img/FriendsPlus/Page93/E2/32.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page93/E2/33.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P93-E3',
    audio: '',
    video: '',
    component: D1,
    fontSize: 28,
    height: 30,
    typeInput: true,
    exerciseKey: 'img/FriendsPlus/Page93/E3/Key/answerKey.png',
    titleImage: '',
    titleQuestion: [{ num: '3', title: 'Match 1–7 with a–g to complete the sentences.', color: '#0caa50' }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page93/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page93/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page93/E3/3.jpg', input: true, answer: 'f' },
        { url: 'img/FriendsPlus/Page93/E3/4.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page93/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page93/E3/6.jpg', input: true, answer: 'd' },
        { url: 'img/FriendsPlus/Page93/E3/7.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page93/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page93/E3/9.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page93/E3/10.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page93/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page93/E3/12.jpg', input: true, answer: 'b' },
        { url: 'img/FriendsPlus/Page93/E3/13.jpg' },
      ],
      [
        // Column14
        { url: 'img/FriendsPlus/Page93/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page93/E3/15.jpg', input: true, answer: 'g' },
      ],
      [
        // Column16
        { url: 'img/FriendsPlus/Page93/E3/16.jpg' },
        { url: 'img/FriendsPlus/Page93/E3/17.jpg', input: true, answer: 'c' },
        { url: 'img/FriendsPlus/Page93/E3/18.jpg' },
      ],
      [
        // Column19
        { url: 'img/FriendsPlus/Page93/E3/19.jpg' },
        { url: 'img/FriendsPlus/Page93/E3/20.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page93/E3/21.jpg' },
      ],
      [
        // Column22
        { url: 'img/FriendsPlus/Page93/E3/22.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P93-E4',
    audio: '',
    video: '',
    component: T1,
    fontSize: 26,
    // inputSize: 60,
    // titleImage: "img/FriendsPlus/Page93/E1/1.png",
    exerciseKey: 'img/FriendsPlus/Page93/E4/Key/answerKey.png',
    imgSize: '90%',
    titleQuestion: [{ color: '#0caa50', num: '4', title: 'Rewrite the text with the correct punctuation.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='margin-left:35px; margin-bottom:50px;'><p> jennifer lawrence is an american actor she was born in kentucky she is tall and shes got blonde </p>" +
          "<p> hair and blue eyes jennifers most famous role was a girl called katniss everdeen in the hunger games </p></div>" +
          "<p>#.</p>" +
          "<p>#.</p>" +
          "<p>#.</p>" +
          "<p>#.</p>",

        answer: [
          "Jennifer Lawrence is an American actor",
          "She was born in Kentucky",
          "She is tall and she's got blonde hair and blue eyes",
          "Jennifer's most famous role was a girl called Katniss Everdeen in The Hunger Games"
        ],
        type: 'longAnwser'
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P93-E5',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page93/E5/1.png",
    // imgSize: '90%',
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json