// import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T2 from "../../components/ExcerciseTypes/TypeIn/T2";
import UI from "../../components/ExcerciseTypes/UseIt";

const json = {
  4: {
    // Exercise num
    unit: "Unit 1",
    id: "SB6-U1-P15-E4",
    audio: "",
    video: "",
    component: UI,
    //titleImage: 'http://',
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page15/E4/1.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page15/E4/2.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page15/E4/3.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  4.1: {
    // Exercise num
    unit: "Unit 1",
    id: "SB6-U1-P15-E4.1",
    audio: "",
    video: "",
    component: T2,
    // exerciseKey: 'img/FriendsPlus/Page13/E4/Key/Key.png',
    titleImage: "img/FriendsPlus/Page15/E4.1/title.jpg",
    imgSize: "80%",
    // titleQuestion: [{ num: '4', title: 'Read the email and answer the questions.' }],
    questionImage: [],
    questions: [
      {
        title: "# # # # # # # # ",
        answer: [],
        type: "longAnwser",
      },
    ],
  },
};

export default json;
