import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import MC2 from '../../components/ExcerciseTypes/MultipleChoice/MC2';

const json = {

  1: { // Exercise num
    unit: 'Progress Review 3',
    id: 'SB6-PV3-P82-E1',
    audio: '',
    video: '',
    height: 32,
    fontSize: 29,
    paddingLeft: 1,
    paddingRight: 1,
    isHiddenCheck: true,
    typeInput: true,
    component: D1,
    isAutofocus: true,
    exerciseKey: 'img/FriendsPlus/Page82/E1/Key/Key.png',
    // questionImage: [],
    questionImage: [


      [//1
        { url: 'img/FriendsPlus/Page82/E1/1.jpg' },
      ],

      [//2
        { url: 'img/FriendsPlus/Page82/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page82/E1/3.jpg', input: true, answer: 'g' },
        { url: 'img/FriendsPlus/Page82/E1/4.jpg', input: true, answer: 'g' },
        { url: 'img/FriendsPlus/Page82/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page82/E1/6.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page82/E1/7.jpg', input: true, answer: 'c' },
        { url: 'img/FriendsPlus/Page82/E1/8.jpg', input: true, answer: 'o' },
        { url: 'img/FriendsPlus/Page82/E1/9.jpg', input: true, answer: 'n' },
        { url: 'img/FriendsPlus/Page82/E1/10.jpg' },
      ],

      [//3
        { url: 'img/FriendsPlus/Page82/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page82/E1/12.jpg', input: true, answer: 'u' },
        { url: 'img/FriendsPlus/Page82/E1/13.jpg', input: true, answer: 'i' },
        { url: 'img/FriendsPlus/Page82/E1/14.jpg', input: true, answer: 'c' },
        { url: 'img/FriendsPlus/Page82/E1/15.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page82/E1/16.jpg' },
      ],

      [//4

        { url: 'img/FriendsPlus/Page82/E1/17.jpg' },

      ],


      [//5

        { url: 'img/FriendsPlus/Page82/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page82/E1/19.jpg', input: true, answer: 'r ' },
        { url: 'img/FriendsPlus/Page82/E1/20.jpg', input: true, answer: 'u ' },
        { url: 'img/FriendsPlus/Page82/E1/21.jpg', input: true, answer: 'i ' },
        { url: 'img/FriendsPlus/Page82/E1/22.jpg', input: true, answer: 't ' },
        { url: 'img/FriendsPlus/Page82/E1/23.jpg' },

      ],
      [//6
        { url: 'img/FriendsPlus/Page82/E1/24.jpg' },
        { url: 'img/FriendsPlus/Page82/E1/25.jpg', input: true, answer: 'p' },
        { url: 'img/FriendsPlus/Page82/E1/26.jpg', input: true, answer: 'p' },
        { url: 'img/FriendsPlus/Page82/E1/27.jpg', input: true, answer: 'l' },
        { url: 'img/FriendsPlus/Page82/E1/28.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page82/E1/29.jpg' },


      ],

      [//7
        { url: 'img/FriendsPlus/Page82/E1/30.jpg' },
        { url: 'img/FriendsPlus/Page82/E1/31.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page82/E1/32.jpg', input: true, answer: 'l' },
        { url: 'img/FriendsPlus/Page82/E1/33.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page82/E1/34.jpg', input: true, answer: 'd' },
        { url: 'img/FriendsPlus/Page82/E1/35.jpg' },
      ],

      [//8
        { url: 'img/FriendsPlus/Page82/E1/36.jpg' },
      ],
      [//9
        { url: 'img/FriendsPlus/Page82/E1/37.jpg' },
        { url: 'img/FriendsPlus/Page82/E1/38.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page82/E1/39.jpg', input: true, answer: 'g' },
        { url: 'img/FriendsPlus/Page82/E1/40.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page82/E1/41.jpg', input: true, answer: 't' },
        { url: 'img/FriendsPlus/Page82/E1/42.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page82/E1/43.jpg', input: true, answer: 'b' },
        { url: 'img/FriendsPlus/Page82/E1/44.jpg', input: true, answer: 'l' },
        { url: 'img/FriendsPlus/Page82/E1/45.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page82/E1/46.jpg', input: true, answer: 's' },
        { url: 'img/FriendsPlus/Page82/E1/47.jpg' },
      ],
      [//10
        { url: 'img/FriendsPlus/Page82/E1/48.jpg' },
        { url: 'img/FriendsPlus/Page82/E1/49.jpg', input: true, answer: 'h' },
        { url: 'img/FriendsPlus/Page82/E1/50.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page82/E1/51.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page82/E1/52.jpg', input: true, answer: 's' },
        { url: 'img/FriendsPlus/Page82/E1/53.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page82/E1/54.jpg' },

      ],
      [//11

        { url: 'img/FriendsPlus/Page82/E1/55.jpg' },
        { url: 'img/FriendsPlus/Page82/E1/56.jpg', input: true, answer: 'u' },
        { url: 'img/FriendsPlus/Page82/E1/57.jpg', input: true, answer: 'r' },
        { url: 'img/FriendsPlus/Page82/E1/58.jpg', input: true, answer: 'g' },
        { url: 'img/FriendsPlus/Page82/E1/59.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page82/E1/60.jpg', input: true, answer: 'r' },
        { url: 'img/FriendsPlus/Page82/E1/61.jpg', input: true, answer: 's' },
        { url: 'img/FriendsPlus/Page82/E1/62.jpg' },
        { url: 'img/FriendsPlus/Page82/E1/63.jpg', input: true, answer: 'h' },
        { url: 'img/FriendsPlus/Page82/E1/64.jpg', input: true, answer: 'i' },
        { url: 'img/FriendsPlus/Page82/E1/65.jpg', input: true, answer: 'p' },
        { url: 'img/FriendsPlus/Page82/E1/66.jpg', input: true, answer: 's' },
        { url: 'img/FriendsPlus/Page82/E1/67.jpg' },
      ],
      [//12

        { url: 'img/FriendsPlus/Page82/E1/68.jpg' },

      ],

    ],

  },


  2: { // Exercise num
    unit: 'Progress Review 3',
    id: 'SB6-PV3-P82-E2',
    audio: '',
    video: '',
    component: D1,
    fontSize: 27,
    exerciseKey: 'img/FriendsPlus/Page82/E2/Key/answerKey.png',
    titleImage: '',
    titleQuestion: [{ num: '2', title: 'Complete the text with the words.', color: '#553f9f' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page82/E2/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page82/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page82/E2/3.jpg', input: true, answer: 'enormous' },
        { url: 'img/FriendsPlus/Page82/E2/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page82/E2/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page82/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page82/E2/7.jpg', input: true, answer: 'train' },
        { url: 'img/FriendsPlus/Page82/E2/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page82/E2/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page82/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page82/E2/11.jpg', input: true, answer: 'dish' },
        { url: 'img/FriendsPlus/Page82/E2/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page82/E2/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page82/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page82/E2/15.jpg', input: true, answer: 'bowls' },
        { url: 'img/FriendsPlus/Page82/E2/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page82/E2/17.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page82/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page82/E2/19.jpg', input: true, answer: 'vitamins' },
        { url: 'img/FriendsPlus/Page82/E2/20.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page82/E2/21.jpg' },

      ], [
        // Column
        { url: 'img/FriendsPlus/Page82/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page82/E2/23.jpg', input: true, answer: 'desserts' },
        { url: 'img/FriendsPlus/Page82/E2/24.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page82/E2/25.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit: 'Progress Review 3',
    id: 'SB6-PV3-P82-E4',
    audio: 'img/FriendsPlus/Page82/E4/Audio/G6 SB Track 2.21.WAV',
    video: '',
    component: D1,
    fontSize: 25,
    height: 27,
    exerciseKey: 'img/FriendsPlus/Page82/E4/Key/answerKey.png',
    titleImage: '',
    // titleQuestion: [{ num: '2', title: 'Complete the text with the words.', color: '#553f9f' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page82/E4/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page82/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page82/E4/3.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page82/E4/4.jpg' },
        { url: 'img/FriendsPlus/Page82/E4/5.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page82/E4/6.jpg' },
        { url: 'img/FriendsPlus/Page82/E4/7.jpg', input: true, answer: 'c' },
        { url: 'img/FriendsPlus/Page82/E4/8.jpg' },
        { url: 'img/FriendsPlus/Page82/E4/9.jpg', input: true, answer: 'g' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page82/E4/10.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page82/E4/11.jpg' },
        { url: 'img/FriendsPlus/Page82/E4/12.jpg', input: true, answer: 'b' },
        { url: 'img/FriendsPlus/Page82/E4/13.jpg' },
        { url: 'img/FriendsPlus/Page82/E4/14.jpg', input: true, answer: 'f' },
        { url: 'img/FriendsPlus/Page82/E4/15.jpg' },
        { url: 'img/FriendsPlus/Page82/E4/16.jpg', input: true, answer: 'd' },
        { url: 'img/FriendsPlus/Page82/E4/17.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    unit: 'Progress Review 3',
    id: 'SB6-PV3-P82-E3',
    audio: '',
    video: '',
    component: MC2,
    exerciseKey: 'img/FriendsPlus/Page82/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Choose the correct word.', color: '#40397B' }],
    //titleImage: 'http://',
    questionImage: [ // Row
      [
        // Column
        { url: 'http://' },
        { url: 'http://', input: true },
        { url: 'http://' },
      ],
    ],
    questions: [
      {
        title: 'There aren’t ....... sweets in this bag.',
        answers: [
          { image: "", isCorrect: false, right: "", text: "some" },
          { image: "", isCorrect: false, right: "", text: "much" },
          { image: "", isCorrect: true, right: "", text: "many" },
        ],
        no: 1,
        question: '',
        type: '',
      },
      {
        title: 'I haven’t got ....... cheese with my burger.',
        answers: [
          { image: "", isCorrect: true, right: "", text: "any" },
          { image: "", isCorrect: false, right: "", text: "some" },
          { image: "", isCorrect: false, right: "", text: "many" },
        ],
        no: 2,
        question: '',
        type: '',
      },
      {
        title: 'My sister eats ....... unhealthy snacks.',
        answers: [
          { image: "", isCorrect: false, right: "", text: "much" },
          { image: "", isCorrect: true, right: "", text: "a lot of" },
          { image: "", isCorrect: false, right: "", text: "any" },
        ],
        no: 3,
        question: '',
        type: '',
      },
      {
        title: 'There isn’t ....... soup in your bowl.',
        answers: [
          { image: "", isCorrect: false, right: "", text: "some" },
          { image: "", isCorrect: true, right: "", text: "much" },
          { image: "", isCorrect: false, right: "", text: "many" },
        ],
        no: 4,
        question: '',
        type: '',
      },
      {
        title: `Are there ........ nice dishes on the menu?`,
        answers: [
          { image: "", isCorrect: true, right: "", text: "any" },
          { image: "", isCorrect: false, right: "", text: "much" },
          { image: "", isCorrect: false, right: "", text: "a lot" },
        ],
        no: 5,
        question: '',
        type: '',
      },
      {
        title: `We’ve got ........ nice black grapes.`,
        answers: [
          { image: "", isCorrect: true, right: "", text: "some" },
          { image: "", isCorrect: false, right: "", text: "any" },
          { image: "", isCorrect: false, right: "", text: "much" },
        ],
        no: 6,
        question: '',
        type: '',
      },
    ]
  },
}
export default json