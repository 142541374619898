// import M1 from '../../components/Exercises/Matching/M1';
import MC1 from "../../components/ExcerciseTypes/MultipleChoice/MC1";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import UI from "../../components/ExcerciseTypes/UseIt";
import url from '../../configs/url';

const json = {
  1: {
    // Exercise num
    id: "SB6-S-P7-E1",
    unit: "Starter Unit",
    audio: "",
    video: url.cdnUrl + "/Animation - Starter grammar 1 trang 7.mp4",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page7/E1/Key/1.png",
    titleImage: "",
    titleQuestion: [
      {
        num: "1",
        title:
          "Complete the table with the words in the box.<br/> Then check your answers in the text on page 6.",
      },
    ],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page7/E1/3.jpg" }],
      [
        // Column
        { url: "img/FriendsPlus/Page7/E1/4.jpg" },
        { url: "img/FriendsPlus/Page7/E1/5.jpg", input: true, answer: "He" },
        { url: "img/FriendsPlus/Page7/E1/6.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page7/E1/7.jpg" },
        { url: "img/FriendsPlus/Page7/E1/8.jpg", input: true, answer: "they" },
        { url: "img/FriendsPlus/Page7/E1/9.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page7/E1/10.jpg" }],
      [
        // Column
        { url: "img/FriendsPlus/Page7/E1/11.jpg" },
        { url: "img/FriendsPlus/Page7/E1/12.jpg", input: true, answer: "not" },
        { url: "img/FriendsPlus/Page7/E1/13.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page7/E1/14.jpg" }],
      [
        // Column
        { url: "img/FriendsPlus/Page7/E1/15.jpg" },
        {
          url: "img/FriendsPlus/Page7/E1/16.jpg",
          input: true,
          answer: "isn't",
        },
        { url: "img/FriendsPlus/Page7/E1/17.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page7/E1/18.jpg" },
        {
          url: "img/FriendsPlus/Page7/E1/19.jpg",
          input: true,
          answer: "aren't",
        },
        { url: "img/FriendsPlus/Page7/E1/20.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page7/E1/21.jpg" }],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P7-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseWidth: "50%",
    exerciseKey: "img/FriendsPlus/Page7/E2/Key/key.png",
    titleImage: "",
    titleQuestion: [
      {
        num: "2",
        title:
          "Write true sentences. Use affirmative and negative forms of the verb <i>be</i>.",
      },
    ],
    questionImage: [],
    questions: [
      {
        title:
          "<p style=' font-family:OneStrokeScriptStd-Bold'>Kate is interested in sport.</p>" +
          "<p><span style='font-weight:600'>1</span> Zebras # animals.</p>" +
          "<p><span style='font-weight:600'>2</span> Oxford # in Brazil.</p>" +
          "<p><span style='font-weight:600'>3</span> My friends # twelve.</p>" +
          "<p><span style='font-weight:600'>4</span> The name of this book # <i>Friends Plus</i>.</p>" +
          "<p><span style='font-weight:600'>5</span> I # interested in video games.</p>" +
          "<p><span style='font-weight:600'>6</span> I # from Đà Nẵng.</p>",
        answer: [
          "are",
          "isn't",
          "are / aren't",
          "is",
          "'m / 'm not",
          "'m / 'm not",
        ],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P7-E3",
    // audio: 'img/FriendsPlus/Page6/E2/Audio/03- Starter Unit - Track 03.mp3',
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page7/E3/Key/answerKey.png",
    //titleImage: 'http://',
    // titleQuestion: [{ num: '2', title: 'Write true sentences. Use affirmative an negative forms of the verb be.' }],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page7/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page7/E3/2.jpg" },
        { url: "img/FriendsPlus/Page7/E3/3.jpg", input: true, answer: "our" },
        { url: "img/FriendsPlus/Page7/E3/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page7/E3/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page7/E3/6.jpg" },
        { url: "img/FriendsPlus/Page7/E3/7.jpg", input: true, answer: "your" },
        { url: "img/FriendsPlus/Page7/E3/8.jpg" },
        { url: "img/FriendsPlus/Page7/E3/9.jpg", input: true, answer: "your" },
        { url: "img/FriendsPlus/Page7/E3/10.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page7/E3/11.jpg" }],
      [
        { url: "img/FriendsPlus/Page7/E3/12.jpg" },
        { url: "img/FriendsPlus/Page7/E3/13.jpg", input: true, answer: "his" },
        { url: "img/FriendsPlus/Page7/E3/14.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page7/E3/15.jpg" }],
      [
        { url: "img/FriendsPlus/Page7/E3/16.jpg" },
        { url: "img/FriendsPlus/Page7/E3/17.jpg", input: true, answer: "her" },
        { url: "img/FriendsPlus/Page7/E3/18.jpg" },
        {
          url: "img/FriendsPlus/Page7/E3/19.jpg",
          input: true,
          answer: "their",
        },
        { url: "img/FriendsPlus/Page7/E3/20.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page7/E3/21.jpg" }],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P7-E4",
    audio: "",
    video: "",
    component: T6,
    titleImage: "",
    exerciseKey: "img/FriendsPlus/Page7/E4/Key/1.png",
    titleQuestion: [
      { num: "4", title: "Replace the words in blue with subject pronouns." },
    ],
    questionImage: [],
    questions: [
      {
        title:
          '<p><b style="color:#33B1D6;">Marc</b> is from Paris. <b style="color:#8E8F94;">He</b> is from Paris.</p><p>1 <b style="color:#33B1D6;">This book</b> is good. # </p><p>2 <b style="color:#33B1D6;">Jen and Ed</b> are from the UK. # </p><p>3 <b style="color:#33B1D6;">Anya and I</b> are friends. # </p><p> 4 <b style="color:#33B1D6;">You and Mick</b> are brothers. # </p>',
        answer: [
          "It is good",
          "They are from the UK",
          "We are friends",
          "You are brothers",
        ],
        type: "longAnwser",
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P7-E5",
    audio: "",
    video: "",
    component: D1,
    height: 22,
    // titleImage: 'img/FriendsPlus/Page7/E2/1.jpg',
    exerciseKey: "img/FriendsPlus/Page7/E5/Key/1.png",
    titleQuestion: [
      {
        num: "5",
        title:
          "Match the object pronouns in the box with the subject pronouns.",
      },
    ],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page7/E5/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page7/E5/2.jpg" },
        { url: "img/FriendsPlus/Page7/E5/3.jpg", input: true, answer: "you" },
        { url: "img/FriendsPlus/Page7/E5/4.jpg" },
        { url: "img/FriendsPlus/Page7/E5/5.jpg", input: true, answer: "it" },
        { url: "img/FriendsPlus/Page7/E5/6.jpg" },
        { url: "img/FriendsPlus/Page7/E5/7.jpg", input: true, answer: "him" },
        { url: "img/FriendsPlus/Page7/E5/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page7/E5/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page7/E5/10.jpg" },
        { url: "img/FriendsPlus/Page7/E5/11.jpg", input: true, answer: "her" },
        { url: "img/FriendsPlus/Page7/E5/12.jpg" },
        { url: "img/FriendsPlus/Page7/E5/13.jpg", input: true, answer: "us" },
        { url: "img/FriendsPlus/Page7/E5/14.jpg" },
        {
          url: "img/FriendsPlus/Page7/E5/15_1.jpg",
          input: true,
          answer: "you",
        },
        { url: "img/FriendsPlus/Page7/E5/15_2.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page7/E5/16.jpg" }],
      [
        { url: "img/FriendsPlus/Page7/E5/17.jpg" },
        { url: "img/FriendsPlus/Page7/E5/18.jpg", input: true, answer: "them" },
        { url: "img/FriendsPlus/Page7/E5/19.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  6: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P7-E6",
    audio: "",
    video: "",
    component: MC1,
    exerciseKey: "img/FriendsPlus/Page7/E6/Key/answerKey.png",
    titleQuestion: [
      {
        num: "6",
        title:
          "Circle the word that differs from the other three in the position of primary stress.",
      },
    ],
    titleImage: "",
    questionImage: [
      // Row
      [
        // Column
        { url: "http://" },
        { url: "http://", input: true },
        { url: "http://" },
      ],
    ],
    questions: [
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "arrive" },
          { image: "", isCorrect: false, right: "", text: "begin" },
          { image: "", isCorrect: true, right: "", text: "answer" },
          { image: "", isCorrect: false, right: "", text: "machine" },
        ],
        no: 1,
        question: "",
        type: "",
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "visit" },
          { image: "", isCorrect: false, right: "", text: "enter" },
          { image: "", isCorrect: false, right: "", text: "under" },
          { image: "", isCorrect: true, right: "", text: "about" },
        ],
        no: 2,
        question: "",
        type: "",
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "along" },
          { image: "", isCorrect: false, right: "", text: "papaya" },
          { image: "", isCorrect: false, right: "", text: "across" },
          { image: "", isCorrect: true, right: "", text: "into" },
        ],
        no: 3,
        question: "",
        type: "",
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "Brazil" },
          { image: "", isCorrect: false, right: "", text: "Japan" },
          { image: "", isCorrect: true, right: "", text: "China" },
          { image: "", isCorrect: false, right: "", text: "Korea" },
        ],
        no: 4,
        question: "",
        type: "",
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "happen" },
          { image: "", isCorrect: false, right: "", text: "final" },
          { image: "", isCorrect: false, right: "", text: "living" },
          { image: "", isCorrect: true, right: "", text: "alive" },
        ],
        no: 5,
        question: "",
        type: "",
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "alarm" },
          { image: "", isCorrect: true, right: "", text: "college" },
          { image: "", isCorrect: false, right: "", text: "mistake" },
          { image: "", isCorrect: false, right: "", text: "cartoon" },
        ],
        no: 6,
        question: "",
        type: "",
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "again" },
          { image: "", isCorrect: false, right: "", text: "above" },
          { image: "", isCorrect: true, right: "", text: "really" },
          { image: "", isCorrect: false, right: "", text: "alike" },
        ],
        no: 7,
        question: "",
        type: "",
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "computer" },
          { image: "", isCorrect: true, right: "", text: "beautiful" },
          { image: "", isCorrect: false, right: "", text: "continuous" },
          { image: "", isCorrect: false, right: "", text: "deliver" },
        ],
        no: 8,
        question: "",
        type: "",
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "Vietnamese" },
          { image: "", isCorrect: false, right: "", text: "Japanese" },
          { image: "", isCorrect: true, right: "", text: "Cambodia" },
          { image: "", isCorrect: false, right: "", text: "Indonesia" },
        ],
        no: 9,
        question: "",
        type: "",
      },
    ],
  },
  7: {
    // Exercise num
    unit: "Starter Unit",
    id: "SB6-S-P7-E7",
    audio: "",
    video: "",
    component: UI,
    exerciseKey: "",
    //titleImage: 'http://',
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page7/E7/1.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page7/E7/2.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page7/E7/3.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
};

export default json;
