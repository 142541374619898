import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import url from '../../configs/url';



const json = {
  1: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P78-E1',
    audio: 'img/FriendsPlus/Page78/E1/Audio/G6 SB Track 2.18.mp3',
    video: 'https://cloud.softech.vn/storage/SachSo/Videos/P78E1_2.mp4',
    videoSub: url.cdnUrl + '/P78E1_2.vtt',
    component: D1,
    fontSize: 24,
    exerciseKey: 'img/FriendsPlus/Page78/E1/Key/answerKey.png',
    titleImage: '',
    // titleQuestion: [{ num: '1', title: 'Complete the words in the sentences.', color:'#553f9f' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page78/E1/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page78/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page78/E1/3.jpg', input: true, answer: 'was' },
        { url: 'img/FriendsPlus/Page78/E1/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page78/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page78/E1/6.jpg', input: true, answer: "was" },
        { url: 'img/FriendsPlus/Page78/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page78/E1/8.jpg', input: true, answer: "went" },
        { url: 'img/FriendsPlus/Page78/E1/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page78/E1/10.jpg' },

      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page78/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page78/E1/12.jpg', input: true, answer: 'Was' },
        { url: 'img/FriendsPlus/Page78/E1/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page78/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page78/E1/15.jpg', input: true, answer: 'were' },
        { url: 'img/FriendsPlus/Page78/E1/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page78/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page78/E1/18.jpg', input: true, answer: 'won' },
        { url: 'img/FriendsPlus/Page78/E1/19.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page78/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page78/E1/21.jpg', input: true, answer: 'were' },
        { url: 'img/FriendsPlus/Page78/E1/22.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page78/E1/23.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  2: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P78-E2',
    audio: 'img/FriendsPlus/Page78/E1/Audio/G6 SB Track 2.18.mp3',
    video: 'https://cloud.softech.vn/storage/SachSo/Videos/P78E1_2.mp4',
    videoSub: url.cdnUrl + '/P78E1_2.vtt',
    component: D1,
    fontSize: 25,
    height: 32,
    exerciseKey: 'img/FriendsPlus/Page78/E2/Key/Key.png',
    titleImage: '',
    // titleQuestion: [{ num: '1', title: 'Complete the words in the sentences.', color:'#553f9f' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page78/E2/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page78/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page78/E2/3.jpg', input: true, answer: 'How' },
        { url: 'img/FriendsPlus/Page78/E2/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page78/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page78/E2/6.jpg', input: true, answer: "That's" },
        { url: 'img/FriendsPlus/Page78/E2/7.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page78/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page78/E2/9.jpg', input: true, answer: 'Who' },
        { url: 'img/FriendsPlus/Page78/E2/10.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page78/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page78/E2/12.jpg', input: true, answer: 'Why' },
        { url: 'img/FriendsPlus/Page78/E2/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page78/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page78/E2/15.jpg', input: true, answer: 'Why' },
        { url: 'img/FriendsPlus/Page78/E2/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page78/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page78/E2/18.jpg', input: true, answer: 'When' },
        { url: 'img/FriendsPlus/Page78/E2/19.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page78/E2/20.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P78-E3',
    audio: 'img/FriendsPlus/Page78/E3/Audio/G6 SB Track 2.19.mp3',
    video: '',
    component: D1,
    fontSize: 22,
    height: 25,
    exerciseKey: 'img/FriendsPlus/Page78/E3/Key/1.png',
    titleImage: '',
    titleQuestion: [{ num: '3', title: 'Look at the phrases. Then listen to three mini-dialogues. Which phrases do you hear?', color: '#d31568' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page78/E3/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page78/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page78/E3/3.jpg', input: true, answer: "It wasn't great. I went to the cinema on Saturday. The film was really boring / It was brilliant. I went to a tennis match. The game was amazing / It was OK. I went to a restaurant with my parents. The food was nice" },
        { url: 'img/FriendsPlus/Page78/E3/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page78/E3/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page78/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page78/E3/7.jpg', input: true, answer: "It wasn't great. I went to the cinema on Saturday. The film was really boring / It was brilliant. I went to a tennis match. The game was amazing / It was OK. I went to a restaurant with my parents. The food was nice" },
        { url: 'img/FriendsPlus/Page78/E3/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page78/E3/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page78/E3/10.jpg' },
        { url: 'img/FriendsPlus/Page78/E3/11.jpg', input: true, answer: "It wasn't great. I went to the cinema on Saturday. The film was really boring / It was brilliant. I went to a tennis match. The game was amazing / It was OK. I went to a restaurant with my parents. The food was nice" },
        { url: 'img/FriendsPlus/Page78/E3/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page78/E3/13.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P78-E4',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page78/E4/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P78-E5',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page78/E5/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json