import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import url from '../../configs/url';


const json = {
  1: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P89-E1',
    audio: '',
    video: url.cdnUrl + '/Animation - Unit 7 grammar 1 trang 89.mp4',
    component: T6,
    inputSize: 600,
    exerciseKey: 'img/FriendsPlus/Page89/E1/Key/key.png',
    titleImage: "img/FriendsPlus/Page89/E1/1.png",
    titleQuestion: [{ num: '1', title: 'Study the examples. Find other examples of the negative form in the text. How do we form the past simple negative? Read the rule to check.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>&ensp;&ensp;&ensp;&ensp; # </p>" +
          "<p>&ensp;&ensp;&ensp;&ensp; # </p>",
        answer: [
          "didn't want", "didn't change",
        ],
        type: 'longAnwser'
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P89-E2',
    audio: '',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page89/E2/Key/answerKey.png',
    inputSize: 100,
    fontSize: 30,
    titleQuestion: [{ num: '2', title: 'Write the opposite forms of the sentences.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='font-family:Rubik-Medium'><p>She had curly hair when she was a baby.</p>" +
          "<p style='font-family:OneStrokeScriptStd-Bold;color:gray'>She didn’t have curly hair when she was a baby. </p>" +
          "<p><span style='font-weight:600'>1 &ensp; </span> I read a book last night. #. </p>" +
          "<p><span style='font-weight:600'>2 &ensp; </span> My brother didn't start a new school last year. #. </p>" +
          "<p><span style='font-weight:600'>3 &ensp; </span> We played basketball yesterday. #. </p>" +
          "<p><span style='font-weight:600'>4 &ensp; </span> My cousin gave me a present for my birthday. #. </p>" +
          "<p><span style='font-weight:600'>5 &ensp; </span> I didn't like playing football when I was young. #. </p>" +
          "<p><span style='font-weight:600'>6 &ensp; </span> My best friend didn't go on holiday last year. #. </p></div>",
        answer: [
          "I didn't read a book last night",
          'My brother started a new school last year',
          "We didn't play basketball yesterday",
          "My cousin didn't give me a present for my birthday",
          "I liked playing football when I was young",
          "My best friend went on holiday last year"
        ],
        type: 'longAnwser'
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P89-E3',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page89/E3/Key/1.png',
    titleImage: '',
    titleQuestion: [{ num: '3', title: 'Complete the text with the affirmative or negative form of the verbs.', color: '#17bbd8' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page89/E3/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page89/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page89/E3/3.jpg', input: true, answer: 'acted' },
        { url: 'img/FriendsPlus/Page89/E3/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page89/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page89/E3/6.jpg', input: true, answer: "didn't make" },
        { url: 'img/FriendsPlus/Page89/E3/7.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page89/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page89/E3/9.jpg', input: true, answer: "didn't go" },
        { url: 'img/FriendsPlus/Page89/E3/10.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page89/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page89/E3/12.jpg', input: true, answer: 'gave' },
        { url: 'img/FriendsPlus/Page89/E3/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page89/E3/14.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page89/E3/15.jpg' },
        { url: 'img/FriendsPlus/Page89/E3/16.jpg', input: true, answer: "didn't have" },
        { url: 'img/FriendsPlus/Page89/E3/17.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page89/E3/18.jpg' },
        { url: 'img/FriendsPlus/Page89/E3/19.jpg', input: true, answer: 'loved' },
        { url: 'img/FriendsPlus/Page89/E3/20.jpg' },
        { url: 'img/FriendsPlus/Page89/E3/21.jpg', input: true, answer: 'had' },
        { url: 'img/FriendsPlus/Page89/E3/22.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page89/E3/23.jpg' },

      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P89-E4',
    audio: '',
    video: '',
    component: D1,
    fontSize: 26,
    height: 28,
    exerciseKey: 'img/FriendsPlus/Page89/E4/Key/answerKey.png',
    titleImage: '',
    // titleQuestion: [{ num: '4', title: 'Complete the conversation with the correct form of the verbs in the box.', color: '#17bbd8' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page89/E4/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page89/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page89/E4/3.jpg', input: true, answer: 'had' },
        { url: 'img/FriendsPlus/Page89/E4/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page89/E4/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page89/E4/6.jpg' },
        { url: 'img/FriendsPlus/Page89/E4/7.jpg', input: true, answer: 'loved' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page89/E4/8.jpg' },
        { url: 'img/FriendsPlus/Page89/E4/9.jpg', input: true, answer: "didn't like" },
        { url: 'img/FriendsPlus/Page89/E4/10.jpg' },
        { url: 'img/FriendsPlus/Page89/E4/11.jpg', input: true, answer: 'wanted' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page89/E4/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page89/E4/13.jpg' },
        { url: 'img/FriendsPlus/Page89/E4/14.jpg', input: true, answer: "didn't have" },
        { url: 'img/FriendsPlus/Page89/E4/15.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page89/E4/16.jpg' },
        { url: 'img/FriendsPlus/Page89/E4/17.jpg', input: true, answer: 'got' },
        { url: 'img/FriendsPlus/Page89/E4/18.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page89/E4/19.jpg' },
        { url: 'img/FriendsPlus/Page89/E4/20.jpg', input: true, answer: 'started' },
        { url: 'img/FriendsPlus/Page89/E4/21.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page89/E4/22.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P89-E5',
    audio: '',
    video: '',
    component: T6,
    inputSize: 200,
    // titleImage: "img/FriendsPlus/Page89/E2/1.png",
    // imgSize: '20%',
    // inputSize: 100,
    // textCenter: true,
    exerciseKey: 'img/FriendsPlus/Page89/E5/Key/answerKey.png',
    titleQuestion: [{ num: '5', title: 'Write about your experiences. Use the affirmative or negative form of the verbs.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> # (live) in another city when I was younger. </p>" +
          "<p><span style='font-weight:600'>2</span> # (have) different colour hair when I was a baby. </p>" +
          "<p><span style='font-weight:600'>3</span> # (like) sweets when I was young. </p>" +
          "<p><span style='font-weight:600'>4</span> # (go) to another country last year. </p>" +
          "<p><span style='font-weight:600'>5</span> # (see) a good film last month. </p>" +
          "<p><span style='font-weight:600'>6</span> # (watch) sport on TV last night. </p>" +
          "<p><span style='font-weight:600'>7</span> # (play) video games last weekend. </p>" +
          "<p><span style='font-weight:600'>8</span> # (visit) my grandparents in January. </p>" +
          "<p><span style='font-weight:600'>9</span> # (eat) soup yesterday. </p>",

        answer: [
          "I lived / I didn't live",
          "I had / I didn't have",
          "I liked / I didn't like",
          "I went / I didn't go",
          "I saw / I didn't see",
          "I watched / I didn't watch",
          "I played / I didn't play",
          "I visited / I didn't visit",
          "I ate / I didn't eat",
        ],
        type: 'longAnwser'
      },
    ]
  },
  6: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P89-E6',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page89/E6/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json