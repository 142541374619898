import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';


const json = {
  1: { // Exercise num
    
    unit : 'Option 7',
    id : 'SB6-OPE7-P116-E1',
    audio: '',
    video: '',
    component: T6,
    // inputSize: 500,
    exerciseKey: 'img/FriendsPlus/Page116/E1/Key/answerKey.png',
    // titleImage: "img/FriendsPlus/Page79/E2/1.png",
    imgSize: '60%',
    titleQuestion: [{ num: '1', title: 'Look at photos A–C. Match the photos with the activities.', color: '#E26D28' }],
    questionImage: [],
    textCenter: true,
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> a school trip # </p>" +
          "<p><span style='font-weight:600'>2</span> a family reunion # </p>" +
          "<p><span style='font-weight:600'>3</span> an after-school club # </p>",
        answer: [
          'B', 'C', 'A'
        ],
        type: ''
      },
    ]
  },
  2: { // Exercise num
    
    unit : 'Option 7',
    id : 'SB6-OPE7-P116-E2',
    audio: 'img/FriendsPlus/Page116/E2/Audio/G6 SB Track 3.17.mp3',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page116/E2/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '2', title: 'Complete the Key Phrases with words in the description.', color: '#0caa50' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page116/E2/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page116/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page116/E2/3.jpg', input: true, answer: 'Amy' },
        { url: 'img/FriendsPlus/Page116/E2/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page116/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page116/E2/6.jpg', input: true, answer: 'Jack' },
        { url: 'img/FriendsPlus/Page116/E2/7.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page116/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page116/E2/9.jpg', input: true, answer: 'Beth' },
        { url: 'img/FriendsPlus/Page116/E2/10.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page116/E2/11.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    
    unit : 'Option 7',
    id : 'SB6-OPE7-P116-E3',
    audio: 'img/FriendsPlus/Page116/E2/Audio/G6 SB Track 3.17.mp3',
    video: '',
    component: T6,
    // inputSize: 500,
    // titleImage: "img/FriendsPlus/Page79/E2/1.png",
    exerciseKey: 'img/FriendsPlus/Page116/E3/Key/answerKey.png',
    imgSize: '60%',
    inputSize: 500,
    titleQuestion: [{ num: '3', title: 'Listen again and answer the questions.', color: '#E26D28' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> Why wasn’t Mark in the photo? #. </p>" +
          "<p><span style='font-weight:600'>2</span> What did they make? # </p>" +
          "<p><span style='font-weight:600'>3</span> Who made the best cake? # </p>" +
          "<p><span style='font-weight:600'>3</span> Who took the photo? # </p>",
        answer: [
          'He went to see the doctor', 'Chocolate cakes', 'Beth', 'Suzi'
        ],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    
    unit : 'Option 7',
    id : 'SB6-OPE7-P116-E4',
    audio: 'img/FriendsPlus/Page116/E4/Audio/G6 SB Track 3.18.mp3',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page116/E4/1.png",
    imgSize: '80%',
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title: '',
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  5: { // Exercise num
    
    unit : 'Option 7',
    id : 'SB6-OPE7-P116-E5',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page116/E5/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },

}
export default json