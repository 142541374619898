import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  1: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P69-E1',
    audio: '',
    video: '',
    component: T1,
    //imgSize: 950,
    exerciseKey: 'img/FriendsPlus/Page69/E1/Key/answerKey.png',
    titleImage: "img/FriendsPlus/Page69/E1/1.jpg",
    titleQuestion: [{ color: '#009241', num: '1', title: 'Check the meanings of the words in the glossary. Then read Sally’s blog. What does she have for the starter? What is her favourite part of the meal?' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1.</span> What does she have for the starter? #. </p>" +
          "<p><span style='font-weight:600'>2.</span>  What is her favourite part of the meal? #. </p>",

        answer: ['She has egg salads', 'Her favourite part of the meal is the dessert'],
        type: 'longAnwser'
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P69-E2',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page69/E2/Key/answerKey.png',
    titleImage: '',
    // titleQuestion: [{ color: '#4F3A96', num: '1', title: 'Complete the words in the text.' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page69/E2/20.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page69/E2/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page69/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/3.jpg', input: true, answer: 'Simple' },
        { url: 'img/FriendsPlus/Page69/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/5.jpg', input: true, answer: 'great' },
        { url: 'img/FriendsPlus/Page69/E2/6.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page69/E2/7.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page69/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/9.jpg', input: true, answer: 'yummy' },
        { url: 'img/FriendsPlus/Page69/E2/10.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page69/E2/11.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page69/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/13.jpg', input: true, answer: 'meal' },
        { url: 'img/FriendsPlus/Page69/E2/14.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page69/E2/15.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page69/E2/16.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/17.jpg', input: true, answer: 'tomorrow' },
        { url: 'img/FriendsPlus/Page69/E2/18.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page69/E2/19.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P69-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page69/E3/Key/answerKey.png',
    // titleImage: "img/FriendsPlus/Page68/E6/1.png",
    titleQuestion: [{ color: '#009241', num: '3', title: 'Look at the words and phrases in <span style="color:#33B1D6;">blue</span> in the blog. Which two have the same meaning?' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1.</span> # </p>",
        answer: ['Next and Then'],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P69-E4',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page69/E4/Key/answerKey.png',
    titleImage: '',
    // titleQuestion: [{ color: '#4F3A96', num: '1', title: 'Complete the words in the text.' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page69/E4/3.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page69/E4/5.jpg' },
        { url: 'img/FriendsPlus/Page69/E4/6.jpg', input: true, answer: 'Then/Next' },
        { url: 'img/FriendsPlus/Page69/E4/7.jpg' },
        { url: 'img/FriendsPlus/Page69/E4/8.jpg', input: true, answer: '2' },
        { url: 'img/FriendsPlus/Page69/E4/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page69/E4/10.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page69/E4/11.jpg' },
        { url: 'img/FriendsPlus/Page69/E4/12.jpg', input: true, answer: 'First' },
        { url: 'img/FriendsPlus/Page69/E4/13.jpg' },
        { url: 'img/FriendsPlus/Page69/E4/14.jpg', input: true, answer: '1' },
        { url: 'img/FriendsPlus/Page69/E4/15.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page69/E4/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page69/E4/17.jpg' },
        { url: 'img/FriendsPlus/Page69/E4/18.jpg', input: true, answer: 'Finally' },
        { url: 'img/FriendsPlus/Page69/E4/19.jpg' },
        { url: 'img/FriendsPlus/Page69/E4/20.jpg', input: true, answer: '4' },
        { url: 'img/FriendsPlus/Page69/E4/21.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page69/E4/22.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page69/E4/23.jpg' },
        { url: 'img/FriendsPlus/Page69/E4/24.jpg', input: true, answer: 'Next/Then' },
        { url: 'img/FriendsPlus/Page69/E4/25.jpg' },
        { url: 'img/FriendsPlus/Page69/E4/26.jpg', input: true, answer: '3' },
        { url: 'img/FriendsPlus/Page69/E4/27.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page69/E4/28.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P69-E5',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page69/E5/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },

}
export default json