import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/UnderLine";
import T2 from "../../components/ExcerciseTypes/TypeIn/T2";
import url from '../../configs/url';

const json = {
  1: {
    // Exercise num
    unit: "Unit 8",
    id: "SB6-U8-P101-E1",
    audio: "",
    video: url.cdnUrl + "/Animation - Unit 8 grammar 2 trang 101.mp4",
    component: D1,
    fontSize: 19,
    exerciseKey: "img/FriendsPlus/Page101/E1/Key/1.png",
    titleImage: "",
    titleQuestion: [
      {
        num: "1",
        title:
          "Study the examples in the table. In your notebook, complete the Rules with <i>condition</i> and <i>result</i>.",
        color: "#19c2d9",
      },
    ],
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page101/E1/1.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page101/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page101/E1/3.jpg",
          input: true,
          answer: "condition",
        },
        { url: "img/FriendsPlus/Page101/E1/4.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page101/E1/5.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page101/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page101/E1/7.jpg",
          input: true,
          answer: "result",
        },
        { url: "img/FriendsPlus/Page101/E1/8.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page101/E1/9.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page101/E1/10.jpg" },
        {
          url: "img/FriendsPlus/Page101/E1/11.jpg",
          input: true,
          answer: "condition",
        },
        { url: "img/FriendsPlus/Page101/E1/12.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page101/E1/13.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 8",
    id: "SB6-U8-P101-E2",
    audio: "",
    video: "",
    component: DesignUnderLine,
    totalInput: 7,
    // titleImage: 'img/FriendsPlus/Page101/E2/1.png',
    exerciseKey: "img/FriendsPlus/Page101/E2/Key/answerKey.png",
    // titleQuestion: [{ num: '2', title: 'Check the meanings of the words in blue. Then choose the correct words in the quiz.' }],
    questionImage: [
      // Row

      [{ url: "img/FriendsPlus/Page101/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page101/E2/2.jpg" },
        { url: "img/FriendsPlus/Page101/E2/3.jpg", input: 1, isCorrect: false },
        { url: "img/FriendsPlus/Page101/E2/4.jpg" },
        { url: "img/FriendsPlus/Page101/E2/5.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page101/E2/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page101/E2/7.jpg" }],
      [
        { url: "img/FriendsPlus/Page101/E2/8.jpg" },
        { url: "img/FriendsPlus/Page101/E2/9.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page101/E2/10.jpg" },
        {
          url: "img/FriendsPlus/Page101/E2/11.jpg",
          input: 2,
          isCorrect: false,
        },
        { url: "img/FriendsPlus/Page101/E2/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page101/E2/13.jpg" }],
      [
        { url: "img/FriendsPlus/Page101/E2/14.jpg" },
        { url: "img/FriendsPlus/Page101/E2/15.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page101/E2/16.jpg" },
        {
          url: "img/FriendsPlus/Page101/E2/17.jpg",
          input: 3,
          isCorrect: false,
        },
        { url: "img/FriendsPlus/Page101/E2/18.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page101/E2/19.jpg" }],
      [
        { url: "img/FriendsPlus/Page101/E2/20.jpg" },
        { url: "img/FriendsPlus/Page101/E2/21.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page101/E2/22.jpg" },
        {
          url: "img/FriendsPlus/Page101/E2/23.jpg",
          input: 4,
          isCorrect: false,
        },
        { url: "img/FriendsPlus/Page101/E2/24.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page101/E2/25.jpg" },
        {
          url: "img/FriendsPlus/Page101/E2/26.jpg",
          input: 5,
          isCorrect: false,
        },
        { url: "img/FriendsPlus/Page101/E2/27.jpg" },
        { url: "img/FriendsPlus/Page101/E2/28.jpg", input: 5, isCorrect: true },
        { url: "img/FriendsPlus/Page101/E2/29.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page101/E2/30.jpg" }],
      [
        { url: "img/FriendsPlus/Page101/E2/31.jpg" },
        { url: "img/FriendsPlus/Page101/E2/32.jpg", input: 6, isCorrect: true },
        { url: "img/FriendsPlus/Page101/E2/33.jpg" },
        {
          url: "img/FriendsPlus/Page101/E2/34.jpg",
          input: 6,
          isCorrect: false,
        },
        { url: "img/FriendsPlus/Page101/E2/35.jpg" },
        { url: "img/FriendsPlus/Page101/E2/36.jpg", input: 7, isCorrect: true },
        { url: "img/FriendsPlus/Page101/E2/37.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page101/E2/38.jpg" },
        {
          url: "img/FriendsPlus/Page101/E2/39.jpg",
          input: 7,
          isCorrect: false,
        },
        { url: "img/FriendsPlus/Page101/E2/40.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page101/E2/41.jpg" }],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 8",
    id: "SB6-U8-P101-E3",
    audio: "",
    video: "",
    component: D1,
    fontSize: 24,
    exerciseKey: "img/FriendsPlus/Page101/E3/Key/answerKey.png",
    titleImage: "",
    titleQuestion: [
      {
        num: "3",
        title:
          "The first conditional sentence is a kind of complex sentence. A complex sentence has ONE independent clause and at least one dependent clause.",
        color: "#19c2d9",
      },
    ],
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page101/E3/1.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page101/E3/2.jpg" },
        { url: "img/FriendsPlus/Page101/E3/3.jpg", input: true, answer: "I.C" },
        { url: "img/FriendsPlus/Page101/E3/4.jpg" },
        { url: "img/FriendsPlus/Page101/E3/5.jpg", input: true, answer: "D.C" },
        { url: "img/FriendsPlus/Page101/E3/6.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page101/E3/7.jpg" },
        { url: "img/FriendsPlus/Page101/E3/8.jpg", input: true, answer: "D.C" },
        { url: "img/FriendsPlus/Page101/E3/9.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page101/E3/10.jpg" },
        {
          url: "img/FriendsPlus/Page101/E3/11.jpg",
          input: true,
          answer: "I.C",
        },
        { url: "img/FriendsPlus/Page101/E3/12.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page101/E3/13.jpg" },
        {
          url: "img/FriendsPlus/Page101/E3/14.jpg",
          input: true,
          answer: "I.C",
        },
        { url: "img/FriendsPlus/Page101/E3/15.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page101/E3/16.jpg" },
        {
          url: "img/FriendsPlus/Page101/E3/17.jpg",
          input: true,
          answer: "D.C",
        },
        { url: "img/FriendsPlus/Page101/E3/18.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 8",
    id: "SB6-U8-P101-E4",
    audio: "",
    video: "",
    component: T2,
    titleImage: "img/FriendsPlus/Page101/E4/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: "longAnwser",
      },
    ],
  },
};
export default json;
