
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import DS2 from '../../components/ExcerciseTypes/Design/Select'
import DD2 from '../../components/ExcerciseTypes/DragAndDrop/DD2';
import url from '../../configs/url';

const json = {
  1: { // Exercise num
    unit: 'Unit 1',
    id: 'SB6-U1-P14-E1',
    audio: 'img/FriendsPlus/Page14/Audio/Friends Plus for Vietnam G6 SB Track 1.12.mp3',
    video: '',
    component: DS2,
    fontSize: 19,
    exerciseKey: 'img/FriendsPlus/Page14/E1/Key/answerKey.png',
    titleImage: '',
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page14/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page14/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page14/E1/3.jpg', input: true, answer: "library" },
        { url: 'img/FriendsPlus/Page14/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page14/E1/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page14/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page14/E1/7.jpg', input: true, answer: "Park" },
        { url: 'img/FriendsPlus/Page14/E1/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page14/E1/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page14/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page14/E1/11.jpg', input: true, answer: "hotel" },
        { url: 'img/FriendsPlus/Page14/E1/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page14/E1/13.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page14/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page14/E1/15.jpg', input: true, answer: "monument" },
        { url: 'img/FriendsPlus/Page14/E1/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page14/E1/17.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page14/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page14/E1/19.jpg', input: true, answer: "square" },
        { url: 'img/FriendsPlus/Page14/E1/20.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page14/E1/21.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page14/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page14/E1/23.jpg', input: true, answer: "shop" },
        { url: 'img/FriendsPlus/Page14/E1/24.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page14/E1/25.jpg' },
      ],
      [
        // Column14
        { url: 'img/FriendsPlus/Page14/E1/26.jpg' },
        { url: 'img/FriendsPlus/Page14/E1/27.jpg', input: true, answer: "bus station" },
        { url: 'img/FriendsPlus/Page14/E1/28.jpg' },
      ],
      [
        // Column15
        { url: 'img/FriendsPlus/Page14/E1/29.jpg' },
      ],
      [
        // Column16
        { url: 'img/FriendsPlus/Page14/E1/30.jpg' },
        { url: 'img/FriendsPlus/Page14/E1/31.jpg', input: true, answer: "market" },
        { url: 'img/FriendsPlus/Page14/E1/32.jpg' },
      ],
      [
        // Column17
        { url: 'img/FriendsPlus/Page14/E1/33.jpg' },
      ],
      [
        // Column18
        { url: 'img/FriendsPlus/Page14/E1/34.jpg' },
        { url: 'img/FriendsPlus/Page14/E1/35.jpg', input: true, answer: "cinema" },
        { url: 'img/FriendsPlus/Page14/E1/36.jpg' },
      ],
      [
        // Column19
        { url: 'img/FriendsPlus/Page14/E1/37.jpg' },
      ],
      [
        // Column20
        { url: 'img/FriendsPlus/Page14/E1/38.jpg' },
        { url: 'img/FriendsPlus/Page14/E1/39.jpg', input: true, answer: "restaurant" },
        { url: 'img/FriendsPlus/Page14/E1/40.jpg' },
      ],
      [
        // Column21
        { url: 'img/FriendsPlus/Page14/E1/41.jpg' },
      ],
      [
        // Column22
        { url: 'img/FriendsPlus/Page14/E1/42.jpg' },
        { url: 'img/FriendsPlus/Page14/E1/43.jpg', input: true, answer: "station" },
        { url: 'img/FriendsPlus/Page14/E1/44.jpg' },
      ],
      [
        // Column23
        { url: 'img/FriendsPlus/Page14/E1/45.jpg' },
      ],
      [
        // Column24
        { url: 'img/FriendsPlus/Page14/E1/46.jpg' },
        { url: 'img/FriendsPlus/Page14/E1/47.jpg', input: true, answer: "theatre" },
        { url: 'img/FriendsPlus/Page14/E1/48.jpg' },
      ],
      [
        // Column25
        { url: 'img/FriendsPlus/Page14/E1/49.jpg' },
      ],
      [
        // Column26
        { url: 'img/FriendsPlus/Page14/E1/50.jpg' },
        { url: 'img/FriendsPlus/Page14/E1/51.jpg', input: true, answer: "bridge" },
        { url: 'img/FriendsPlus/Page14/E1/52.jpg' },
      ],
      [
        // Column27
        { url: 'img/FriendsPlus/Page14/E1/53.jpg' },
      ],
      [
        // Column28
        { url: 'img/FriendsPlus/Page14/E1/54.jpg' },
        { url: 'img/FriendsPlus/Page14/E1/55.jpg', input: true, answer: "office building" },
        { url: 'img/FriendsPlus/Page14/E1/56.jpg' },
      ],
      [
        // Column29
        { url: 'img/FriendsPlus/Page14/E1/57.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  // 1: { // Exercise num
  //   unit: 'Unit 1',
  //   id: 'SB6-U1-P14-E1',
  //   audio: 'img/FriendsPlus/Page14/E1/Audio/19- Unit 1 - Track 02.mp3',
  //   video: '',
  //   component: DS2,
  //   exerciseKey: 'img/FriendsPlus/Page14/E1/Key/answerKey.png',
  //   titleImage: '',
  //   questionImage: [
  //     [
  //       { url: 'img/FriendsPlus/Page14/E1/1.jpg' },
  //     ],
  //     [
  //       // { url: 'img/FriendsPlus/Page14/E1/2.jpg' },
  //       { url: 'img/FriendsPlus/Page14/E1/2_01.jpg' },
  //       { url: 'img/FriendsPlus/Page14/E1/2_02.jpg', videoUrl: 'Videos/grade-6_english-plus-1_unit-1_lesson-1.mp4' },
  //       { url: 'img/FriendsPlus/Page14/E1/2_03.jpg' },
  //       { url: 'img/FriendsPlus/Page14/E1/3.jpg', input: true, answer: "library" },
  //       { url: 'img/FriendsPlus/Page14/E1/4.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page14/E1/5.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page14/E1/6.jpg' },
  //       { url: 'img/FriendsPlus/Page14/E1/7.jpg', input: true, answer: "park" },
  //       { url: 'img/FriendsPlus/Page14/E1/8.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page14/E1/9.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page14/E1/10.jpg' },
  //       { url: 'img/FriendsPlus/Page14/E1/11.jpg', input: true, answer: "hotel" },
  //       { url: 'img/FriendsPlus/Page14/E1/12.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page14/E1/13.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page14/E1/14.jpg' },
  //       { url: 'img/FriendsPlus/Page14/E1/15.jpg', input: true, answer: "monument" },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page14/E1/16.jpg' },
  //     ],
  //     [

  //       { url: 'img/FriendsPlus/Page14/E1/17.jpg' },
  //       { url: 'img/FriendsPlus/Page14/E1/18.jpg', input: true, answer: "square" },
  //     ],
  //     [

  //       { url: 'img/FriendsPlus/Page14/E1/19.jpg', },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page14/E1/20.jpg' },
  //       { url: 'img/FriendsPlus/Page14/E1/21.jpg', input: true, answer: "shop" },
  //       { url: 'img/FriendsPlus/Page14/E1/22.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page14/E1/23.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page14/E1/24.jpg' },
  //       { url: 'img/FriendsPlus/Page14/E1/25.jpg', input: true, answer: "bus station" },
  //       { url: 'img/FriendsPlus/Page14/E1/26.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page14/E1/27.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page14/E1/28.jpg' },
  //       { url: 'img/FriendsPlus/Page14/E1/29.jpg', input: true, answer: "market" },
  //       { url: 'img/FriendsPlus/Page14/E1/30.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page14/E1/31.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page14/E1/32.jpg' },
  //       { url: 'img/FriendsPlus/Page14/E1/33.jpg', input: true, answer: "cinema" },
  //       { url: 'img/FriendsPlus/Page14/E1/34.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page14/E1/35.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page14/E1/36.jpg' },
  //       { url: 'img/FriendsPlus/Page14/E1/37.jpg', input: true, answer: "restaurant" },
  //       { url: 'img/FriendsPlus/Page14/E1/38.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page14/E1/39.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page14/E1/40.jpg' },
  //       { url: 'img/FriendsPlus/Page14/E1/41.jpg', input: true, answer: "station" },
  //       { url: 'img/FriendsPlus/Page14/E1/42.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page14/E1/43.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page14/E1/44.jpg' },
  //       { url: 'img/FriendsPlus/Page14/E1/45.jpg', input: true, answer: "theatre" },
  //       { url: 'img/FriendsPlus/Page14/E1/46.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page14/E1/47.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page14/E1/48.jpg' },
  //       { url: 'img/FriendsPlus/Page14/E1/49.jpg', input: true, answer: "bridge" },
  //       { url: 'img/FriendsPlus/Page14/E1/50.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page14/E1/51.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page14/E1/52.jpg' },
  //       { url: 'img/FriendsPlus/Page14/E1/53.jpg', input: true, answer: "office building" },
  //       { url: 'img/FriendsPlus/Page14/E1/54.jpg' },
  //     ],
  //     [
  //       { url: 'img/FriendsPlus/Page14/E1/55.jpg' },
  //     ],
  //   ],
  //   questions: [
  //     {
  //       title: 'The dictionaries are # the shelf',
  //       answer: ['table']
  //     },
  //   ]
  // },
  2: { // Exercise num
    unit: 'Unit 1',
    id: 'SB6-U1-P14-E2',
    audio: 'img/FriendsPlus/Page14/Audio/Friends Plus for Vietnam G6 SB Track 1.13.mp3',
    video: 'https://cloud.softech.vn/storage/SachSo/Videos/P14E2_3.mp4',
    videoSub: url.cdnUrl + '/P14E2_3.vtt',
    exerciseKey: 'img/FriendsPlus/Page14/E2/Key/answerKey.png',
    component: DD2,
    titleImage: '',
    titleQuestion: [{ num: '2', title: 'Watch or listen. Which places from exercise 1 do the people mention?' }],
    questionImage: [],
    questions: [
      {
        text: "square",
      },
      {
        text: "restaurant",
        correctIndex: 2,
      },
      {
        text: "school",
      },
      {
        text: "hotel",
      },
      {
        text: "office building",
      },
      {
        text: "train station",
      },
      {
        text: "bridge",
        correctIndex: 6,
      },
      {
        text: "café",
        correctIndex: 0,
      },
      {
        text: "theatre",
      },
      {
        text: "sports centre",
        correctIndex: 3,
      },
      {
        text: "library",
        correctIndex: 5,
      },
      {
        text: "bus station",
        correctIndex: 8,
      },
      {
        text: "cinema",
        correctIndex: 1,
      },
      {
        text: "shop",
        correctIndex: 9,
      },
      {
        text: "park",
        correctIndex: 4,
      },
      {
        text: "shopping centre",
      },
      {
        text: "market",
      },
      {
        text: "monument",
        correctIndex: 7,
      },
      {
        text: "hotel",
      },
      {
        text: "flat",
      },
      {
        text: "street",
      },

    ]
  },


  // 2: { // Exercise num
  unit: 'Unit 1',
  //   audio: 'img/FriendsPlus/Page14/E2/Audio/21- Unit 1 - Track 04.mp3',
  //   video: url.cdnUrl + '/P14E2.mp4',
  //   component: T6,
  //   exerciseKey: 'img/FriendsPlus/Page14/E2/Key/answerKey.png',
  //   titleImage: 'img/FriendsPlus/Page14/E2/title.jpg',
  //   // titleQuestion: [{ num: '3', title: 'Watch or listen again and complete the sentences.' }],
  //   questionImage: [],
  //   questions: [
  //     {
  //       title:
  //       "</br>"+ 
  //       "#&emsp; # #&emsp; # #&emsp; # #&emsp; # #&emsp; # ",
  //       answer: [
  //         "café/cinema/restaurant/sports/centre/park/library/bridge/monument/bus station/shop"+
  //         "café/cinema/restaurant/sports/centre/park/library/bridge/monument/bus station/shop"+
  //         "café/cinema/restaurant/sports/centre/park/library/bridge/monument/bus station/shop"+
  //         "café/cinema/restaurant/sports/centre/park/library/bridge/monument/bus station/shop"+
  //         "café/cinema/restaurant/sports/centre/park/library/bridge/monument/bus station/shop"+
  //         "café/cinema/restaurant/sports/centre/park/library/bridge/monument/bus station/shop"+
  //         "café/cinema/restaurant/sports/centre/park/library/bridge/monument/bus station/shop"+
  //         "café/cinema/restaurant/sports/centre/park/library/bridge/monument/bus station/shop"+
  //         "café/cinema/restaurant/sports/centre/park/library/bridge/monument/bus station/shop"+
  //         "café/cinema/restaurant/sports/centre/park/library/bridge/monument/bus station/shop",
  //       ],
  //       type: 'longAnwser'
  //     },
  //   ]
  // },


  3: { // Exercise num
    unit: 'Unit 1',
    id: 'SB6-U1-P14-E3',
    audio: 'img/FriendsPlus/Page14/Audio/Friends Plus for Vietnam G6 SB Track 1.13.mp3',
    video: 'https://cloud.softech.vn/storage/SachSo/Videos/P14E2_3.mp4',
    videoSub: url.cdnUrl + '/P14E2_3.vtt',
    component: T6,
    exerciseWidth: '50%',
    inputSize: 150,
    exerciseKey: 'img/FriendsPlus/Page14/E3/Key/answerKey.png',
    titleImage: '',
    titleQuestion: [{ num: '3', title: 'Watch or listen again and complete the sentences.' }],
    questionImage: [],
    questions: [
      {
        title: `<div style="margin-left:35px"><p><b>1.</b>There isn’t <span style="color: #23BDF2">a</span> # here.</p><p><b>2.</b> There are <span style="color: #23BDF2">some</span> # in the city, too.</p><p><b>3.</b> There’s <span style="color: #23BDF2">an</span> old # .</p><p><b>4.</b> There's <span style="color: #23BDF2">a</span> # .</p><p><b>5.</b> There aren’t <span style="color: #23BDF2">any</span> good # here.</p>`,
        answer: [
          'theatre', 'parks', 'bridge', 'café', 'shops'
        ],
        type: 'longAnwser'
      },
    ]
  },

}

export default json;