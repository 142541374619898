import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  8: {
    // Exercise num
    unit: "Progress Review 3",
    id: "SB6-PV3-P84-E8",
    audio: "",
    video: "",
    component: D1,
    fontSize: 28,
    exerciseKey: "img/FriendsPlus/Page84/E8/Key/answerKey.png",
    titleImage: "",
    titleQuestion: [
      { num: "8", title: "Complete the words.", color: "#553f9f" },
    ],
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page84/E8/1.jpg" },
        {
          url: "img/FriendsPlus/Page84/E8/2.jpg",
          input: true,
          answer: "ockey",
        },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E8/3.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E8/4.jpg" },
        {
          url: "img/FriendsPlus/Page84/E8/5.jpg",
          input: true,
          answer: "thletics",
        },
        { url: "img/FriendsPlus/Page84/E8/6.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E8/7.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E8/8.jpg" },
        { url: "img/FriendsPlus/Page84/E8/9.jpg", input: true, answer: "eam" },
        { url: "img/FriendsPlus/Page84/E8/10.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E8/11.jpg" },
        {
          url: "img/FriendsPlus/Page84/E8/12.jpg",
          input: true,
          answer: "lympic",
        },
        { url: "img/FriendsPlus/Page84/E8/13.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E8/14.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E8/15.jpg" },
        {
          url: "img/FriendsPlus/Page84/E8/16.jpg",
          input: true,
          answer: "ycling",
        },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E8/17.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E8/18.jpg" },
        {
          url: "img/FriendsPlus/Page84/E8/19.jpg",
          input: true,
          answer: "hampionship",
        },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E8/21.jpg" },
        {
          url: "img/FriendsPlus/Page84/E8/22.jpg",
          input: true,
          answer: "kiing",
        },
        { url: "img/FriendsPlus/Page84/E8/23.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E8/24.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E8/25.jpg" },
        {
          url: "img/FriendsPlus/Page84/E8/26.jpg",
          input: true,
          answer: "ootball",
        },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E8/27.jpg" },
      ],
      [
        // Column
        // { url: 'img/FriendsPlus/Page84/E8/28.jpg' },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },

  9: {
    // Exercise num
    unit: "Progress Review 3",
    id: "SB6-PV3-P84-E8",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page84/E9/Key/KeyE9.png",
    component: D1,
    questionImage: [
      [
        //1
        { url: "img/FriendsPlus/Page84/E9/1.jpg" },
      ],

      [
        //2
        { url: "img/FriendsPlus/Page84/E9/2.jpg" },
        {
          url: "img/FriendsPlus/Page84/E9/3.jpg",
          input: true,
          answer: "medallist",
        },
      ],
      [
        //3
        { url: "img/FriendsPlus/Page84/E9/4.jpg" },
      ],

      [
        //4
        { url: "img/FriendsPlus/Page84/E9/5.jpg" },
        {
          url: "img/FriendsPlus/Page84/E9/6.jpg",
          input: true,
          answer: "winner",
        },
        { url: "img/FriendsPlus/Page84/E9/7.jpg" },
      ],

      [
        //5

        { url: "img/FriendsPlus/Page84/E9/8.jpg" },
      ],

      [
        { url: "img/FriendsPlus/Page84/E9/9.jpg" },
        {
          url: "img/FriendsPlus/Page84/E9/10.jpg",
          input: true,
          answer: "spectator",
        },
        { url: "img/FriendsPlus/Page84/E9/11.jpg" },
      ],

      [
        //7

        { url: "img/FriendsPlus/Page84/E9/12.jpg" },
      ],

      [
        //8
        { url: "img/FriendsPlus/Page84/E9/13.jpg" },
        {
          url: "img/FriendsPlus/Page84/E9/14.jpg",
          input: true,
          answer: "competitor",
        },
        { url: "img/FriendsPlus/Page84/E9/15.jpg" },
      ],

      [
        //9
        { url: "img/FriendsPlus/Page84/E9/16.jpg" },
      ],

      [
        { url: "img/FriendsPlus/Page84/E9/17.jpg" },
        {
          url: "img/FriendsPlus/Page84/E9/18.jpg",
          input: true,
          answer: "swimmer",
        },
        { url: "img/FriendsPlus/Page84/E9/19.jpg" },
      ],

      [
        //11

        { url: "img/FriendsPlus/Page84/E9/20.jpg" },
      ],
    ],
  },

  10: {
    // Exercise num
    unit: "Progress Review 3",
    id: "SB6-PV3-P84-E10",
    audio: "",
    video: "",
    fontSize: 24,
    component: D1,
    exerciseKey: "img/FriendsPlus/Page84/E10/Key/1.png",
    titleImage: "",
    titleQuestion: [
      {
        num: "10",
        title: "Complete the sentences with the word.",
        color: "#553f9f",
      },
    ],
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page84/E10/1.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E10/2.jpg" },
        {
          url: "img/FriendsPlus/Page84/E10/3.jpg",
          input: true,
          answer: "There weren't",
        },
        { url: "img/FriendsPlus/Page84/E10/4.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E10/5.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E10/6.jpg" },
        {
          url: "img/FriendsPlus/Page84/E10/7.jpg",
          input: true,
          answer: "weren't",
        },
        { url: "img/FriendsPlus/Page84/E10/8.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E10/9.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E10/10.jpg" },
        {
          url: "img/FriendsPlus/Page84/E10/11.jpg",
          input: true,
          answer: "was",
        },
        { url: "img/FriendsPlus/Page84/E10/12.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E10/13.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E10/14.jpg" },
        {
          url: "img/FriendsPlus/Page84/E10/15.jpg",
          input: true,
          answer: "There were",
        },
        { url: "img/FriendsPlus/Page84/E10/16.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E10/17.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E10/18.jpg" },
        {
          url: "img/FriendsPlus/Page84/E10/19.jpg",
          input: true,
          answer: "There was",
        },
        { url: "img/FriendsPlus/Page84/E10/20.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E10/21.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E10/22.jpg" },
        {
          url: "img/FriendsPlus/Page84/E10/23.jpg",
          input: true,
          answer: "were",
        },
        { url: "img/FriendsPlus/Page84/E10/24.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E10/25.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E10/26.jpg" },
        {
          url: "img/FriendsPlus/Page84/E10/27.jpg",
          input: true,
          answer: "There wasn't",
        },
        { url: "img/FriendsPlus/Page84/E10/28.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E10/29.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E10/30.jpg" },
        {
          url: "img/FriendsPlus/Page84/E10/31.jpg",
          input: true,
          answer: "wasn't",
        },
        { url: "img/FriendsPlus/Page84/E10/32.jpg" },
      ],
      [
        // Column
        // { url: 'img/FriendsPlus/Page84/E10/33.jpg' },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  11: {
    // Exercise num
    unit: "Progress Review 3",
    id: "SB6-PV3-P84-E11",
    audio: "img/FriendsPlus/Page84/E11/Audio/G6 SB Track 2.22.WAV",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page84/E11/Key/answerKey.png",
    titleImage: "",
    //titleQuestion: [{ num: '11', title: 'Complete the words.', color: '#553f9f' }],
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page84/E11/1.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E11/2.jpg" },
        {
          url: "img/FriendsPlus/Page84/E11/3.jpg",
          input: true,
          answer: "False",
        },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E11/4.jpg" },
        {
          url: "img/FriendsPlus/Page84/E11/5.jpg",
          input: true,
          answer: "False",
        },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E11/6.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E11/7.jpg" },
        {
          url: "img/FriendsPlus/Page84/E11/8.jpg",
          input: true,
          answer: "True",
        },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E11/9.jpg" },
        {
          url: "img/FriendsPlus/Page84/E11/10.jpg",
          input: true,
          answer: "True",
        },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E11/11.jpg" },
        {
          url: "img/FriendsPlus/Page84/E11/12.jpg",
          input: true,
          answer: "False",
        },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page84/E11/13.jpg" },
        {
          url: "img/FriendsPlus/Page84/E11/14.jpg",
          input: true,
          answer: "True",
        },
      ],
      [
        // Column
        // { url: 'img/FriendsPlus/Page84/E11/15.jpg' },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
};
export default json;
