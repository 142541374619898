import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
// import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import UI from '../../components/ExcerciseTypes/UseIt';

const json = {
  1: { // Exercise num
    unit: 'Unit 1',
    id: 'SB6-U1-P16-E1',
    audio: '',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page16/E1/Key/answerKey.png',
    titleImage: 'img/FriendsPlus/Page16/E1/title.jpg',
    titleQuestion: [{ num: '1', title: 'Look at the photos. What do you think are the answers to questions 1–3? Read and check.', color: '#DA2C45' }],
    questionImage: [],
    questions: [
      {
        title: "<p><span style='font-weight:600'>1</span> Is there a school and a library on the ship? # </p>" +
          "<p><span style='font-weight:600'>2</span> Are there any swimming pools? # </p>" +
          "<p><span style='font-weight:600'>3</span> How many cafés and restaurants are there on the ship? # </p>",
        answer: [
          "There isn't a school, but there's a library/There isn't a school, but there's a library.",
          "Yes. There are five swimming pools/Yes. There are five swimming pools.",
          "There are twenty cafés and restaurants/There are twenty cafés and restaurants."
        ],
        type: 'longAnwser'
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 1',
    id: 'SB6-U1-P16-E2',
    audio: 'img/FriendsPlus/Page16/E2/Audio/Friends Plus for Vietnam G6 SB Track 1.14.mp3',
    video: '',
    component: T6,
    imgSize: '100%',
    inputSize: 500,
    exerciseKey: 'img/FriendsPlus/Page16/E2/Key/Key.png',
    titleImage: 'img/FriendsPlus/Page16/E2/title.jpg',
    // titleQuestion: [{ num: '2', title: 'Read the skills strategy. Then read and listen to the text and answer the questions.', color: '#DA2C45' }],
    questionImage: [],
    questions: [
      {
        title: "<div style='margin-left:35px'><p><span style='font-weight:600'>1.</span> What’s the name of the ship? # </p>" +
          "<p><span style='font-weight:600'>2.</span> How many people work on the ship? #. </p>" +
          "<p><span style='font-weight:600'>3.</span> How many chefs are there on the ship? #. </p>" +
          "<p><span style='font-weight:600'>4.</span> Is it the only ship with a park? #. </p>" +
          "<p><span style='font-weight:600'>5.</span> What’s in the sports area? #. </p></div>",
        answer: [
          'Oasis of the Seas',
          'More than 2,000 people work on the ship',
          'There are 250 chefs',
          'Yes',
          "There's a climbing wall in the sports area",
        ],
        type: 'longAnwser'
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 1',
    id: 'SB6-U1-P16-E3',
    audio: '',
    video: '',
    component: UI,
    imgSize: '50%',
    titleImage: '',
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page16/E3/1.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 1',
    id: 'SB6-U1-P16-E4',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page16/E4/5.png",
    titleQuestion: '',
    questionImage: [],
    questions: [
      {
        title:
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}

export default json;