
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {

  13: { // Exercise num
    unit : 'Progress Review 2',
    id : 'SB6-PV2-P61-E13',
    audio: '',
    video: '',
    component: T1,
    // titleImage: "img/FriendsPlus/Page59/E5/1.png",
    exerciseKey: 'img/FriendsPlus/Page61/E13/Key/1.png',
    imgSize: '70%',
    titleQuestion: [{ color: '#3F3478', num: '13', title: "Order the words to make questions." }],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='font-family:Rubik-Medium'><p><span style='font-weight:600'>1.</span> students / those / having / are / break / a / ? #</p>" +
          "<p><span style='font-weight:600'>2.</span> words / are / we / new / these / learning / ? #</p>" +
          "<p><span style='font-weight:600'>3.</span> making / Eva / some / is / notes / ? #</p>" +
          "<p><span style='font-weight:600'>4.</span> you / running / why / school / to / are / ? #</p>" +
          "<p><span style='font-weight:600'>5.</span> are / animals / where / going / those / ? #</p>" +
          "<p><span style='font-weight:600'>6.</span> what / Robert / doing / now / is / ? #</p>",
        answer: [
          "Are those students having a break?",
          "Are we learning these new words?",
          "Is Eva making some notes?",
          "Why are you running to school?",
          "Where are those animals going?",
          "What is Robert doing now?",
        ],
        type: 'longAnwser'
      },
    ]
  },
  14: { // Exercise num
    unit : 'Progress Review 2',
    id : 'SB6-PV2-P61-E14',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page61/E14/Key/answerKey.png',
    titleImage: '',
    titleQuestion: [{ num: '14', title: 'Complete the sentences using the present continuous or present simple form of the verbs.', color: '#5C4BA5' }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page61/E14/1.jpg'},
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page61/E14/2.jpg'},
        { url: 'img/FriendsPlus/Page61/E14/3.jpg', input: true, answer: 'swims'},
        { url: 'img/FriendsPlus/Page61/E14/4.jpg'},
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page61/E14/5.jpg'},
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page61/E14/6.jpg'},
        { url: 'img/FriendsPlus/Page61/E14/7.jpg', input: true, answer: "'re revising"},
        { url: 'img/FriendsPlus/Page61/E14/8.jpg'},
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page61/E14/9.jpg'},
        { url: 'img/FriendsPlus/Page61/E14/10.jpg', input: true, answer: 'Do'},
        { url: 'img/FriendsPlus/Page61/E14/11.jpg'},
        { url: 'img/FriendsPlus/Page61/E14/12.jpg', input: true, answer: 'like'},
        { url: 'img/FriendsPlus/Page61/E14/13.jpg'},
      ],
      [
        // Column14
        { url: 'img/FriendsPlus/Page61/E14/14.jpg'},
      ],
      [
        // Column15
        { url: 'img/FriendsPlus/Page61/E14/15.jpg'},
        { url: 'img/FriendsPlus/Page61/E14/16.jpg', input: true, answer: "don't live"},
        { url: 'img/FriendsPlus/Page61/E14/17.jpg'},
      ],
      [
        // Column18
        { url: 'img/FriendsPlus/Page61/E14/18.jpg'},
      ],
      [
        // Column19
        { url: 'img/FriendsPlus/Page61/E14/19.jpg'},
        { url: 'img/FriendsPlus/Page61/E14/20.jpg', input: true, answer: 'Is'},
        { url: 'img/FriendsPlus/Page61/E14/21.jpg'},
      ],
      [
        // Column22
        { url: 'img/FriendsPlus/Page61/E14/22.jpg'},
        { url: 'img/FriendsPlus/Page61/E14/23.jpg', input: true, answer: 'doing'},
        { url: 'img/FriendsPlus/Page61/E14/24.jpg'},
      ],
      [
        // Column25
        { url: 'img/FriendsPlus/Page61/E14/25.jpg'},
        { url: 'img/FriendsPlus/Page61/E14/26.jpg', input: true, answer: 'goes'},
        { url: 'img/FriendsPlus/Page61/E14/27.jpg'},
      ],
      [
        // Column28
        { url: 'img/FriendsPlus/Page61/E14/28.jpg'},
      ],
      [
        // Column29
        { url: 'img/FriendsPlus/Page61/E14/29.jpg'},
        { url: 'img/FriendsPlus/Page61/E14/30.jpg', input: true, answer: "isn't listening"},
        { url: 'img/FriendsPlus/Page61/E14/31.jpg'},
      ],
      [
        // Column32
        { url: 'img/FriendsPlus/Page61/E14/32.jpg'},
      ],
      
      
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  15: { // Exercise num
    unit : 'Progress Review 2',
    id : 'SB6-PV2-P61-E15',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page61/E15/Key/1.png',
    // titleImage: "img/FriendsPlus/Page50/E15/1.png",
    // titleQuestion: [{ num: '1', title: 'Complete the examples from the text on page 50. Then choose the correct words in rules 1–3.' }],
    questionImage: [ // Row

      [
        // Column
        { url: 'img/FriendsPlus/Page61/E15/1.jpg' },
      ],

      [
        // Column
        { url: 'img/FriendsPlus/Page61/E15/2.jpg' },
        { url: 'img/FriendsPlus/Page61/E15/3.jpg', input: true, answer: 'B' },
        { url: 'img/FriendsPlus/Page61/E15/4.jpg' },
      ],

      [
        // Column
        { url: 'img/FriendsPlus/Page61/E15/5.jpg' },
      ],

      [
        // Column
        { url: 'img/FriendsPlus/Page61/E15/6.jpg' },
        { url: 'img/FriendsPlus/Page61/E15/7.jpg', input: true, answer: 'C' },
        { url: 'img/FriendsPlus/Page61/E15/8.jpg' },
      ],

      [
        // Column
        { url: 'img/FriendsPlus/Page61/E15/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page61/E15/10.jpg' },
        { url: 'img/FriendsPlus/Page61/E15/11.jpg', input: true, answer: 'D' },
        { url: 'img/FriendsPlus/Page61/E15/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page61/E15/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page61/E15/14.jpg' },
        { url: 'img/FriendsPlus/Page61/E15/15.jpg', input: true, answer: 'A' },
        { url: 'img/FriendsPlus/Page61/E15/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page61/E15/17.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page61/E15/19.jpg' },
      ],

    ],

    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  16: { // Exercise num
    unit : 'Progress Review 2',
    id : 'SB6-PV2-P61-E16',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page61/E16/Key/1.png',
    // titleImage: "img/FriendsPlus/Page50/E16/1.png",
    // titleQuestion: [{ num: '1', title: 'Complete the examples from the text on page 50. Then choose the correct words in rules 1–3.' }],
    questionImage: [ // Row

      [
        // Column
        { url: 'img/FriendsPlus/Page61/E16/1.jpg' },
      ],

      [
        // Column
        { url: 'img/FriendsPlus/Page61/E16/2.jpg' },
        { url: 'img/FriendsPlus/Page61/E16/3.jpg', input: true, answer: 'happy' },
        { url: 'img/FriendsPlus/Page61/E16/4.jpg' },
      ],

      [
        // Column
        { url: 'img/FriendsPlus/Page61/E16/5.jpg' },
      ],

      [
        // Column
        { url: 'img/FriendsPlus/Page61/E16/6.jpg' },
        { url: 'img/FriendsPlus/Page61/E16/7.jpg', input: true, answer: 'big' },
        { url: 'img/FriendsPlus/Page61/E16/8.jpg' },
      ],

      [
        // Column
        { url: 'img/FriendsPlus/Page61/E16/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page61/E16/10.jpg' },
        { url: 'img/FriendsPlus/Page61/E16/11.jpg', input: true, answer: 'so' },
        { url: 'img/FriendsPlus/Page61/E16/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page61/E16/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page61/E16/14.jpg' },
        { url: 'img/FriendsPlus/Page61/E16/15.jpg', input: true, answer: 'finish' },
        { url: 'img/FriendsPlus/Page61/E16/16.jpg' },
        { url: 'img/FriendsPlus/Page61/E16/17.jpg', input: true, answer: 'break' },
      ],

      [
        // Column
        { url: 'img/FriendsPlus/Page61/E16/18.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page61/E16/19.jpg' },
        { url: 'img/FriendsPlus/Page61/E16/20.jpg', input: true, answer: 'compulsory' },
        { url: 'img/FriendsPlus/Page61/E16/21.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page61/E16/22.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page61/E16/23.jpg' },
        { url: 'img/FriendsPlus/Page61/E16/24.jpg', input: true, answer: 'optional' },
        { url: 'img/FriendsPlus/Page61/E16/25.jpg' },


      ],
      [
        { url: 'img/FriendsPlus/Page61/E16/26.jpg' },
      ],
      // [
      //   { url: 'img/FriendsPlus/Page61/E16/27.jpg' },
      // ]

    ],

    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  }



}
export default json