import U1 from "../../components/ExcerciseTypes/UnderLine/U1";
import UI from "../../components/ExcerciseTypes/UseIt";
import MC6 from "../../components/ExcerciseTypes/MultipleChoice/MC6";

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "SB6-U2-P26-E1",
    audio: "",
    video: "",
    component: MC6,
    // url: 'img/FriendsPlus/Page26/E1/Key/2.jpg',
    exerciseKey: "img/FriendsPlus/Page26/E1/Key/answerKey.png",
    // titleQuestion: [{ num: '1', title: 'Look at the photo. Choose and write a title a–c for the text. Then read the text and check your idea.', color: '#C02A32' }],
    titleImage: "img/FriendsPlus/Page26/E1/Key/3.jpg",
    imgSize: "90%",
    questionImage: [
      // Row
      [
        // Column
        { url: "http://" },
        { url: "http://", input: true },
        { url: "http://" },
      ],
    ],
    questions: [
      {
        title: "",
        answers: [
          {
            image: "",
            isCorrect: false,
            right: "",
            text: "Big family, big problems",
          },
          {
            image: "",
            isCorrect: false,
            right: "",
            text: "An easy life for the Radfords",
          },
          { image: "", isCorrect: true, right: "", text: "Happy house" },
        ],
        no: 1,
        question: "",
        type: "",
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 2",
    id: "SB6-U2-P26-E2",
    audio: "img/FriendsPlus/Page26/E2/Audio/G6 SB Track 1.22.mp3",
    video: "",
    component: U1,
    titleImage: "",
    exerciseKey: "img/FriendsPlus/Page26/E2/Key/Key.png",
    titleQuestion: [
      {
        num: "2",
        title:
          "Read the skills strategy. Then read and listen to the text and choose the correct words.",
        color: "#C02A32",
      },
    ],
    questionImage: [],
    questions: [
      {
        title:
          '<img src="img/FriendsPlus/Page26/E1/Key/2.jpg" ></img>' +
          "<p>1 Noel # /#  with his family.</p>" +
          "<p>2 The younger children  # / # their older sister.</p>" +
          "<p>3 They have dinner # / # .</p>" +
          "<p>4 The parents  # / # go to bed after the older children go to bed.</p>",
        answerString: [
          "has breakfast ",
          "doesn't have breakfast",
          "help",
          "don’t help",
          "at a restaurant",
          "at home",
          "often",
          "always",
        ],
        answer: ["doesn't have breakfast", "don’t help", "at home", "always"],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 2",
    id: "SB6-U2-P26-E3",
    audio: "",
    video: "",
    component: UI,
    exerciseKey: "",
    //titleImage: 'http://',
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page26/E3/1.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page26/E3/2.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page26/E3/3.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page26/E3/4.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page26/E3/5.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 2",
    id: "SB6-U2-P26-E4",
    audio: "",
    video: "",
    component: UI,
    exerciseKey: "",
    //titleImage: 'http://',
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page26/E4/1.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
};

export default json;
