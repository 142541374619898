
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  1: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P90-E1',
    audio: 'img/FriendsPlus/Page90/E1/Audio/G6 SB Track 2.27.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page90/E1/Key/answerKey.png',
    // inputSize: 500,
    // titleImage: "img/FriendsPlus/Page89/E2/1.png",
    // imgSize: '20%',
    // inputSize: 100,
    // textCenter: true,
    titleQuestion: [{ color: '#E26D28', num: '1', title: 'Read the text about Robert Wadlow and check the meanings of the phrases in <span style="color:#33B1D6;">blue</span>. Write the past simple form of the verbs. Listen and check.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='font-family:Rubik-Medium'><p style='font-family:OneStrokeScriptStd-Bold;color:gray'>grow up – grew up</p>" +
          "<p><span style='font-weight:600'>1</span> go to school - # </p>" +
          "<p><span style='font-weight:600'>2</span> leave school - # </p>" +
          "<p><span style='font-weight:600'>3</span> go to university - # </p>" +
          "<p><span style='font-weight:600'>4</span>  get a qualification - # </p>" +
          "<p><span style='font-weight:600'>5</span> get a job - # </p>" +
          "<p><span style='font-weight:600'>6</span> get married - # </p>" +
          "<p><span style='font-weight:600'>7</span> have children - # </p>" +
          "<p><span style='font-weight:600'>8</span> travel - # </p>" +
          "<p><span style='font-weight:600'>9</span> die - # </p>",
        answer: [
          "went to school",
          'left school',
          "went to university",
          "got a qualification",
          "got a job",
          "got married",
          "had children",
          "travelled",
          "died"
        ],
        type: 'longAnwser'
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P90-E2',
    audio: 'img/FriendsPlus/Page90/E2/Audio/G6 SB Track 2.28.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page90/E2/Key/answerKey.png',
    // inputSize: 500,
    // titleImage: "img/FriendsPlus/Page90/E2/1.png",
    imgSize: '60%',
    // inputSize: 100,
    // textCenter: true,
    titleQuestion: [{ color: '#E26D28', num: '2', title: 'Do you think people in the USA liked or disliked Robert? Listen to a podcast about him and check your answer.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p style='font-family:Rubik-Medium'> Do you think people in the USA liked or disliked Robert? # </p>",
        answer: [
          "People liked him/They liked him",
        ],
        type: 'longAnwser'
      },
    ]
  },



  3: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P90-E3',
    audio: 'img/FriendsPlus/Page90/E2/Audio/G6 SB Track 2.28.mp3',
    video: '',
    component: D1,
    fontSize: 25,
    height: 40,
    exerciseKey: 'img/FriendsPlus/Page90/E3/Key/answer.png',
    // questionImage: [],
    questionImage: [
      [//1
        { url: 'img/FriendsPlus/Page90/E3/1.jpg' },
      ],

      [//2
        { url: 'img/FriendsPlus/Page90/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page90/E3/3.jpg', input: true, answer: '1918' },
        { url: 'img/FriendsPlus/Page90/E3/4.jpg' },
      ],
      [//3
        { url: 'img/FriendsPlus/Page90/E3/5.jpg' },
      ],
      [//4
        { url: 'img/FriendsPlus/Page90/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page90/E3/7.jpg', input: true, answer: '800' },
        { url: 'img/FriendsPlus/Page90/E3/8.jpg' },
      ],
      [//5
        { url: 'img/FriendsPlus/Page90/E3/9.jpg' },
        { url: 'img/FriendsPlus/Page90/E3/10.jpg', input: true, answer: 'two brothers' },
        { url: 'img/FriendsPlus/Page90/E3/11.jpg' },
      ],
      [//6
        { url: 'img/FriendsPlus/Page90/E3/12.jpg' },
        { url: 'img/FriendsPlus/Page90/E3/13.jpg', input: true, answer: 'two sisters' },
        { url: 'img/FriendsPlus/Page90/E3/14.jpg' },
      ],
      [//7
        { url: 'img/FriendsPlus/Page90/E3/15.jpg' },
        { url: 'img/FriendsPlus/Page90/E3/16.jpg', input: true, answer: 'normal' },
        { url: 'img/FriendsPlus/Page90/E3/17.jpg' },
      ],
      [//8
        { url: 'img/FriendsPlus/Page90/E3/18.jpg' },
      ],
      [//9
        { url: 'img/FriendsPlus/Page90/E3/19.jpg' },
        { url: 'img/FriendsPlus/Page90/E3/20.jpg', input: true, answer: 'twenty-two' },
        { url: 'img/FriendsPlus/Page90/E3/21.jpg' },
      ],
      [//10
        { url: 'img/FriendsPlus/Page90/E3/22.jpg' },
      ],
      [//11
        { url: 'img/FriendsPlus/Page90/E3/23.jpg' },
        { url: 'img/FriendsPlus/Page90/E3/24.jpg', input: true, answer: 'seventeen' },
        { url: 'img/FriendsPlus/Page90/E3/25.jpg' },
        { url: 'img/FriendsPlus/Page90/E3/26.jpg', input: true, answer: 'feet' },
        { url: 'img/FriendsPlus/Page90/E3/27.jpg' },
      ],
      [//12 
        { url: 'img/FriendsPlus/Page90/E3/28.jpg' },
      ],
      [//13
        { url: 'img/FriendsPlus/Page90/E3/29.jpg' },
        { url: 'img/FriendsPlus/Page90/E3/30.jpg', input: true, answer: 'spoke' },
        { url: 'img/FriendsPlus/Page90/E3/31.jpg' },
      ],
      [//14
        { url: 'img/FriendsPlus/Page90/E3/32.jpg' },
      ],



    ],

  },



  4: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P90-E4',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page90/E4/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },

  5: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P90-E5',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page90/E5/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json