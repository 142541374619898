import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
    1: { // Exercise num
        unit : 'Unit 8',
        id : 'SB6-U8-P105-E1',
        audio: '',
        video: '',
        component: T6,
        exerciseKey: 'img/FriendsPlus/Page105/E1/Key/Key.png',
        titleImage: "img/FriendsPlus/Page105/E1/1.png",
        // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
        questionImage: [],
        questions: [
            {
                title:
                    '<p><span style="font-weight:600">&emsp; 1</span>  #.  </p>' +
                    '<p><span style="font-weight:600">&emsp; 2</span>  #. </p>' +
                    '<p><span style="font-weight:600">&emsp; 3</span>  #. </p>' +
                    '<p><span style="font-weight:600">&emsp; 4</span>  #. </p>' +
                    '<p><span style="font-weight:600">&emsp; 5</span>  #. </p>' +
                    '<p><span style="font-weight:600">&emsp; 6</span>   #. </p>' +
                    '<p><span style="font-weight:600">&emsp; 7</span>   #. </p>' +
                    '<p><span style="font-weight:600">&emsp; 8</span>   #. </p>',
                answer: [
                    "bus",
                    "tram",
                    "boat",
                    "car",
                    "plane",
                    "ferry",
                    "cable car",
                    "subway train",

                ],
                type: 'longAnwser'
            },
        ]
    },
    2: { // Exercise num
        unit : 'Unit 8',
        id : 'SB6-U8-P105-E2',
        audio: '',
        video: '',
        component: D1,
        fontSize: 27,
        height : 32,
        exerciseKey: 'img/FriendsPlus/Page105/E2/Key/answerKey.png',
        titleImage: '',
        //titleQuestion: [{ num: '2', title: 'Complete the Key Phrases with words in the description.', color: '#0caa50' }],
        questionImage: [ // Row
            [
                // Column
                { url: 'img/FriendsPlus/Page105/E2/1.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page105/E2/2.jpg' },
                { url: 'img/FriendsPlus/Page105/E2/3.jpg', input: true, answer: 'cold' },
                { url: 'img/FriendsPlus/Page105/E2/4.jpg' },
                { url: 'img/FriendsPlus/Page105/E2/5.jpg', input: true, answer: 'rainy' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page105/E2/6.jpg' },
                { url: 'img/FriendsPlus/Page105/E2/7.jpg', input: true, answer: 'windy' },
                { url: 'img/FriendsPlus/Page105/E2/8.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page105/E2/9.jpg' },
                { url: 'img/FriendsPlus/Page105/E2/10.jpg', input: true, answer: 'sunny' },
                { url: 'img/FriendsPlus/Page105/E2/11.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page105/E2/12.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page105/E2/13.jpg' },
            ],
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    3: { // Exercise num
        unit : 'Unit 8',
        id : 'SB6-U8-P105-E3',
        audio: '',
        video: '',
        component: T2,
        titleImage: "img/FriendsPlus/Page105/E3/1.png",
        imgSize: '70%',
        // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
        questionImage: [],
        questions: [
            {
                title: '',
                answer: [],
                type: 'longAnwser'
            },
        ]
    },

}
export default json