import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import S7 from '../../components/ExcerciseTypes/Select/Select7';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: { // Exercise num
    unit : 'Option 2',
    id : 'SB6-OPE2-P111-E1',
    audio: '',
    video: '',
    component: S7,
    width: 270,
    exerciseKey: 'img/FriendsPlus/Page111/E1/Key/answerKey.png',
    titleImage: 'img/FriendsPlus/Page111/E1/title.jpg',
    // titleQuestion: [{ num: '1', title: 'Match the words in the box with pictures 1–16. Then listen and check. Which sports do you like?', color: '#115D98'}],
    questions: [
      {
        no: "1",
        text: "three o’clock",
        image: "img/FriendsPlus/Page111/E1/06.jpg",
      },
      {
        no: "2",
        text: "five past seven",
        image: "img/FriendsPlus/Page111/E1/08.jpg",
      },
      {
        no: "3",
        text: "quarter past nine",
        image: "img/FriendsPlus/Page111/E1/10.jpg",
      },
      {
        no: "4",
        text: "twenty-five past two",
        image: "img/FriendsPlus/Page111/E1/12.jpg",
      },
      {
        no: "5",
        text: "half past six",
        image: "img/FriendsPlus/Page111/E1/16.jpg",
      },
      {
        no: "6",
        text: "twenty to twelve",
        image: "img/FriendsPlus/Page111/E1/18.jpg",
      },
      {
        no: "7",
        text: "quarter to eleven",
        image: "img/FriendsPlus/Page111/E1/20.jpg",
      },
      {
        no: "8",
        text: "ten to two",
        image: "img/FriendsPlus/Page111/E1/22.jpg",
      },
    ]
  },

  2: { // Exercise num
     unit : 'Option 2',
    id : 'SB6-OPE2-P111-E2',
    audio: 'img/FriendsPlus/Page111/E2/Audio/Friends Plus for Vietnam G6 SB Track 3.05.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page111/E2/Key/answerKey.png',
    component: T6,
    titleImage: "img/FriendsPlus/Page111/E2/title.jpg",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>&emsp;&emsp; 1</span><span style='font-family:OneStrokeScriptStd-Bold;color:gray;font-size:30px'> &emsp;10.20 </span></p>" +
          "<p><span style='font-weight:600'>&emsp;&emsp; 2</span> # </p>" +
          "<p><span style='font-weight:600'>&emsp;&emsp; 3</span> # </p>" +
          "<p><span style='font-weight:600'>&emsp;&emsp; 4</span> # </p>" +
          "<p><span style='font-weight:600'>&emsp;&emsp; 5</span> # </p>" +
          "<p><span style='font-weight:600'>&emsp;&emsp; 6</span> # </p>",
        answer: [
          "11.55",
          "6.35",
          "11.10",
          "8.30",
          "2.45",
        ],
        type: 'longAnwser'
      },
    ]
  },

  3: { // Exercise num
     unit : 'Option 2',
    id : 'SB6-OPE2-P111-E3',
    audio: 'img/FriendsPlus/Page111/E3/Audio/Friends Plus for Vietnam G6 SB Track 3.06.mp3',
    video: '',
    component: T6,
    inputSize: 500,
    titleImage: "img/FriendsPlus/Page111/E3/title.jpg",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>&emsp;&emsp; #. </p>",
        answer: [
          "Two: judo and table tennis",
        ],
        type: 'longAnwser'
      },
    ]
  },


  4: { // Exercise num
     unit : 'Option 2',
    id : 'SB6-OPE2-P111-E4',
    audio: 'img/FriendsPlus/Page111/E4/Audio/G6 SB Track 3.06.mp3',
    video: '',
    component: D1,
    fontSize: 25,
    height: 30,
    exerciseKey: 'img/FriendsPlus/Page111/E4/Key/answerKey.png',
    titleImage: '',
    //titleQuestion: [{ num: '4', title: 'Complete the Key Phrases with words in the description.', color: '#EF882B' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page111/E4/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page111/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page111/E4/3.jpg', input: true, answer: '5.45 p.m.' },
        { url: 'img/FriendsPlus/Page111/E4/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page111/E4/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page111/E4/6.jpg' },
        { url: 'img/FriendsPlus/Page111/E4/7.jpg', input: true, answer: '4.30 p.m.' },
        { url: 'img/FriendsPlus/Page111/E4/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page111/E4/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page111/E4/10.jpg' },
        { url: 'img/FriendsPlus/Page111/E4/11.jpg', input: true, answer: 'Table tennis' },
        { url: 'img/FriendsPlus/Page111/E4/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page111/E4/13.jpg' },
        { url: 'img/FriendsPlus/Page111/E4/14.jpg', input: true, answer: '6.15 p.m.' },
        { url: 'img/FriendsPlus/Page111/E4/15.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page111/E4/16.jpg' },

      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  5: { // Exercise num
     unit : 'Option 2',
    id : 'SB6-OPE2-P111-E5',
    audio: 'img/FriendsPlus/Page111/E5/Audio/G6 SB Track 3.07.mp3',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page111/E5/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title: '',
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  6: { // Exercise num
     unit : 'Option 2',
    id : 'SB6-OPE2-P111-E6',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page111/E6/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },

}
export default json