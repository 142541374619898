import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import url from '../../configs/url';


const json = {
  1: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P91-E1',
    audio: '',
    video: url.cdnUrl + '/Animation - Unit 7 grammar 2 trang 91.mp4',
    component: D1,
    fontSize: 25,
    height: 25,
    paddingLeft: 7,
    paddingRight: 7,
    exerciseKey: 'img/FriendsPlus/Page91/E1/Key/1.png',
    titleImage: '',
    titleQuestion: [{ num: '1', title: 'Look at the questions about Robert Wadlow in the text on page 90 again and complete the table. Which word is in all of the questions?', color: '#553f9f' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page91/E1/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page91/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page91/E1/3.jpg', input: true, answer: 'Did' },
        { url: 'img/FriendsPlus/Page91/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page91/E1/5.jpg', input: true, answer: 'have' },
        { url: 'img/FriendsPlus/Page91/E1/6.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page91/E1/7.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page91/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page91/E1/9.jpg', input: true, answer: 'he' },
        { url: 'img/FriendsPlus/Page91/E1/10.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page91/E1/11.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page91/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page91/E1/13.jpg', input: true, answer: 'How' },
        { url: 'img/FriendsPlus/Page91/E1/14.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page91/E1/15.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page91/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page91/E1/17.jpg', input: true, answer: 'people' },
        { url: 'img/FriendsPlus/Page91/E1/18.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page91/E1/19.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page91/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page91/E1/21.jpg', input: true, answer: 'did' },
        { url: 'img/FriendsPlus/Page91/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page91/E1/23.jpg', input: true, answer: 'die' },
        { url: 'img/FriendsPlus/Page91/E1/24.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page91/E1/25.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P91-E2',
    audio: '',
    video: '',
    component: T6,
    // inputSize: 500,
    // titleImage: "img/FriendsPlus/Page90/E2/1.png",
    imgSize: '60%',
    inputSize: 400,
    exerciseKey: 'img/FriendsPlus/Page91/E2/Key/answerKey.png',
    // textCenter: true,
    titleQuestion: [{ num: '2', title: 'Order the words to make past simple questions. There is one extra word in each sentence.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='font-family:Rubik-Medium'><p><span style='font-weight:600'>&emsp; 1 &emsp;</span> here / you / did / do / grow up / ? </p>" +
          "<p>&#160;&emsp;&emsp; #</p>" +
          "<p><span style='font-weight:600'>&emsp; 2 &emsp; </span> where / what / live / did / he / ? </p>" +
          "<p>&#160;&emsp;&emsp; #</p>" +
          "<p><span style='font-weight:600'>&emsp; 3 &emsp; </span> does / he / get a job / did / ? </p>" +
          "<p>&#160;&emsp;&emsp; #</p>" +
          "<p><span style='font-weight:600'>&emsp; 4 &emsp; </span> how / travel / did / they / travels / ? </p>" +
          "<p>&#160;&emsp;&emsp; #</p>" +
          "<p><span style='font-weight:600'>&emsp; 5 &emsp; </span> did / finished / when / he / finish / ? </p>" +
          "<p>&#160;&emsp;&emsp; #</p>",
        answer: [
          "Did you grow up here?",
          "Where did he live?",
          "Did he get a job?",
          "How did they travel?",
          "When did he finish?"
        ],
        type: 'longAnwser'
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P91-E3',
    audio: '',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page91/E3/Key/1.png',
    titleImage: "img/FriendsPlus/Page91/E3/1.jpg",
    imgSize: '100%',
    titleQuestion: [{ num: '3', title: 'Read the information about the Vietnamese scholar Lê Quý Đôn. Write questions using the words in blue.', color: '#19beda' }],
    questionImage: [],
    questions: [
      {
        title:

          "<p>1.  # ?</p>" +
          "<p>2.  # ?</p>" +
          "<p>3.  # ?</p>" +
          "<p>4.  # ?</p>" +
          "<p>5.  # ?</p>" +
          "<p>6.  # ?</p>" +
          "<p>7.  # ?</p>",

        answer: [
          "What century was he in",
          "Who was he",
          "Where did Lê Quý Đôn go as an ambassador in 1760/Where did he go as an ambassador in 1760",
          "Was he the principal of the National University situated in the Văn Miếu in Hà Nội",
          "Did he have a few works of the old Vietnamese literature",
          "How old was he when he passed away in 1784/How old was he when he passed away in 1784",
          "What do they do in most cities in Việt Nam",
        ],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P91-E4',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page91/E4/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P91-E5',
    audio: '',
    video: '',
    component: T6,
    inputSize: 550,
    imgSize: '80%',
    fontSize: 27,
    exerciseKey: 'img/FriendsPlus/Page91/E5/Key/answerKey.png',
    titleImage: "img/FriendsPlus/Page91/E5/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1 </span> #?   </p>" +
          "<p><span style='font-weight:600'>2 </span> #?   </p>" +
          "<p><span style='font-weight:600'>3 </span> #?   </p>" +
          "<p><span style='font-weight:600'>4 </span> #?   </p>" +
          "<p><span style='font-weight:600'>5 </span> #?   </p>" +
          "<p><span style='font-weight:600'>6 </span> #?   </p>" +
          "<p><span style='font-weight:600'>7 </span> #?   </p>" +
          "<p><span style='font-weight:600'>8 </span> #?  </p>",
        answer: [
          "Did you live here when you were younger",
          "What did you do last weekend",
          "When did you last go to the cinema",
          "What film did you see",
          "Who did you go with",
          "Did you do any sports last weekend",
          "Did you play any video games yesterday",
          "Did you have fun on your last holiday",
        ],
        type: 'longAnwser'
      },
    ]
  },
}
export default json