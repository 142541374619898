import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import U1 from '../../components/ExcerciseTypes/UnderLine/U1'
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import S4 from '../../components/ExcerciseTypes/Select/Select4';


const json = {
  1: { // Exercise num
    
    unit : 'Option 6',
    id : 'SB6-OPE6-P115-E1',
    // audio: 'img/FriendsPlus/Page115/E1/Audio/07- Starter Unit - Track 07.mp3',
    video: '',
    component: S4,
    exerciseKey: 'img/FriendsPlus/Page115/E1/Key/answerKey.png',
    titleImage: '',
    // titleQuestion:[{num:'1',title:'Match the games with the categories in the box., colo'}],
    image: 'img/FriendsPlus/Page115/E1/title.jpg',
    // image1: 'img/FriendsPlus/Page115/E1/2.jpg',
    width: 280,
    questions: [
      {
        no: "1",
        text: "driving and racing",
      },
      {
        no: "2",
        text: "simulation",
      },
      {
        no: "3",
        text: "action and adventure",
      },
      {
        no: "4",
        text: "strategy",
      },
      {
        no: "5",
        text: "sport",
      },

    ]
  },
  2: { // Exercise num
    
    unit : 'Option 6',
    id : 'SB6-OPE6-P115-E2',
    audio: 'img/FriendsPlus/Page115/E2/Audio/G6 SB Track 3.15.mp3',
    video: '',
    component: D1,
    fontSize: 24,
    tag: '-',
    height: 30,
    exerciseKey: 'img/FriendsPlus/Page115/E2/Key/answerKey.jpg',
    titleImage: '',
    // titleQuestion: [{ num: '2', title: 'Listen to the dialogue and write the correct answers.', color: '#f67f24' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page115/E2/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page115/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page115/E2/3.jpg', input: true, answer: 'World Rally' },
        { url: 'img/FriendsPlus/Page115/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page115/E2/5.jpg', input: true, answer: 'Hero' },
        { url: 'img/FriendsPlus/Page115/E2/6.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page115/E2/7.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page115/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page115/E2/9.jpg', input: true, answer: 'fun, then boring - good, then boring' },
        { url: 'img/FriendsPlus/Page115/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page115/E2/11.jpg', input: true, answer: 'great-exciting' },
        { url: 'img/FriendsPlus/Page115/E2/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page115/E2/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page115/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page115/E2/15.jpg', input: true, answer: "6/10" },
        { url: 'img/FriendsPlus/Page115/E2/16.jpg' },
        { url: 'img/FriendsPlus/Page115/E2/17.jpg', input: true, answer: '4/10' },
        { url: 'img/FriendsPlus/Page115/E2/18.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page115/E2/19.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    
    unit : 'Option 6',
    id : 'SB6-OPE6-P115-E3',
    audio: 'img/FriendsPlus/Page115/E2/Audio/G6 SB Track 3.15.mp3',
    video: '',
    component: U1,
    titleImage: '',
    exerciseKey: 'img/FriendsPlus/Page115/E3/Key/answerKey.png',
    // titleImagePosition: 'top',
    titleQuestion: [{ num: '3', title: 'Listen again. Choose the correct words.', color: '#E36C29' }],
    questionImage: [],
    questions: [
      {
        title: "<p><span style='font-weight:600'>1</span> In Elsa’s game the players are # /# </p>" +
          "<p><span style='font-weight:600'>2</span>. Elsa was bored after one # / # " +
          "<p><span style='font-weight:600'>3</span>. Finn’s game has got very # / # graphics." +
          "<p><span style='font-weight:600'>4</span> You # / # cars in Finn’s game." +
          "<p><span style='font-weight:600'>5</span> In Chen’s game the # / # are complicated.",
        answerString: ['helpers', 'designers', 'hour', 'day', 'good', 'bad', 'create', 'drive', 'characters', 'rules'],
        answer: ['designers', 'hour', 'good', 'drive', 'rules'],
      },
    ]
  },
  4: { // Exercise num
    
    unit : 'Option 6',
    id : 'SB6-OPE6-P115-E4',
    audio: 'img/FriendsPlus/Page115/E4/Audio/G6 SB Track 3.16.mp3',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page115/E5/1.png",
    imgSize: '80%',
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title: '',
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  5: { // Exercise num
    
    unit : 'Option 6',
    id : 'SB6-OPE6-P115-E5',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page115/E6/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json