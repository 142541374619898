import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
    6: { // Exercise num
        unit: 'Progress Review 2',
        id: 'SB6-PV2-P59-E6',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page59/E6/Key/answerKey.png',
        titleImage: '',
        // titleQuestion: [{ num: '7', title: '' }],
        questionImage: [ // Row
            [
                // Column
                { url: 'img/FriendsPlus/Page59/E6/1.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page59/E6/2.jpg' },
                { url: 'img/FriendsPlus/Page59/E6/3.jpg', input: true, answer: 'How far' },
                { url: 'img/FriendsPlus/Page59/E6/4.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page59/E6/5.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page59/E6/6.jpg' },
                { url: 'img/FriendsPlus/Page59/E6/7.jpg', input: true, answer: 'How tall' },
                { url: 'img/FriendsPlus/Page59/E6/8.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page59/E6/9.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page59/E6/10.jpg' },
                { url: 'img/FriendsPlus/Page59/E6/11.jpg', input: true, answer: 'How fast' },
                { url: 'img/FriendsPlus/Page59/E6/12.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page59/E6/13.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page59/E6/14.jpg' },
                { url: 'img/FriendsPlus/Page59/E6/15.jpg', input: true, answer: 'How heavy' },
                { url: 'img/FriendsPlus/Page59/E6/16.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page59/E6/17.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page59/E6/18.jpg' },
                { url: 'img/FriendsPlus/Page59/E6/19.jpg', input: true, answer: 'How many' },
                { url: 'img/FriendsPlus/Page59/E6/20.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page59/E6/21.jpg' },

            ], [
                // Column
                { url: 'img/FriendsPlus/Page59/E6/22.jpg' },
                { url: 'img/FriendsPlus/Page59/E6/23.jpg', input: true, answer: 'How rare' },
                { url: 'img/FriendsPlus/Page59/E6/24.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page59/E6/25.jpg' },
            ],
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    7: { // Exercise num
        unit: 'Progress Review 2',
        id: 'SB6-PV2-P59-E7',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page59/E7/Key/answerKey.png',
        titleImage: '',
        // titleQuestion: [{ num: '7', title: '' }],
        questionImage: [ // Row
            [
                // Column
                { url: 'img/FriendsPlus/Page59/E7/1.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page59/E7/2.jpg' },
                { url: 'img/FriendsPlus/Page59/E7/3.jpg', input: true, answer: 'OK' },
                { url: 'img/FriendsPlus/Page59/E7/4.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page59/E7/5.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page59/E7/6.jpg' },
                { url: 'img/FriendsPlus/Page59/E7/7.jpg', input: true, answer: 'afraid' },
                { url: 'img/FriendsPlus/Page59/E7/8.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page59/E7/9.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page59/E7/10.jpg' },
                { url: 'img/FriendsPlus/Page59/E7/11.jpg', input: true, answer: 'but' },
                { url: 'img/FriendsPlus/Page59/E7/12.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page59/E7/13.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page59/E7/14.jpg' },
                { url: 'img/FriendsPlus/Page59/E7/15.jpg', input: true, answer: 'I' },
                { url: 'img/FriendsPlus/Page59/E7/16.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page59/E7/17.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page59/E7/18.jpg' },
                { url: 'img/FriendsPlus/Page59/E7/19.jpg', input: true, answer: 'can' },
                { url: 'img/FriendsPlus/Page59/E7/20.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page59/E7/21.jpg' },

            ], [
                // Column
                { url: 'img/FriendsPlus/Page59/E7/22.jpg' },
                { url: 'img/FriendsPlus/Page59/E7/23.jpg', input: true, answer: 'Thanks' },
                { url: 'img/FriendsPlus/Page59/E7/24.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page59/E7/25.jpg' },
            ],
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },



    5: { // Exercise num
        unit: 'Progress Review 2',
        id: 'SB6-PV2-P59-E5',
        audio: '',
        video: '',
        component: T6,
        inputSize: 500,
        exerciseKey: 'img/FriendsPlus/Page59/E5/Key/1.png',
        titleImage: "img/FriendsPlus/Page59/E5/1.png",
        imgSize: '70%',
        titleQuestion: [{ color: '#3F3478', num: '5', title: "Write questions and short answers with <i>can</i>." }],
        questionImage: [],
        questions: [
            {
                title:
                    "<div style='font-family:Rubik-Medium'><p><span style='font-weight:600'>1.</span> Freddie / swim / ? #? #.</p>" +
                    "<p><span style='font-weight:600'>2.</span> Anna and Kate / swim / ? #? #.</p>" +
                    "<p><span style='font-weight:600'>3.</span> Freddie / speak French / ? #? #.</p>" +
                    "<p><span style='font-weight:600'>4.</span> Anna and Kate / speak French / ? #? #.</p>" +
                    "<p><span style='font-weight:600'>5.</span> Freddie / play basketball / ? #? #.</p>" +
                    "<p><span style='font-weight:600'>6.</span> Anna and Kate / play basketball / ? #? #.</p>",
                answer: [
                    "Can Freddie swim",
                    "No, he can't",
                    "Can Anna and Kate swim",
                    "Yes, they can",
                    "Can Freddie speak French",
                    "Yes, he can",
                    "Can Anna and Kate speak French",
                    "No, they can't",
                    "Can Freddie play basketball",
                    "No, he can't",
                    "Can Anna and Kate play basketball",
                    "Yes, they can",
                ],
                type: 'longAnwser'
            },
        ]
    },
    //   6: { // Exercise num
    unit: 'Progress Review 2',
    //     audio: '',
    //     video: '',
    //     component: T6,
    //     titleImage: "img/FriendsPlus/Page59/E6/1.png",
    //     imgSize: '70%',
    //     titleQuestion: [{ color: '#3F3478', num: '6', title: "Complete the questions with How and one of the words." }],
    //     questionImage: [],
    //     questions: [
    //       {
    //         title:
    //           "<div style='font-family:Rubik-Medium'><p><span style='font-weight:600'>1.</span>‘ # can a whale swim?’ ‘Thousands</p>" +
    //           "<p>of kilometres.’</p>"+
    //           "<div style='font-family:Rubik-Medium'><p><span style='font-weight:600'>2.</span>‘ # is your older brother?’ ‘One</p>" +
    //           "<p>metre eighty-five.’</p>"+
    //           "<div style='font-family:Rubik-Medium'><p><span style='font-weight:600'>3.</span>‘ # can a wolf run?’ ‘Sixty</p>" +
    //           "<p>kilometres an hour.’</p>"+
    //           "<div style='font-family:Rubik-Medium'><p><span style='font-weight:600'>4.</span>‘ # is an ostrich?’ ‘More than</p>" +
    //           "<p>100 kilos.’</p>"+
    //           "<div style='font-family:Rubik-Medium'><p><span style='font-weight:600'>5.</span>‘ # pandas are there in the zoo?’</p>" +
    //           "<p>‘Two.’</p>"+
    //           "<div style='font-family:Rubik-Medium'><p><span style='font-weight:600'>6.</span>‘ # are these animals?’ ‘There are </p>" +
    //           "<p>only fifty in the world now.’</p>",
    //         answer: [
    //           "How far",
    //           "How tall",
    //           "How fast",
    //           "How heavy",
    //           "How many",
    //           "How rare",
    //         ],
    //         type: 'longAnwser'
    //       },
    //     ]
    //   },
    8: { // Exercise num
        unit: 'Progress Review 2',
        id: 'SB6-PV2-P59-E8',
        audio: '',
        video: '',
        component: T6,
        inputSize: 640,
        exerciseKey: 'img/FriendsPlus/Page59/E8/Key/1.png',
        // titleImage: "img/FriendsPlus/Page59/E6/1.png",
        imgSize: '70%',
        titleQuestion: [{ color: '#3F3478', num: '8', title: "Order the words to make sentences." }],
        questionImage: [],
        questions: [
            {
                title:
                    "<div style='font-family:Rubik-Medium'><p><span style='font-weight:600'>1.</span> live / pandas / the mountains / of / in / China</p>" +
                    "<p>Pandas #.</p>" +
                    "<p><span style='font-weight:600'>2.</span> 160 kilos / adult pandas / weigh / to / up / can</p>" +
                    "<p>Adult pandas #.</p>" +
                    "<p><span style='font-weight:600'>3.</span> in / panda / now / danger / is / the</p>" +
                    "<p>The #.</p>" +
                    "<p><span style='font-weight:600'>4.</span> creatures / one / it’s / rarest / the / in / of / world / the</p>" +
                    "<p>It’s #.</p>" +
                    "<p><span style='font-weight:600'>5.</span> is / problem / worst / the / human activity / like / farming</p>" +
                    "<p>The #.</p>" +
                    "<p><span style='font-weight:600'>6.</span> can / you / information / get / websites / from / as / such / <span style='font-style:italic'>Save the Panda!<span></p>" +
                    "<p>You #.</p>",
                answer: [
                    "live in the mountains of China",
                    "can weigh up to 160 kilos",
                    "panda is now in danger",
                    "one of the rarest creatures in the world",
                    "worst problem is human activity like farming",
                    "can get information from websites such as Save the Panda!",
                ],
                type: 'longAnwserInLine'
            },
        ]
    },
}
export default json