import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row, UncontrolledTooltip } from 'reactstrap';
import ExerciseKey from '../ExerciseKey'

import styles from './styles.module.css';
// import VideoModal from '../VideoModal';
// import { useSelector } from 'react-redux';

const FooterModal = ({ resultStringProps, result, isDisabledSubmit, isDisabledRetry, onRetry, onSubmit: onSubmitProps, audioUrl, exerciseKey, exerciseKeySize, hideBtnFooter }) => {

  const [state, setState] = React.useState({
    data: null,
    onRetrying: false,
    keysVisible: false,
    videoVisible: false,
    audioVisible: true,
    keyButtonVisible: false,
  });

  // const videoModal = React.useRef()

  const toggleState = React.useCallback((fieldName) => () => {
    setState((prevState) => ({
      ...prevState,
      [fieldName]: !prevState[fieldName],
    }));
  }, []);

  const toggleDrawer = React.useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      keysVisible: !prevState.keysVisible
    }));
  }, []);



  const onSubmit = React.useCallback(() => {
    onSubmitProps?.();

    setState((prevState) => ({ ...prevState, keyButtonVisible: true }));

  }, [onSubmitProps]);

  const resultString = React.useCallback(() => {
    let correctAnswer = 0;
    if (!result) return;

    result.forEach((item, index) => {
      if (item) {
        correctAnswer++
      }
    })
    const percent = parseInt(correctAnswer / result.length * 100)
    return `${correctAnswer}/${result.length} (${percent}%)`
  }, [result])

  return (
    <Row className='d-flex justify-content-center mt-2 mb-2' >
      <div style={{ position: 'fixed', bottom: 0, zIndex: 1, display: 'flex', width: '90%', justifyContent: 'center', backgroundColor: 'white', padding: 10, height: 64 }}>
        <div className='d-flex' style={{ flexDirection: 'row', width: '80%' }}>
          {/* Button Key */}
          <div className={`${styles.wrapColLeft}`}>
            {/* {state.keyButtonVisible && (
              <Button
                id="key"
                color="info"
                type="button"
                className="btn-icon btn-2"
                onClick={toggleDrawer}
              >
                <span className="btn-inner--icon">
                  <i className="fas fa-key"></i>
                </span>
              </Button>
            )} */}

            {/* Button AUDIO */}
            {/* {audioUrl && (
              <>
                <Button
                  id="tooltip611234742"
                  color="info"
                  type="button"
                  className="btn-icon btn-2"
                  onClick={toggleState('audioVisible')}
                >
                  <span className="btn-inner--icon">
                    <i className="fas fa-headphones-alt"></i>
                  </span>
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  placement="top"
                  target="tooltip611234742"
                >
                  Audio
                </UncontrolledTooltip>
              </>
            )} */}
            {/* {(result.length || resultStringProps)  && !isDisabledRetry && ( */}
            {(result || resultStringProps) && state.keyButtonVisible && !isDisabledRetry && (
              <>
                <Button
                  id="key"
                  color="info"
                  type="button"
                  className="btn-icon btn-2"
                  onClick={toggleDrawer}
                >
                  <span className="btn-inner--icon">
                    <i className="fas fa-key"></i>
                  </span>
                </Button>
                <Button
                  id="tooltip611234742"
                  color="info"
                  type="button"
                  className="btn-icon btn-2"
                // onClick={toggleState('audioVisible')}
                >
                  {/* <span className="btn-inner--icon">
                    <i className="fas fa-headphones-alt"></i>
                  </span> */}
                  {'Kết quả: '}{resultString() || resultStringProps}
                </Button>
                {/* <UncontrolledTooltip
                  delay={0}
                  placement="top"
                  target="tooltip611234742"
                >
                  Audio
                </UncontrolledTooltip> */}
              </>
            )}
            {/* {videoUrl && (
              <>
                <Button
                  id="tooltip611234743"
                  color="info"
                  type="button"
                  className="btn-icon btn-2"
                  onClick={() => videoModal.current?.toggleModal('isVisibled')}
                >
                  <span className="btn-inner--icon">
                    <i class="fas fa-video"></i>
                  </span>
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  placement="top"
                  target="tooltip611234743"
                >
                  Video
                </UncontrolledTooltip>
              </>
            )} */}
          </div>
          {state.audioVisible && audioUrl && (
            <div className={`${styles.wrapColCenter} justify-center items-center`} >
              <div >
                <audio className={styles.audio} id='myAudio' controls>
                  <source src={audioUrl} type='audio/mpeg' />
                </audio>
              </div>

            </div>
          )}
          <div className={`${styles.wrapColRight}`}>
            <Button
              type="button"
              color="danger"
              // className="ml-2"
              data-dismiss="modal"
              disabled={isDisabledRetry}
              hidden={hideBtnFooter}
              onClick={onRetry}
            >
              Try again
            </Button>
            <Button
              type="button"
              color="danger"
              // className="ml-auto"
              data-dismiss="2"
              disabled={isDisabledSubmit}
              hidden={hideBtnFooter}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
      <ExerciseKey
        exerciseKey={exerciseKey}
        exerciseKeySize={exerciseKeySize}
        visible={state.keysVisible}
        onClose={() => setState((prevState) => ({ ...prevState, keysVisible: !prevState.keysVisible }))}
      />
      {/* <VideoModal ref={videoModal} videoUrl={videoUrl} /> */}
    </Row>
  );
};

FooterModal.propTypes = {
  questions: PropTypes.instanceOf(Object),
  isVisibled: PropTypes.bool,
  toggleModal: PropTypes.func,
};

export default FooterModal;
