// import WordsBox from '../../components/ExcerciseTypes/Design/WordBox1'
import WordsBox from "../../components/ExcerciseTypes/Design/WordBox";
import UI from "../../components/ExcerciseTypes/UseIt";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "SB6-U1-P23-E1",
    audio: "",
    video: "",
    component: WordsBox,
    isAutofocus: true,
    fontSize: 29,
    height: 39,
    textTransform: "uppercase",
    paddingLeft: 0,
    exerciseKey: "img/FriendsPlus/Page23/E1/Key/Key.png",
    titleImage: "",
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page23/E1/2.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page23/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page23/E1/5.jpg",
          input: true,
          answer: "A",
          group: 0,
        },
        {
          url: "img/FriendsPlus/Page23/E1/6.jpg",
          input: true,
          answer: "R",
          group: 0,
        },
        {
          url: "img/FriendsPlus/Page23/E1/7.jpg",
          input: true,
          answer: "K",
          group: 0,
        },
        {
          url: "img/FriendsPlus/Page23/E1/8.jpg",
          input: true,
          answer: "E",
          group: 0,
        },
        { url: "img/FriendsPlus/Page23/E1/9.jpg" },
        { url: "img/FriendsPlus/Page23/E1/10.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page23/E1/11.jpg" },
        {
          url: "img/FriendsPlus/Page23/E1/12.jpg",
          input: true,
          answer: "E",
          group: 1,
        },
        { url: "img/FriendsPlus/Page23/E1/13.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page23/E1/14.jpg" },
        {
          url: "img/FriendsPlus/Page23/E1/15.jpg",
          input: true,
          answer: "S",
          group: 2,
        },
        {
          url: "img/FriendsPlus/Page23/E1/16.jpg",
          input: true,
          answer: "Q",
          group: 2,
        },
        { url: "img/FriendsPlus/Page23/E1/17.jpg" },
        {
          url: "img/FriendsPlus/Page23/E1/18.jpg",
          input: true,
          answer: "A",
          group: 2,
        },
        { url: "img/FriendsPlus/Page23/E1/19.jpg" },
        {
          url: "img/FriendsPlus/Page23/E1/20.jpg",
          input: true,
          answer: "E",
          group: 2,
        },
        { url: "img/FriendsPlus/Page23/E1/21.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page23/E1/22.jpg" },
        {
          url: "img/FriendsPlus/Page23/E1/23.jpg",
          input: true,
          answer: "T",
          group: 3,
        },
        { url: "img/FriendsPlus/Page23/E1/24.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page23/E1/25.jpg" },
        {
          url: "img/FriendsPlus/Page23/E1/26.jpg",
          input: true,
          answer: "A",
          group: 4,
        },
        {
          url: "img/FriendsPlus/Page23/E1/27.jpg",
          input: true,
          answer: "F",
          group: 4,
        },
        { url: "img/FriendsPlus/Page23/E1/28.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page23/E1/29.jpg" },
        {
          url: "img/FriendsPlus/Page23/E1/30.jpg",
          input: true,
          answer: "U",
          group: 5,
        },
        { url: "img/FriendsPlus/Page23/E1/31.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page23/E1/32.jpg" },
        {
          url: "img/FriendsPlus/Page23/E1/33.jpg",
          input: true,
          answer: "L",
          group: 6,
        },
        { url: "img/FriendsPlus/Page23/E1/34.jpg" },
        {
          url: "img/FriendsPlus/Page23/E1/35.jpg",
          input: true,
          answer: "B",
          group: 6,
        },
        {
          url: "img/FriendsPlus/Page23/E1/36.jpg",
          input: true,
          answer: "R",
          group: 6,
        },
        { url: "img/FriendsPlus/Page23/E1/37.jpg" },
        {
          url: "img/FriendsPlus/Page23/E1/38.jpg",
          input: true,
          answer: "R",
          group: 6,
        },
        { url: "img/FriendsPlus/Page23/E1/39.jpg" },
        { url: "img/FriendsPlus/Page23/E1/40.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page23/E1/41.jpg" },
        {
          url: "img/FriendsPlus/Page23/E1/42.jpg",
          input: true,
          answer: "A",
          group: 7,
        },
        { url: "img/FriendsPlus/Page23/E1/43.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page23/E1/44.jpg" },
        {
          url: "img/FriendsPlus/Page23/E1/45.jpg",
          input: true,
          answer: "I",
          group: 8,
        },
        {
          url: "img/FriendsPlus/Page23/E1/46.jpg",
          input: true,
          answer: "N",
          group: 8,
        },
        { url: "img/FriendsPlus/Page23/E1/47.jpg" },
        {
          url: "img/FriendsPlus/Page23/E1/48.jpg",
          input: true,
          answer: "M",
          group: 8,
        },
        { url: "img/FriendsPlus/Page23/E1/49.jpg" },
        { url: "img/FriendsPlus/Page23/E1/50.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page23/E1/51.jpg" },
        {
          url: "img/FriendsPlus/Page23/E1/52.jpg",
          input: true,
          answer: "T",
          group: 9,
        },
        { url: "img/FriendsPlus/Page23/E1/53.jpg" },
        {
          url: "img/FriendsPlus/Page23/E1/54.jpg",
          input: true,
          answer: "E",
          group: 9,
        },
        { url: "img/FriendsPlus/Page23/E1/55.jpg" },
        {
          url: "img/FriendsPlus/Page23/E1/56.jpg",
          input: true,
          answer: "T",
          group: 9,
        },
        { url: "img/FriendsPlus/Page23/E1/57.jpg" },
        {
          url: "img/FriendsPlus/Page23/E1/58.jpg",
          input: true,
          answer: "E",
          group: 9,
        },
        { url: "img/FriendsPlus/Page23/E1/59.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page23/E1/60.jpg" }],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["ARKE", "SQAE", "AF", "LBRR", "INM", "TETE"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "SB6-U1-P23-E2",
    audio: "",
    video: "",
    component: UI,
    //titleImage: 'http://',
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page23/E2/1.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E2/2.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E2/3.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E2/4.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E2/5.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E2/6.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E2/7.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 1",
    id: "SB6-U1-P23-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page23/E3/Key/Key.png",
    component: D1,
    //titleImage: 'http://',
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page23/E3/1.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E3/2.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E3/3.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E3/4.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E3/5.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 1",
    id: "SB6-U1-P23-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page23/E4/Key/answerKey.jpg",
    component: D1,
    //titleImage: 'http://',
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page23/E4/1.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E4/2.jpg" },
        {
          url: "img/FriendsPlus/Page23/E4/3.jpg",
          input: true,
          answer: "friendly",
        },
        { url: "img/FriendsPlus/Page23/E4/4.jpg" },
        {
          url: "img/FriendsPlus/Page23/E4/5.jpg",
          input: true,
          answer: "modern",
        },
        { url: "img/FriendsPlus/Page23/E4/6.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E4/7.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E4/8.jpg" },
        {
          url: "img/FriendsPlus/Page23/E4/9.jpg",
          input: true,
          answer: "clean",
        },
        { url: "img/FriendsPlus/Page23/E4/10.jpg" },
        {
          url: "img/FriendsPlus/Page23/E4/11.jpg",
          input: true,
          answer: "ugly",
        },
        { url: "img/FriendsPlus/Page23/E4/12.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E4/13.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E4/14.jpg" },
        {
          url: "img/FriendsPlus/Page23/E4/15.jpg",
          input: true,
          answer: "dangerous",
        },
        { url: "img/FriendsPlus/Page23/E4/16.jpg" },
        {
          url: "img/FriendsPlus/Page23/E4/17.jpg",
          input: true,
          answer: "expensive",
        },
        { url: "img/FriendsPlus/Page23/E4/18.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E4/19.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 1",
    id: "SB6-U1-P23-E5",
    audio: "",
    video: "",
    component: UI,
    //titleImage: 'http://',
    questionImage: [
      // Row
      [
        // Column
        { url: "img/FriendsPlus/Page23/E5/1.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E5/2.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E5/3.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E5/4.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E5/5.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E5/6.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E5/7.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E5/8.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E5/9.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E5/10.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E5/11.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E5/12.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E5/13.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E5/14.jpg" },
      ],
      [
        // Column
        { url: "img/FriendsPlus/Page23/E5/15.jpg" },
      ],
    ],
    questions: [
      {
        title: "The dictionaries are # the shelf",
        answer: ["table"],
      },
    ],
  },
};
export default json;
