import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import url from '../../configs/url';

const json = {
    1: {
        unit: 'Unit 3',
        id: 'SB6-U3-P38-E1',
        audio: '',
        video: 'https://cloud.softech.vn/storage/SachSo/Videos/P38E1_2.mp4',
        videoSub: url.cdnUrl + '/P38E1_2.vtt',
        component: DesignUnderLine,
        isHiddenCheck: true,
        totalInput: 3,
        exerciseKey: 'img/FriendsPlus/Page38/E1/Key/1.jpg',
        // titleQuestion: [{ color: '#026AAD', num: '15', title: "Choose the best title for the text. Tick (✔) one box." }],
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page38/E1/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page38/E1/3.jpg', input: 1, isCorrect: true },
                { url: 'img/FriendsPlus/Page38/E1/4.jpg' },
                { url: 'img/FriendsPlus/Page38/E1/5.jpg', input: 2, isCorrect: true },
                { url: 'img/FriendsPlus/Page38/E1/6.jpg' },
                { url: 'img/FriendsPlus/Page38/E1/7.jpg', input: 3, isCorrect: false },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E1/8.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E1/9.jpg' },
                { url: 'img/FriendsPlus/Page38/E1/10.jpg', input: 1, isCorrect: false },
                { url: 'img/FriendsPlus/Page38/E1/11.jpg' },
                { url: 'img/FriendsPlus/Page38/E1/12.jpg', input: 2, isCorrect: false },
                { url: 'img/FriendsPlus/Page38/E1/13.jpg' },
                { url: 'img/FriendsPlus/Page38/E1/14.jpg', input: 3, isCorrect: true },
            ],
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    2: { // Exercise num
        unit: 'Unit 3',
        id: 'SB6-U3-P38-E2',
        audio: 'img/FriendsPlus/Page38/E2/Audio/G6 SB Track 1.29.mp3',
        video: 'https://cloud.softech.vn/storage/SachSo/Videos/P38E1_2.mp4',
        videoSub: url.cdnUrl + '/P38E1_2.vtt',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page38/E2/Key/answerKey.png',
        titleImage: '',
        titleQuestion: [{ color: '#026AAD', num: '2', title: 'Watch or listen again and complete the Key Phrases.' }],
        questionImage: [ // Row
            [
                // Column
                { url: 'img/FriendsPlus/Page38/E2/1.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page38/E2/2.jpg' },
                { url: 'img/FriendsPlus/Page38/E2/3.jpg', input: true, answer: 'it is' },
                { url: 'img/FriendsPlus/Page38/E2/4.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page38/E2/5.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page38/E2/6.jpg' },
                { url: 'img/FriendsPlus/Page38/E2/7.jpg', input: true, answer: "i've got" },
                { url: 'img/FriendsPlus/Page38/E2/8.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page38/E2/9.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page38/E2/10.jpg' },
                { url: 'img/FriendsPlus/Page38/E2/11.jpg', input: true, answer: 'a frog' },
                { url: 'img/FriendsPlus/Page38/E2/12.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page38/E2/13.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page38/E2/14.jpg' },
                { url: 'img/FriendsPlus/Page38/E2/15.jpg', input: true, answer: 'a dog' },
                { url: 'img/FriendsPlus/Page38/E2/16.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page38/E2/17.jpg' },
            ],
            [
                // Column   
                { url: 'img/FriendsPlus/Page38/E2/18.jpg' },
                { url: 'img/FriendsPlus/Page38/E2/19.jpg', input: true, answer: 'an elephant' },
                { url: 'img/FriendsPlus/Page38/E2/20.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page38/E2/21.jpg' },
            ],

        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    3: {
        unit: 'Unit 3',
        id: 'SB6-U3-P38-E3',
        audio: 'img/FriendsPlus/Page38/E3/Audio/G6 SB Track 1.30.mp3',
        video: '',
        component: DesignUnderLine,
        isHiddenCheck: true,
        totalInput: 3,
        exerciseKey: 'img/FriendsPlus/Page38/E3/Key/1.png',
        // titleQuestion: [{ color: '#026AAD', num: '15', title: "Choose the best title for the text. Tick (✔) one box." }],
        questionImage: [ // Row
            [
                { url: 'img/FriendsPlus/Page38/E3/1.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page38/E3/3.jpg', input: 1 },
                { url: 'img/FriendsPlus/Page38/E3/4.jpg', input: 2, isCorrect: true },
                { url: 'img/FriendsPlus/Page38/E3/5.jpg', input: 3 },
                { url: 'img/FriendsPlus/Page38/E3/6.jpg', input: 4 },
                { url: 'img/FriendsPlus/Page38/E3/7.jpg', input: 5, isCorrect: true },
                { url: 'img/FriendsPlus/Page38/E3/8.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E3/9.jpg' },
                { url: 'img/FriendsPlus/Page38/E3/10.jpg', input: 6, isCorrect: true },
                { url: 'img/FriendsPlus/Page38/E3/11.jpg', input: 7 },
                { url: 'img/FriendsPlus/Page38/E3/12.jpg', input: 8 },
                { url: 'img/FriendsPlus/Page38/E3/13.jpg', input: 9, isCorrect: true },
                { url: 'img/FriendsPlus/Page38/E3/14.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E3/15.jpg' },
                { url: 'img/FriendsPlus/Page38/E3/16.jpg', input: 10 },
                { url: 'img/FriendsPlus/Page38/E3/17.jpg', input: 11, isCorrect: true },
                { url: 'img/FriendsPlus/Page38/E3/18.jpg', input: 12 },
                { url: 'img/FriendsPlus/Page38/E3/19.jpg', input: 13 },
                { url: 'img/FriendsPlus/Page38/E3/20.jpg', input: 14, isCorrect: true },
                { url: 'img/FriendsPlus/Page38/E3/21.jpg', },
                { url: 'img/FriendsPlus/Page38/E3/22.jpg', input: 15, isCorrect: true },
                { url: 'img/FriendsPlus/Page38/E3/23.jpg', input: 16 },
                { url: 'img/FriendsPlus/Page38/E3/24.jpg', input: 17 },
                { url: 'img/FriendsPlus/Page38/E3/25.jpg', input: 18, isCorrect: true },
                { url: 'img/FriendsPlus/Page38/E3/26.jpg' },
            ],
            [
                { url: 'img/FriendsPlus/Page38/E3/27.jpg' },
            ],
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    4: { // Exercise num
        unit: 'Unit 3',
        id: 'SB6-U3-P38-E4',
        audio: 'img/FriendsPlus/Page38/E4/Audio/G6 SB Track 1.31.mp3',
        video: '',
        component: D1,
        height: 25,
        fontSize: 23,
        exerciseKey: 'img/FriendsPlus/Page38/E4/Key/Key.png',
        titleImage: '',
        // titleQuestion: [{ color: '#026AAD', num: '4', title: 'Watch or listen again and complete the Key Phrases.' }],
        questionImage: [ // Row
            [
                // Column
                { url: 'img/FriendsPlus/Page38/E4/1.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page38/E4/2.jpg' },
                { url: 'img/FriendsPlus/Page38/E4/3.jpg', input: true, answer: 'frog' },
                { url: 'img/FriendsPlus/Page38/E4/4.jpg' },
                { url: 'img/FriendsPlus/Page38/E4/5.jpg', input: true, answer: 'elephant' },
                { url: 'img/FriendsPlus/Page38/E4/6.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page38/E4/7.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page38/E4/8.jpg' },
                { url: 'img/FriendsPlus/Page38/E4/9.jpg', input: true, answer: 'butterfly' },
                { url: 'img/FriendsPlus/Page38/E4/10.jpg' },
                { url: 'img/FriendsPlus/Page38/E4/11.jpg', input: true, answer: 'snake' },
                { url: 'img/FriendsPlus/Page38/E4/12.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page38/E4/13.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page38/E4/14.jpg' },
                { url: 'img/FriendsPlus/Page38/E4/15.jpg', input: true, answer: 'gorilla' },
                { url: 'img/FriendsPlus/Page38/E4/16.jpg' },
                { url: 'img/FriendsPlus/Page38/E4/17.jpg', input: true, answer: 'eagle' },
                { url: 'img/FriendsPlus/Page38/E4/18.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page38/E4/19.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page38/E4/20.jpg' },
                { url: 'img/FriendsPlus/Page38/E4/21.jpg', input: true, answer: 'rabbit' },
                { url: 'img/FriendsPlus/Page38/E4/22.jpg' },
                { url: 'img/FriendsPlus/Page38/E4/23.jpg', input: true, answer: 'whale' },
                { url: 'img/FriendsPlus/Page38/E4/24.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page38/E4/25.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page38/E4/26.jpg' },
                { url: 'img/FriendsPlus/Page38/E4/27.jpg', input: true, answer: 'crocodile' },
                { url: 'img/FriendsPlus/Page38/E4/28.jpg' },
                { url: 'img/FriendsPlus/Page38/E4/29.jpg', input: true, answer: 'ostrich' },
                { url: 'img/FriendsPlus/Page38/E4/30.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page38/E4/31.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page38/E4/32.jpg' },
                { url: 'img/FriendsPlus/Page38/E4/33.jpg', input: true, answer: 'bat' },
                { url: 'img/FriendsPlus/Page38/E4/34.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page38/E4/35.jpg' },
            ],
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
}
export default json