import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {

    12: { // Exercise num
        unit : 'Progress Review 3',
        id : 'SB6-PV3-P85-E12',
        audio: '',
        video: '',
        component: D1,
        fontSize: 22,
        exerciseKey: 'img/FriendsPlus/Page85/E12/Key/1.png',
        titleQuestion: [{ color: '#4F3A96', num: '12', title: 'Complete the sentences using the past simple form of the verbs. ' }],
        titleImage: '',
        questionImage: [ // Row
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E12/1.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E12/2.jpg' },
                { url: 'img/FriendsPlus/Page85/E12/3.jpg', input: true, answer: 'watched' },
                { url: 'img/FriendsPlus/Page85/E12/4.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E12/5.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E12/6.jpg' },
                { url: 'img/FriendsPlus/Page85/E12/7.jpg', input: true, answer: 'swam' },
                { url: 'img/FriendsPlus/Page85/E12/8.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E12/9.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E12/10.jpg' },
                { url: 'img/FriendsPlus/Page85/E12/11.jpg', input: true, answer: 'travelled' },
                { url: 'img/FriendsPlus/Page85/E12/12.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E12/13.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E12/14.jpg' },
                { url: 'img/FriendsPlus/Page85/E12/15.jpg', input: true, answer: 'became' },
                { url: 'img/FriendsPlus/Page85/E12/16.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E12/17.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E12/18.jpg' },
                { url: 'img/FriendsPlus/Page85/E12/19.jpg', input: true, answer: 'scored' },
                { url: 'img/FriendsPlus/Page85/E12/20.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E12/21.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E12/22.jpg' },
                { url: 'img/FriendsPlus/Page85/E12/23.jpg', input: true, answer: 'competed' },
                { url: 'img/FriendsPlus/Page85/E12/24.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E12/25.jpg' },
            ],
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    13: { // Exercise num
        unit : 'Progress Review 3',
        id : 'SB6-PV3-P85-E13',
        audio: '',
        video: '',
        component: D1,
        fontSize: 30,
        height: 33,
        exerciseKey: 'img/FriendsPlus/Page85/E13/Key/key.png',
        titleImage: '',
        questionImage: [ // Row
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E13/1.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E13/2.jpg' },
                { url: 'img/FriendsPlus/Page85/E13/3.jpg', input: true, answer: 'How' },
                { url: 'img/FriendsPlus/Page85/E13/4.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E13/5.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E13/6.jpg' },
                { url: 'img/FriendsPlus/Page85/E13/7.jpg', input: true, answer: 'was' },
                { url: 'img/FriendsPlus/Page85/E13/8.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E13/9.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E13/10.jpg' },
                { url: 'img/FriendsPlus/Page85/E13/11.jpg', input: true, answer: 'went' },
                { url: 'img/FriendsPlus/Page85/E13/12.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E13/13.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E13/14.jpg' },
                { url: 'img/FriendsPlus/Page85/E13/15.jpg', input: true, answer: 'good' },
                { url: 'img/FriendsPlus/Page85/E13/16.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E13/17.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E13/18.jpg' },
                { url: 'img/FriendsPlus/Page85/E13/19.jpg', input: true, answer: 'were' },
                { url: 'img/FriendsPlus/Page85/E13/20.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E13/21.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E13/22.jpg' },
                { url: 'img/FriendsPlus/Page85/E13/23.jpg', input: true, answer: 'news' },
                { url: 'img/FriendsPlus/Page85/E13/24.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E13/25.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E13/26.jpg' },
                { url: 'img/FriendsPlus/Page85/E13/27.jpg', input: true, answer: 'with' },
                { url: 'img/FriendsPlus/Page85/E13/28.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E13/29.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E13/30.jpg' },
                { url: 'img/FriendsPlus/Page85/E13/31.jpg', input: true, answer: "don't" },
                { url: 'img/FriendsPlus/Page85/E13/32.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E13/33.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E13/34.jpg' },
                { url: 'img/FriendsPlus/Page85/E13/35.jpg', input: true, answer: 'when' },
                { url: 'img/FriendsPlus/Page85/E13/36.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page85/E13/37.jpg' },
            ],
        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    14: { // Exercise num
        unit : 'Progress Review 3',
        id : 'SB6-PV3-P85-E14',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page85/E14/Key/answerKey.png',
        titleImage: '',
        titleQuestion: [{ num: '14', title: 'Order the words to make sentences', color: '#7364B2' }],
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page85/E14/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page85/E14/2.jpg' },
                { url: 'img/FriendsPlus/Page85/E14/3.jpg', input: true, answer: 'full name is Andrew Baron Murray.' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page85/E14/4.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page85/E14/5.jpg' },
                { url: 'img/FriendsPlus/Page85/E14/6.jpg', input: true, answer: 'a tennis player from Scotland.' },
            ],
            [
                // Column7
                { url: 'img/FriendsPlus/Page85/E14/7.jpg' },
            ],
            [
                // Column8
                { url: 'img/FriendsPlus/Page85/E14/8.jpg' },
                { url: 'img/FriendsPlus/Page85/E14/9.jpg', input: true, answer: 'was born on 15th May 1987.' },
            ],
            [
                // Column10
                { url: 'img/FriendsPlus/Page85/E14/10.jpg' },
            ],
            [
                // Column11
                { url: 'img/FriendsPlus/Page85/E14/11.jpg' },
                { url: 'img/FriendsPlus/Page85/E14/12.jpg', input: true, answer: 'first competed in tennis matches when he was at school.' },
            ],
            [
                // Column13
                { url: 'img/FriendsPlus/Page85/E14/13.jpg' },
            ],
            [
                // Column14
                { url: 'img/FriendsPlus/Page85/E14/14.jpg' },
                { url: 'img/FriendsPlus/Page85/E14/15.jpg', input: true, answer: 'the age of 17 he won the Junior US Open.' },
            ],
            [
                // Column16
                { url: 'img/FriendsPlus/Page85/E14/16.jpg' },
            ],
            [
                // Column17
                { url: 'img/FriendsPlus/Page85/E14/17.jpg' },
                { url: 'img/FriendsPlus/Page85/E14/18.jpg', input: true, answer: 'won a gold medal at the Olympics.' },
            ],
            [
                // Column19
                { url: 'img/FriendsPlus/Page85/E14/19.jpg' },
            ],
            [
                // Column20
                { url: 'img/FriendsPlus/Page85/E14/20.jpg' },
                { url: 'img/FriendsPlus/Page85/E14/21.jpg', input: true, answer: ' one of the most successful British tennis players.' },
            ],
            [
                // Column22
                // { url: 'img/FriendsPlus/Page85/E14/22.jpg' },
            ],

        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
}
export default json;