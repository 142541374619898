
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {

	1: {
		unit : 'Unit 5',
		id : 'SB6-U5-P62-E1',
		audio: 'img/FriendsPlus/Page62/E1/Audio/G6 SB Track 2.02.WAV',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page62/E1/Key/answerKey.png',
		component: DesignUnderLine,
		totalInput: 19,
		//titleQuestion: [{ num: '1', title: `Work in pairs. Answer questions 1–6 in the quiz.`, color: '#E26D28' }],
		questionImage: [ // Row

			[
				{ url: 'img/FriendsPlus/Page62/E1/02.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1/03.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1/04.jpg', input: 1, isCorrect: true },
				{ url: 'img/FriendsPlus/Page62/E1/05.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1/06.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1/07.jpg', input: 1 },
				{ url: 'img/FriendsPlus/Page62/E1/08.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1/09.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1/10.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1/11.jpg', input: 2, isCorrect: true },
				{ url: 'img/FriendsPlus/Page62/E1/12.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1/13.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1/14.jpg', input: 2 },
				{ url: 'img/FriendsPlus/Page62/E1/15.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1/16.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1/17.jpg', input: 3 },
				{ url: 'img/FriendsPlus/Page62/E1/18.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1/19.jpg', input: 3, isCorrect: true },
				{ url: 'img/FriendsPlus/Page62/E1/20.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1/21.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1/22.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1/23.jpg', input: 4 },
				{ url: 'img/FriendsPlus/Page62/E1/24.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1/25.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1/26.jpg', input: 4, isCorrect: true },
				{ url: 'img/FriendsPlus/Page62/E1/27.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1/28.jpg', input: 5 },
				{ url: 'img/FriendsPlus/Page62/E1/29.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1/30.jpg', input: 5, isCorrect: true },
				{ url: 'img/FriendsPlus/Page62/E1/31.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1/32.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1/33.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1/34.jpg', input: 6, isCorrect: true },
				{ url: 'img/FriendsPlus/Page62/E1/35.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1/36.jpg', input: 6 },
				{ url: 'img/FriendsPlus/Page62/E1/37.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1/38.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1/39.jpg', input: 7 },
				{ url: 'img/FriendsPlus/Page62/E1/40.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1/41.jpg', input: 7, isCorrect: true },
				{ url: 'img/FriendsPlus/Page62/E1/42.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1/43.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1/44.jpg', input: 8 },
				{ url: 'img/FriendsPlus/Page62/E1/45.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1/46.jpg', input: 8, isCorrect: true },
				{ url: 'img/FriendsPlus/Page62/E1/47.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1/48.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1/49.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1/50.jpg', input: 9, isCorrect: true },
				{ url: 'img/FriendsPlus/Page62/E1/51.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1/52.jpg', input: 9 },
				{ url: 'img/FriendsPlus/Page62/E1/53.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1/54.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1-2/01.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1-2/02.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1-2/03.jpg', input: 10, isCorrect: true },
				{ url: 'img/FriendsPlus/Page62/E1-2/04.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1-2/05.jpg', input: 10 },
				{ url: 'img/FriendsPlus/Page62/E1-2/06.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1-2/07.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1-2/08.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1-2/09.jpg', input: 11, isCorrect: true },
				{ url: 'img/FriendsPlus/Page62/E1-2/10.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1-2/11.jpg', input: 11 },
				{ url: 'img/FriendsPlus/Page62/E1-2/12.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1-2/13.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1-2/14.jpg', input: 12, isCorrect: true },
				{ url: 'img/FriendsPlus/Page62/E1-2/15.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1-2/16.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1-2/17.jpg', input: 12 },
				{ url: 'img/FriendsPlus/Page62/E1-2/18.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1-2/19.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1-2/20.jpg', input: 13 },
				{ url: 'img/FriendsPlus/Page62/E1-2/21.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1-2/22.jpg', input: 13, isCorrect: true },
				{ url: 'img/FriendsPlus/Page62/E1-2/23.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1-2/24.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1-2/25.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1-2/26.jpg', input: 14 },
				{ url: 'img/FriendsPlus/Page62/E1-2/27.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1-2/28.jpg', input: 14, isCorrect: true },
				{ url: 'img/FriendsPlus/Page62/E1-2/29.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1-2/30.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1-2/31.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1-2/32.jpg', input: 15, isCorrect: true },
				{ url: 'img/FriendsPlus/Page62/E1-2/33.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1-2/34.jpg', input: 15 },
				{ url: 'img/FriendsPlus/Page62/E1-2/35.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1-2/36.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1-2/37.jpg', input: 16, isCorrect: true },
				{ url: 'img/FriendsPlus/Page62/E1-2/38.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1-2/39.jpg', input: 16 },
				{ url: 'img/FriendsPlus/Page62/E1-2/40.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1-2/41.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1-2/42.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1-2/43.jpg', input: 17 },
				{ url: 'img/FriendsPlus/Page62/E1-2/44.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1-2/45.jpg', input: 17, isCorrect: true },
				{ url: 'img/FriendsPlus/Page62/E1-2/46.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1-2/47.jpg', input: 18, isCorrect: true },
				{ url: 'img/FriendsPlus/Page62/E1-2/48.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1-2/49.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1-2/50.jpg', input: 18 },
				{ url: 'img/FriendsPlus/Page62/E1-2/51.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1-2/52.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1-2/53.jpg', input: 19 },
				{ url: 'img/FriendsPlus/Page62/E1-2/54.jpg' },
				{ url: 'img/FriendsPlus/Page62/E1-2/55.jpg', input: 19, isCorrect: true },
				{ url: 'img/FriendsPlus/Page62/E1-2/56.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page62/E1-2/57.jpg' },
			]
		],
		questions: [
			{
				title: 'The dictionaries are # the shelf',
				answer: ['table']
			},
		]
	},

	2: { // Exercise num
		unit : 'Unit 5',
		id : 'SB6-U5-P62-E2',
		audio: '',
		video: '',
		imgSize: 600,
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page62/E2/Key/answerKey.png',
		titleImage: "img/FriendsPlus/Page62/E2/title.jpg",
		titleQuestion: [{ num: '2', title: "Read the study strategy. Then copy and complete the word web with words in exercise 1. Think of more words to add to the web." }],
		questionImage: [],
		questions: [
			{
				title:
					"<p>vegetables: # </p>" +
					"<p>fruit: # </p>" +
					"<p>snack: # </p>" +
					"<p>drinks: # </p>" +
					"<p>meat: # </p>",

				answer: ["chips, salad, beans", "apple", "nuts, crisps, sweets", "water, fizzy drinks, juice", "chicken"],
				type: 'longAnwser'
			},
		]
	},

}

export default json