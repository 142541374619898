// import M1 from '../../components/Exercises/Matching/M1';
import MC2 from '../../components/ExcerciseTypes/MultipleChoice/MC2';
import S3 from '../../components/ExcerciseTypes/Select/Select3';
import UI from '../../components/ExcerciseTypes/UseIt';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';



const json = {
  1: { // Exercise num
    unit: 'Starter Unit',
    id: 'SB6-S-P10-E1',
    audio: 'img/FriendsPlus/Page10/E1/Audio/Friends Plus for Vietnam G6 SB Track 1.08.mp3',
    video: '',
    component: S3,
    exerciseKey: 'img/FriendsPlus/Page10/E1/Key/answerKey.png',
    titleImage: 'img/FriendsPlus/Page10/E1/title.png',
    titleQuestion: [{ num: '1', title: 'Read the text. Match the words in <span style="color:#33B1D6;">blue</span>  with their opposites in the box. Then listen and check.' }],
    image: 'img/FriendsPlus/Page10/E1/1.png',
    widthImg: 300,
    width: 200,
    questions: [
      {
        no: "1",
        text: "horrible",
        textLeft: 'nice:',
      },
      {
        no: "2",
        text: "new",
        textLeft: 'old:',
      },
      {
        no: "3",
        text: "fast",
        textLeft: 'slow:',
      },
      {
        no: "4",
        text: "bad",
        textLeft: 'good:',
      },
      {
        no: "5",
        text: "cheap",
        textLeft: 'expensive:',
      },
      {
        no: "6",
        text: "big",
        textLeft: 'small:',
      },
      {
        no: "7",
        text: "unpopular",
        textLeft: 'popular:',
      },
      {
        no: "8",
        text: "boring",
        textLeft: 'interesting:',
      },
    ]
  },
  2: { // Exercise num
    unit: 'Starter Unit',
    id: 'SB6-S-P10-E2',
    audio: '',
    video: '',
    component: MC2,
    exerciseKey: 'img/FriendsPlus/Page10/E2/Key/answerKey.png',
    titleQuestion: [{ num: '2', title: 'Read the text again and choose the correct answers.' }],
    titleImage_2: 'img/FriendsPlus/Page10/E2/title.png',
    imgSize: '100%',
    questionImage: [ // Row
      [
        // Column
        { url: 'http://' },
        { url: 'http://', input: true },
        { url: 'http://' },
      ],
    ],
    questions: [
      {
        title: `Has Paul's mobile got a good camera?`,
        answers: [
          { image: "", isCorrect: false, right: "", text: "Yes, it has." },
          { image: "", isCorrect: true, right: "", text: "No, it hasn't." },
        ],
        no: 1,
        question: '',
        type: '',
      },
      {
        title: `Have Ben's cousins got a parrot?`,
        answers: [
          { image: "", isCorrect: true, right: "", text: "Yes, they have." },
          { image: "", isCorrect: false, right: "", text: "No, they haven't." },
        ],
        no: 2,
        question: '',
        type: '',
      },
      {
        title: 'Has Maria got a present for her dad?',
        answers: [
          { image: "", isCorrect: true, right: "", text: "Yes, she has." },
          { image: "", isCorrect: false, right: "", text: "No, she hasn't." },

        ],
        no: 3,
        question: '',
        type: '',
      },
    ]
  },
  // 3: { // Exercise num
  unit: 'Starter Unit',
  //   audio: '',
  //   video: '',
  //   component: UI,
  //   exerciseKey : '',
  //   //titleImage: 'http://',
  //   questionImage: [ // Row
  //     [
  //       // Column
  //       { url: 'img/FriendsPlus/Page10/E3/1.jpg' },
  //     ],
  //     [
  //       // Column
  //       { url: 'img/FriendsPlus/Page10/E3/2.jpg' },
  //     ],
  //     [
  //       // Column
  //       { url: 'img/FriendsPlus/Page10/E3/3.jpg' },
  //     ],
  //     [
  //       // Column
  //       { url: 'img/FriendsPlus/Page10/E3/4.jpg' },
  //     ],
  //     [
  //       // Column
  //       { url: 'img/FriendsPlus/Page10/E3/5.jpg' },
  //     ],
  //   ],
  //   questions: [
  //     {
  //       title: 'The dictionaries are # the shelf',
  //       answer: ['table']
  //     },
  //   ]
  // },

  3: { // Exercise num
    unit: 'Starter Unit',
    id: 'SB6-S-P10-E3',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page10/E3/title.jpg",
    // titleQuestion: [{ num: '5', color: '#F88D3B', title: 'Think of three places that you like in your town or city and three places that you don’t like. Write sentences and say why you like or dislike them' }],
    questionImage: [],
    questions: [
      {
        title:
          "# # # # # # # # ",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  6: { // Exercise num
    unit: 'Starter Unit',
    id: 'SB6-S-P10-E6',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page18/E6/3.png",
    titleQuestion: '',
    questionImage: [],
    questions: [
      {
        title:
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },

  4: { // Exercise num
    unit: 'Starter Unit',
    id: 'SB6-S-P10-E4',
    audio: '',
    video: '',
    component: UI,
    exerciseKey: '',
    //titleImage: 'http://',
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page10/E4/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page10/E4/2.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
}

export default json;