import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import MC1 from '../../components/ExcerciseTypes/MultipleChoice/MC1';
import WordsBox from '../../components/ExcerciseTypes/Design/WordBox';

const json = {
  5: { // Exercise num
    unit: 'Progress Review 3',
    id: 'SB6-PV3-P83-E5',
    audio: '',
    video: '',
    component: T6,
    inputSize: 275,
    // titleImage: "img/FriendsPlus/Page80/E1/1.png",
    exerciseKey: 'img/FriendsPlus/Page83/E5/Key/answerKey.png',
    imgSize: '60%',
    titleQuestion: [{ num: '5', title: 'Write sentences using the verbs.', color: '#3B3074' }],
    questionImage: [],
    questions: [
      {
        title:

          "<p><span style='font-weight:600'>1</span> My sister # in the park. (love, play)</p>" +
          "<p><span style='font-weight:600'>2</span> Artem always # (hate, get up) at 6 a.m.</p>" +
          "<p><span style='font-weight:600'>3</span> Nuran and Osman # at home. (like, help)</p>" +
          "<p><span style='font-weight:600'>4</span> I # in the centre of town. (don’t mind, live)</p>" +
          "<p><span style='font-weight:600'>5</span> We # for the bus.(not like, run)</p>" +
          "<p><span style='font-weight:600'>6</span> For lunch, Ann # sandwiches. (prefer, eat)</p>" +
          "<p><span style='font-weight:600'>7</span> Freddie # in cold water. (not like, swim)</p>" +
          "<p><span style='font-weight:600'>8</span> I # homework.(don’t mind, do)</p>",
        answer: [
          'loves playing', 'hates getting up', "like helping", "don't mind living", "don't like running", 'prefers eating'
          , "doesn't like swimming", "don't mind doing"
        ],
        // type: 'longAnwser'
      },
    ]
  },
  6: { // Exercise num
    unit: 'Progress Review 3',
    id: 'SB6-PV3-P83-E6',
    audio: '',
    video: '',
    component: WordsBox,
    isAutofocus: true,
    fontSize: 30,
    padding: 0,
    paddingLeft: 0,
    exerciseKey: 'img/FriendsPlus/Page83/E6/Key/answerKey.png',
    titleImage: '',
    titleQuestion: [{ num: '6', title: 'Put the dialogue in the correct order. Number the sentences.', color: '#3B3074' }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page83/E6/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page83/E6/2.jpg' },
        { url: 'img/FriendsPlus/Page83/E6/3.jpg', input: true, answer: '3' },
        { url: 'img/FriendsPlus/Page83/E6/4.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page83/E6/5.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page83/E6/6.jpg' },
        { url: 'img/FriendsPlus/Page83/E6/7.jpg', input: true, answer: '8' },
        { url: 'img/FriendsPlus/Page83/E6/8.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page83/E6/9.jpg' },
        { url: 'img/FriendsPlus/Page83/E6/10.jpg', input: true, answer: '1' },
        { url: 'img/FriendsPlus/Page83/E6/11.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page83/E6/12.jpg' },
        { url: 'img/FriendsPlus/Page83/E6/13.jpg', input: true, answer: '4' },
        { url: 'img/FriendsPlus/Page83/E6/14.jpg' },
      ],
      [
        // Column15
        { url: 'img/FriendsPlus/Page83/E6/15.jpg' },
        { url: 'img/FriendsPlus/Page83/E6/16.jpg', input: true, answer: '7' },
        { url: 'img/FriendsPlus/Page83/E6/17.jpg' },
      ],
      [
        // Column18
        { url: 'img/FriendsPlus/Page83/E6/18.jpg' },
      ],
      [
        // Column19
        { url: 'img/FriendsPlus/Page83/E6/19.jpg' },
        { url: 'img/FriendsPlus/Page83/E6/20.jpg' },
        { url: 'img/FriendsPlus/Page83/E6/21.jpg' },
      ],
      [
        // Column22
        { url: 'img/FriendsPlus/Page83/E6/22.jpg' },
        { url: 'img/FriendsPlus/Page83/E6/23.jpg', input: true, answer: '9' },
        { url: 'img/FriendsPlus/Page83/E6/24.jpg' },
      ],
      [
        // Column25
        { url: 'img/FriendsPlus/Page83/E6/25.jpg' },
        { url: 'img/FriendsPlus/Page83/E6/26.jpg', input: true, answer: '2' },
        { url: 'img/FriendsPlus/Page83/E6/27.jpg' },
      ],
      [
        // Column28
        { url: 'img/FriendsPlus/Page83/E6/28.jpg' },
      ],
      [
        // Column29
        { url: 'img/FriendsPlus/Page83/E6/29.jpg' },
        { url: 'img/FriendsPlus/Page83/E6/30.jpg', input: true, answer: '5' },
        { url: 'img/FriendsPlus/Page83/E6/31.jpg' },
      ],
      [
        // Column32
        { url: 'img/FriendsPlus/Page83/E6/32.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  7: { // Exercise num
    unit: 'Progress Review 3',
    id: 'SB6-PV3-P83-E7',
    audio: '',
    video: '',
    component: MC1,
    exerciseKey: 'img/FriendsPlus/Page83/E7/Key/answerKey.png',
    titleQuestion: [{ num: '7', title: 'Choose the correct words and complete the text.', color: '#3B3074' }],
    titleImage: 'img/FriendsPlus/Page83/E7/titleImage.jpg',
    questionImage: [ // Row
      [
        // Column
        { url: 'http://' },
        { url: 'http://', input: true },
        { url: 'http://' },
      ],
    ],
    questions: [
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "Then" },
          { image: "", isCorrect: false, right: "", text: "Next" },
          { image: "", isCorrect: true, right: "", text: "First" },
        ],
        no: 1,
        question: '',
        type: '',
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "terrible" },
          { image: "", isCorrect: true, right: "", text: "tasty" },
          { image: "", isCorrect: false, right: "", text: "horrible" },
        ],
        no: 2,
        question: '',
        type: '',
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "Next" },
          { image: "", isCorrect: false, right: "", text: "First" },
          { image: "", isCorrect: true, right: "", text: "After" },
        ],
        no: 3,
        question: '',
        type: '',
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: true, right: "", text: "to make" },
          { image: "", isCorrect: false, right: "", text: "making" },
          { image: "", isCorrect: false, right: "", text: "make" },
        ],
        no: 4,
        question: '',
        type: '',
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "dull" },
          { image: "", isCorrect: true, right: "", text: "colourful" },
          { image: "", isCorrect: false, right: "", text: "ugly" },
        ],
        no: 5,
        question: '',
        type: '',
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "After" },
          { image: "", isCorrect: false, right: "", text: "First" },
          { image: "", isCorrect: true, right: "", text: "Finally" },
        ],
        no: 6,
        question: '',
        type: '',
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "any" },
          { image: "", isCorrect: true, right: "", text: "many" },
          { image: "", isCorrect: false, right: "", text: "some" },
        ],
        no: 7,
        question: '',
        type: '',
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: true, right: "", text: "Enjoy" },
          { image: "", isCorrect: false, right: "", text: "Like" },
          { image: "", isCorrect: false, right: "", text: "Have" },
        ],
        no: 8,
        question: '',
        type: '',
      },

    ],
  },
}
export default json