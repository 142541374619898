import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import url from '../../configs/url';

const json = {
  // 1: { // Exercise num
  //   unit: 'Unit 5',
  //   id: 'SB6-U5-P67-E1',
  //   audio: '',
  //   video: '',
  //   component: T1,
  //   exerciseKey: "img/FriendsPlus/Page67/E1/Key/answerKey.jpg",
  //   titleQuestion: [{ color: '#35C7DF', num: '1', title: 'Look at the examples. Then answer the questions in Rules 1–2.' }],
  //   // titleImage:"img/FriendsPlus/Page67/E1/1.jpg",
  //   imgSize: 600,
  //   questionImage: [],
  //   questions: [
  //     {
  //       title:
  //         "<p>Do you <b style='font-weight:600'>prefer sitting</b> on the sofa or <b style='font-weight:600'>going out</b>?</p>" +
  //         "<p>I <b style='font-weight:600'>like playing</b> video games, but I also <b style='font-weight:600'>like going out</b>.</p>" +
  //         "<p>I <b style='font-weight:600'>like running</b> and <b style='font-weight:600'>swimming</b>.</p>" +
  //         "</br>" +
  //         "<p>1. What form of verb comes after like, prefer, hate and don’t mind? # </p>" +
  //         "<p>2. What is the spelling of the -ing forms of the verbs run, sit and swim? # </p>",
  //       answer: [
  //         'the -ing form',
  //         'running, sitting, swimming'
  //       ],
  //       type: 'longAnwser'
  //     },
  //   ]
  // },
  1: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P67-E1',
    audio: '',
    video: url.cdnUrl + '/Animation - Unit 5 grammar 2 trang 67.mp4',
    component: D1,
    fontSize: 24,
    height: 26,
    exerciseKey: "img/FriendsPlus/Page67/E1/Key/answerKey.png",
    // titleImage: "img/FriendsPlus/Page50/E15/1.png",
    titleQuestion: [{ num: '1', title: 'Look at the examples. Then answer the questions in Rules 1–2.' }],
    questionImage: [ // Row      
      [
        // Column1
        { url: 'img/FriendsPlus/Page67/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page67/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page67/E1/3.jpg', input: true, answer: "the -ing form" },
        { url: 'img/FriendsPlus/Page67/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page67/E1/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page67/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page67/E1/7.jpg', input: true, answer: "running, sitting, swimming" },
        { url: 'img/FriendsPlus/Page67/E1/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page67/E1/9.jpg' },
      ],

    ],

    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P67-E2',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page67/E2/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P67-E4',
    audio: '',
    video: '',
    component: D1,
    // titleImage: "img/FriendsPlus/Page50/E15/1.png",
    // titleQuestion: [{ num: '5', title: 'Watch or listen again and complete the Key Phrases with examples that the students talk about.', color: '#5196C7' }],
    questionImage: [ // Row      
      [
        // Column
        { url: 'img/FriendsPlus/page67/E4/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/page67/E4/2.jpg' },
        { url: 'img/FriendsPlus/page67/E4/3.jpg', input: true, answer: '' },
        { url: 'img/FriendsPlus/page67/E4/4.jpg' },
        { url: 'img/FriendsPlus/page67/E4/5.jpg', input: true, answer: '' },
        { url: 'img/FriendsPlus/page67/E4/6.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/page67/E4/7.jpg' },
        { url: 'img/FriendsPlus/page67/E4/8.jpg', input: true, answer: '' },
        { url: 'img/FriendsPlus/page67/E4/9.jpg' },
        { url: 'img/FriendsPlus/page67/E4/10.jpg', input: true, answer: '' },
        { url: 'img/FriendsPlus/page67/E4/11.jpg' },
      ],


    ],

    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P67-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page67/E3/Key/answerKey.png',
    // titleImage: "img/FriendsPlus/Page59/E5/1.png",
    imgSize: '70%',
    titleQuestion: [{ num: '3', title: "Look at the examples of imperatives. How do we make the negative form? Find examples in the <i>Health quiz</i> on page 66." }],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='font-family:Rubik-Medium'><p><span style='font-weight:600'>Eat</span> some snacks.</p>" +
          "<p><span style='font-weight:600'>Wait</span> for dinner.</p>" +
          "<p><span style='font-weight:600'>1.</span> #.</p>" +
          "<p><span style='font-weight:600'>2.</span> #.</p>" +
          "<p><span style='font-weight:600'>3.</span>  #.</p></div>",
        answer: [
          "Don't eat",
          "Don't go to bed late",
          "Don't go to school",
        ],
        type: 'longAnwser'
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P67-E5',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page67/E5/Key/answerKey.png',
    titleImage: '',
    // titleQuestion: [{ color: '#4F3A96', num: '1', title: 'Complete the words in the text.' }],
    questionImage: [ // Row

      [
        // Column
        { url: 'img/FriendsPlus/Page67/E5/14.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page67/E5/1.jpg' },
      ],

      [
        // Column
        { url: 'img/FriendsPlus/Page67/E5/2.jpg' },
        { url: 'img/FriendsPlus/Page67/E5/3.jpg', input: true, answer: 'Eat' },
        { url: 'img/FriendsPlus/Page67/E5/4.jpg' },
        { url: 'img/FriendsPlus/Page67/E5/5.jpg', input: true, answer: 'Visit' },
        { url: 'img/FriendsPlus/Page67/E5/6.jpg' },

      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page67/E5/7.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page67/E5/8.jpg' },
        { url: 'img/FriendsPlus/Page67/E5/9.jpg', input: true, answer: `Don't drink` },
        { url: 'img/FriendsPlus/Page67/E5/10.jpg' },
        { url: 'img/FriendsPlus/Page67/E5/11.jpg', input: true, answer: 'Go' },
        { url: 'img/FriendsPlus/Page67/E5/12.jpg' },

      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page67/E5/13.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  6: {
    unit: 'Unit 5',
    id: 'SB6-U5-P67-E6',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page67/E6/Key/answerKey.png',
    component: DesignUnderLine,
    totalInput: 2,
    titleQuestion: [{ num: '6', title: `Look at the examples. Then choose the correct answers to complete the Rules.`, color: '#57B4C3' }],
    questionImage: [ // Row

      [
        { url: 'img/FriendsPlus/Page67/E6/03.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page67/E6/05.jpg' },
        { url: 'img/FriendsPlus/Page67/E6/06.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page67/E6/07.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page67/E6/08.jpg' },
        { url: 'img/FriendsPlus/Page67/E6/09.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page67/E6/10.jpg' },

      ],
      [
        { url: 'img/FriendsPlus/Page67/E6/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page67/E6/12.jpg' },
        { url: 'img/FriendsPlus/Page67/E6/13.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page67/E6/14.jpg' },
        { url: 'img/FriendsPlus/Page67/E6/15.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page67/E6/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page67/E6/17.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  7: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P67-E7',
    audio: '',
    video: '',
    component: T6,
    inputSize: 500,
    // titleImage: "img/FriendsPlus/Page59/E5/1.png",
    imgSize: '70%',
    exerciseKey: 'img/FriendsPlus/Page67/E7/Key/answerKey.png',
    titleQuestion: [{ num: '7', title: "Your friend wants to be healthy. Complete the advice using <i>should / shouldn’t</i>." }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1.</span> stay up / late</p>" +
          "<p style='font-family:OneStrokeScriptStd-Bold;color:gray'>You shouldn’t stay up late.</p>" +
          "<p><span style='font-weight:600'>2.</span> eat / fruit and vegetables #.</p>" +
          "<p><span style='font-weight:600'>3.</span> do/exercise #.</p>" +
          "<p><span style='font-weight:600'>4.</span> drink / fizzy drinks #.</p>" +
          "<p><span style='font-weight:600'>5.</span> sit / on the sofa all day #.</p>",
        answer: [
          "You should eat fruit and vegetables",
          "You should do exercise",
          "You shouldn't drink fizzy drinks",
          "You shouldn't sit on the sofa all day",
        ],
        type: 'longAnwser'
      },
    ]
  },
  8: { // Exercise num
    unit: 'Unit 5',
    id: 'SB6-U5-P67-E8',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page67/E8/1.png",
    // titleQuestion: [{ color: '#35C7DF', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json