import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import WordsFind from '../../components/ExcerciseTypes/WordsFind';


const json = {
    1: { // Exercise num
        unit: 'Unit 4',
        id: 'SB6-U4-P57-E1',
        audio: '',
        video: '',
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page57/E1/Key/1.png',
        // titleImage: "img/FriendsPlus/Page50/E15/1.png",
        titleQuestion: [{ num: '1', title: 'Use the code to write the school subjects. Then put the subjects in order from your favourite (1) to your least favourite (9). ', color: '#222223' }],
        questionImage: [ // Row
            [
                // Column
                { url: 'img/FriendsPlus/Page57/E1/0.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page57/E1/1.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page57/E1/2.jpg' },
                { url: 'img/FriendsPlus/Page57/E1/3.jpg' },
                { url: 'img/FriendsPlus/Page57/E1/4.jpg' },
                { url: 'img/FriendsPlus/Page57/E1/5.jpg', input: true, answer: 'ICT' },
                { url: 'img/FriendsPlus/Page57/E1/6.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page57/E1/7.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page57/E1/8.jpg' },
                { url: 'img/FriendsPlus/Page57/E1/9.jpg', input: true, answer: 'science' },
                { url: 'img/FriendsPlus/Page57/E1/10.jpg' },
                { url: 'img/FriendsPlus/Page57/E1/11.jpg', input: true, answer: 'geography' },
                { url: 'img/FriendsPlus/Page57/E1/12.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page57/E1/13.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page57/E1/14.jpg' },
                { url: 'img/FriendsPlus/Page57/E1/15.jpg', input: true, answer: 'PE' },
                { url: 'img/FriendsPlus/Page57/E1/16.jpg' },
                { url: 'img/FriendsPlus/Page57/E1/17.jpg', input: true, answer: 'history' },
                { url: 'img/FriendsPlus/Page57/E1/18.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page57/E1/19.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page57/E1/20.jpg' },
                { url: 'img/FriendsPlus/Page57/E1/21.jpg', input: true, answer: 'English' },
                { url: 'img/FriendsPlus/Page57/E1/22.jpg' },
                { url: 'img/FriendsPlus/Page57/E1/23.jpg', input: true, answer: 'Spanish' },
                { url: 'img/FriendsPlus/Page57/E1/24.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page57/E1/25.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page57/E1/26.jpg' },
                { url: 'img/FriendsPlus/Page57/E1/27.jpg', input: true, answer: 'art' },
                { url: 'img/FriendsPlus/Page57/E1/28.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page57/E1/29.jpg' },
            ],
        ],

        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },
    2: { // Exercise num
        unit: 'Unit 4',
        id: 'SB6-U4-P57-E2',
        audio: '',
        video: '',
        component: T2,
        titleImage: "img/FriendsPlus/Page57/E2/1.jpg",
        // titleQuestion: [{ num: '1', title: 'Check the meanings of the words in the box. Then describe the photo using the words.', color: '#009241' }],
        questionImage: [],
        questions: [
            {
                title:
                    "<p>#</p>" +
                    "<p>#</p>" +
                    "<p>#</p>",

                answer: [],
                type: 'longAnwser'
            },
        ]
    },
    3: { // Exercise num
        unit: 'Unit 4',
        id: 'SB6-U4-P57-E3',
        audio: '',
        video: '',
        fontSize: 18,
        component: D1,
        exerciseKey: 'img/FriendsPlus/Page57/E3/Key/answerKey.png',
        // titleImage: "img/FriendsPlus/Page50/E15/1.png",
        // titleQuestion: [{ num: '5', title: 'Watch or listen again and complete the Key Phrases with examples that the students talk about.', color: '#5196C7' }],
        questionImage: [ // Row
            [
                // Column
                { url: 'img/FriendsPlus/Page57/E3/1.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page57/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page57/E3/3.jpg', input: true, answer: 'Sally' },
                { url: 'img/FriendsPlus/Page57/E3/4.jpg' },
                { url: 'img/FriendsPlus/Page57/E3/5.jpg', input: true, answer: 'Emma' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page57/E3/6.jpg' },
                { url: 'img/FriendsPlus/Page57/E3/7.jpg', input: true, answer: 'Becky' },
                { url: 'img/FriendsPlus/Page57/E3/8.jpg' },
                { url: 'img/FriendsPlus/Page57/E3/9.jpg', input: true, answer: 'John' },
                { url: 'img/FriendsPlus/Page57/E3/10.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page57/E3/11.jpg' },
                { url: 'img/FriendsPlus/Page57/E3/12.jpg', input: true, answer: 'Joe' },
                { url: 'img/FriendsPlus/Page57/E3/13.jpg' },
                { url: 'img/FriendsPlus/Page57/E3/14.jpg', input: true, answer: 'Dan' },
                { url: 'img/FriendsPlus/Page57/E3/15.jpg' },
            ],
            [
                // Column
                { url: 'img/FriendsPlus/Page57/E3/16.jpg' },
            ],


        ],

        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },

    // 4: { // Exercise num
    //     unit: 'Unit 4',
    //     id: 'SB6-U4-P57-E4',
    //     audio: '',
    //     video: '',
    //     component: WordsFind,
    //     // exerciseKey: 'img/FriendsPlus/Page6/E1/key.png',
    //     titleImage: 'img/FriendsPlus/Page57/E4/title.jpg',
    //     // titleQuestion: [{ num: '1', title: "Find twelve more words in the wordsearch.", star: 1, color: '#5C97A9' }],
    //     questionImage: [],
    //     questions: [
    //         {
    //             title: 'positive',
    //             answerInputs: ['revise', 'check', 'learn', 'spell',]
    //         },
    //         {
    //             title: 'negative',
    //             answerInputs: ['understand', 'know', 'practise', 'repeat',]
    //         }
    //     ]
    // },
    4: { // Exercise num
        unit: 'Unit 4',
        id: 'SB6-U4-P57-E4',
        audio: '',
        video: '',
        component: WordsFind,
        exerciseKey: 'img/FriendsPlus/Page57/E4/Key/answerKey.png',
        titleImage: 'img/FriendsPlus/Page57/E4/title.jpg',
        // titleQuestion: [{ num: '1', title: "Find twelve more words in the wordsearch.", star: 1, color: '#5C97A9' }],
        questionImage: [],
        array: [
            ["S", "T", "R", "E", "V", "I", "S", "E", "L", "U",],
            ["P", "K", "L", "N", "U", "F", "Y", "R", "O", "N",],
            ["R", "N", "O", "C", "H", "E", "C", "K", "T", "D",],
            ["A", "O", "D", "T", "A", "S", "W", "O", "H", "E",],
            ["C", "W", "R", "E", "L", "I", "J", "R", "W", "R",],
            ["T", "R", "E", "D", "R", "S", "I", "P", "G", "S",],
            ["I", "S", "P", "F", "O", "G", "U", "R", "I", "T",],
            ["S", "P", "E", "L", "L", "D", "B", "H", "R", "A",],
            ["E", "J", "A", "I", "A", "L", "E", "A", "R", "N",],
            ["M", "I", "T", "G", "L", "A", "H", "O", "N", "D",]
        ],
        initialValues: [
            { id: '7-0', isCorrect: true },
            { id: '7-1', isCorrect: true },
            { id: '7-2', isCorrect: true },
            { id: '7-3', isCorrect: true },
            { id: '7-4', isCorrect: true },

        ],
        questions: [
            {
                title: 'positive',
                answerInputs: ['revise', 'check', 'learn', 'spell',]
            },
            {
                title: 'negative',
                answerInputs: ['understand', 'know', 'practise', 'repeat',]
            }
        ]
    },

    5: { // Exercise num
        unit: 'Unit 4',
        id: 'SB6-U4-P57-E5',
        audio: '',
        video: '',
        component: T2,
        titleImage: "img/FriendsPlus/Page57/E5/1.jpg",
        // titleQuestion: [{ num: '1', title: 'Check the meanings of the words in the box. Then describe the photo using the words.', color: '#009241' }],
        questionImage: [],
        questions: [
            {
                title:
                    "<p>#</p>" +
                    "<p>#</p>" +
                    "<p>#</p>",

                answer: [],
                type: 'longAnwser'
            },
        ]
    },

}
export default json