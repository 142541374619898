import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P45-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page45/E1/Key/1.png',
    titleImage: "img/FriendsPlus/Page45/E1/title.jpg",
    imgSize: '150%',
    titleQuestion: [{ color: '#009241', num: '1', title: 'Check the meanings of the words in the box. Which of these do you think is a problem for the Philippine eagle? Read the information leaflet and check.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1. # </p>",
        answer: ['farming'],
        type: 'longAnwser'
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P45-E2',
    audio: '',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page45/E2/Key/1.png',
    titleImage: "img/FriendsPlus/Page45/E1/title.jpg",
    titleQuestion: [{ color: '#009241', num: '2', title: 'Read the text again. Why is the Philippine eagle special? What kinds of animals does it eat?' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1.Why is the Philippine eagle special? #. </p>" +
          "<p><span style='font-weight:600'>2.What kinds of animals does it eat? #. </p>",
        answer: [
          "It's one of the biggest birds in the world, and it's in danger and very rare",
          "It eats snakes and small animals such as bats, rats and small monkeys"
        ],
        type: 'longAnwser'
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P45-E3',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page45/E3/Key/answerKey.png',
    // typeInput:'center',
    titleImage: 'img/FriendsPlus/Page45/E3/title.png',
    // titleQuestion: [{ num: '3', title: "Complete the Key Phrases with words from the text."}],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page45/E3/18.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page45/E3/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page45/E3/2.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page45/E3/3.jpg' },
        { url: 'img/FriendsPlus/Page45/E3/4.jpg', input: true, answer: 'eight kilos/one metre long' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page45/E3/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page45/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page45/E3/7.jpg', input: true, answer: 'small animals' },
        { url: 'img/FriendsPlus/Page45/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page45/E3/9.jpg', input: true, answer: 'snakes, bats and rats' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page45/E3/10.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page45/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page45/E3/12.jpg', input: true, answer: 'in danger' },
        { url: 'img/FriendsPlus/Page45/E3/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page45/E3/14.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page45/E3/15.jpg' },
        { url: 'img/FriendsPlus/Page45/E3/16.jpg', input: true, answer: 'on the website' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page45/E3/17.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P45-E4',
    audio: '',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page45/E4/Key/1.png',
    titleImage: "img/FriendsPlus/Page45/E1/1.png",
    titleQuestion: [{ color: '#009241', num: '4', title: ' Look at the phrases in <span style="color:#33B1D6;">blue</span> in the text. Which can we use at the start of a sentence? Which has a comma (,) after it?' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1.Which can we use at the start of a sentence? # </p>" +
          "<p><span style='font-weight:600'>2.Which has a comma (,) after it? # </p>",
        answer: [
          "We can use For example at the start of a sentence.",
          "For example has a comma after it."
        ],
        type: 'longAnwser'
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P45-E5',
    audio: '',
    video: '',
    component: T6,
    inputSize: 450,
    exerciseWidth: '50%',
    imgSize: '100%',
    titleImage: "img/FriendsPlus/Page45/E5/title.jpg",
    // titleQuestion: [{ color: '#009241', num: '5', title: 'Complete the sentences. Use such as, like, For example and your own ideas.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='margin-left:30px'><p><span style='font-weight:600'>1</span> I like big animals # </p>" +
          "<p><span style='font-weight:600'>2</span> In my country you can visit beautiful places # </p>" +
          "<p><span style='font-weight:600'>3</span> You can help some animals in danger # ,</p>" +
          "<p>you can # or # </p>",
        answer: [],
        // answer: ['', '', '', '', ''],
        type: 'longAnwser'
      },
    ]
  },
  6: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P45-E6',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page45/E6/1.png",
    // titleQuestion: [{ color: '#BF215C', num: '1', title: 'Look at the photo. What is the situation?.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json