import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import WordsBox from '../../components/ExcerciseTypes/Design/WordBox';
import MC2 from '../../components/ExcerciseTypes/MultipleChoice/MC2';

const json = {

  1: { // Exercise num
    unit: 'Progress Review 1',
    id: 'SB6-PV-P34-E1',
    audio: '',
    video: '',
    component: WordsBox,
    isAutofocus: true,
    fontSize: 29,
    height: 32,
    paddingLeft: 8,
    typeInput: true,
    exerciseKey: 'img/FriendsPlus/Page34/E1/Key/answerKey.png',
    titleImage: '',
    // titleQuestion: [{ num: '1', title: 'Complete the words in the text.', color: '#3B3074' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page34/E1/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page34/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page34/E1/3.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page34/E1/4.jpg', input: true, answer: 's' },
        { url: 'img/FriendsPlus/Page34/E1/5.jpg', input: true, answer: 't' },
        { url: 'img/FriendsPlus/Page34/E1/6.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page34/E1/7.jpg', input: true, answer: 'u' },
        { url: 'img/FriendsPlus/Page34/E1/8.jpg', input: true, answer: 'r' },
        { url: 'img/FriendsPlus/Page34/E1/9.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page34/E1/10.jpg', input: true, answer: 'n' },
        { url: 'img/FriendsPlus/Page34/E1/11.jpg', input: true, answer: 't' },
        { url: 'img/FriendsPlus/Page34/E1/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page34/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page34/E1/14.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page34/E1/15.jpg', input: true, answer: 'f' },
        { url: 'img/FriendsPlus/Page34/E1/16.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page34/E1/17.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page34/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page34/E1/19.jpg', input: true, answer: 'p' },
        { url: 'img/FriendsPlus/Page34/E1/20.jpg', input: true, answer: 'o' },
        { url: 'img/FriendsPlus/Page34/E1/21.jpg', input: true, answer: 'r' },
        { url: 'img/FriendsPlus/Page34/E1/22.jpg', input: true, answer: 't' },
        { url: 'img/FriendsPlus/Page34/E1/23.jpg', input: true, answer: 's' },
        { url: 'img/FriendsPlus/Page34/E1/24.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page34/E1/25.jpg' },
        { url: 'img/FriendsPlus/Page34/E1/26.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page34/E1/27.jpg', input: true, answer: 'n' },
        { url: 'img/FriendsPlus/Page34/E1/28.jpg', input: true, answer: 't' },
        { url: 'img/FriendsPlus/Page34/E1/29.jpg', input: true, answer: 'r' },
        { url: 'img/FriendsPlus/Page34/E1/30.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page34/E1/31.jpg' },
        { url: 'img/FriendsPlus/Page34/E1/32.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page34/E1/33.jpg', input: true, answer: 'r' },
        { url: 'img/FriendsPlus/Page34/E1/34.jpg', input: true, answer: 'k' },
        { url: 'img/FriendsPlus/Page34/E1/35.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page34/E1/36.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page34/E1/37.jpg' },
        { url: 'img/FriendsPlus/Page34/E1/38.jpg', input: true, answer: 'o' },
        { url: 'img/FriendsPlus/Page34/E1/39.jpg', input: true, answer: 't' },
        { url: 'img/FriendsPlus/Page34/E1/40.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page34/E1/41.jpg', input: true, answer: 'l' },
        { url: 'img/FriendsPlus/Page34/E1/42.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page34/E1/43.jpg' },
        { url: 'img/FriendsPlus/Page34/E1/44.jpg', input: true, answer: 'i' },
        { url: 'img/FriendsPlus/Page34/E1/45.jpg', input: true, answer: 'n' },
        { url: 'img/FriendsPlus/Page34/E1/46.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page34/E1/47.jpg', input: true, answer: 'm' },
        { url: 'img/FriendsPlus/Page34/E1/48.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page34/E1/49.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page34/E1/50.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page34/E1/51.jpg' },
        { url: 'img/FriendsPlus/Page34/E1/52.jpg', input: true, answer: 'h' },
        { url: 'img/FriendsPlus/Page34/E1/53.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page34/E1/54.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page34/E1/55.jpg', input: true, answer: 't' },
        { url: 'img/FriendsPlus/Page34/E1/56.jpg', input: true, answer: 'r' },
        { url: 'img/FriendsPlus/Page34/E1/57.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page34/E1/58.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page34/E1/59.jpg' },
        { url: 'img/FriendsPlus/Page34/E1/60.jpg', input: true, answer: 'c' },
        { url: 'img/FriendsPlus/Page34/E1/61.jpg', input: true, answer: 'h' },
        { url: 'img/FriendsPlus/Page34/E1/62.jpg', input: true, answer: 'o' },
        { url: 'img/FriendsPlus/Page34/E1/63.jpg', input: true, answer: 'o' },
        { url: 'img/FriendsPlus/Page34/E1/64.jpg', input: true, answer: 'l' },
        { url: 'img/FriendsPlus/Page34/E1/65.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page34/E1/66.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit: 'Progress Review 1',
    id: 'SB6-PV-P34-E2',
    audio: '',
    video: '',
    component: MC2,
    exerciseKey: 'img/FriendsPlus/Page34/E2/Key/answerKey.png',
    titleQuestion: [{ num: '2', title: 'Choose the correct answers.', color: '#3B3074' }],
    //titleImage: 'http://',
    questionImage: [ // Row
      [
        // Column
        { url: 'http://' },
        { url: 'http://', input: true },
        { url: 'http://' },
      ],
    ],
    questions: [
      {
        title: 'The new ship has got a ....... with a lot of books.',
        answers: [
          { image: "", isCorrect: false, right: "", text: "cabin" },
          { image: "", isCorrect: true, right: "", text: "library" },
          { image: "", isCorrect: false, right: "", text: "swimming pool" },
        ],
        no: 1,
        question: '',
        type: '',
      },
      {
        title: 'The ....... on the ship prepare very good food.',
        answers: [
          { image: "", isCorrect: true, right: "", text: "chefs" },
          { image: "", isCorrect: false, right: "", text: "passengers" },
          { image: "", isCorrect: false, right: "", text: "tourists" },
        ],
        no: 2,
        question: '',
        type: '',
      },
      {
        title: 'The sports centre has got a big climbing wall. It’s very ....... – I like it!',
        answers: [
          { image: "", isCorrect: false, right: "", text: "comfortable" },
          { image: "", isCorrect: false, right: "", text: "horrible" },
          { image: "", isCorrect: true, right: "", text: "exciting" },
        ],
        no: 3,
        question: '',
        type: '',
      },
      {
        title: 'The passengers all have got rooms called .......',
        answers: [
          { image: "", isCorrect: true, right: "", text: "cabins" },
          { image: "", isCorrect: false, right: "", text: "shops" },
          { image: "", isCorrect: false, right: "", text: "swimming pools" },
        ],
        no: 4,
        question: '',
        type: '',
      },
      {
        title: `The people on the ship like the food. It’s .......!`,
        answers: [
          { image: "", isCorrect: false, right: "", text: "horrible" },
          { image: "", isCorrect: false, right: "", text: "comfortable" },
          { image: "", isCorrect: true, right: "", text: "fantastic" },
        ],
        no: 5,
        question: '',
        type: '',
      },
      {
        title: `‘Is there a sports centre?’ ‘No, but there are five
            nice ........’`,
        answers: [
          { image: "", isCorrect: true, right: "", text: "swimming pools" },
          { image: "", isCorrect: false, right: "", text: "cabins" },
          { image: "", isCorrect: false, right: "", text: "passengers" },
        ],
        no: 6,
        question: '',
        type: '',
      },
    ]
  },
  3: { // Exercise num
    unit: 'Progress Review 1',
    id: 'SB6-PV-P34-E3',
    audio: '',
    video: '',
    component: T1,

    inpuSize: 600,
    // titleImage: "img/FriendsPlus/Page32/E3/1.png",
    exerciseKey: 'img/FriendsPlus/Page34/E3/Key/1.png',
    titleQuestion: [{ color: '#3B3074', num: '3', title: 'Write questions and short answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='margin-left:35px'><p><span style='font-weight:600'>1</span> a big cinema in your area? / no #. </p>" +
          "<p><span style='font-weight:600'>2</span> any interesting monuments in this town? / yes #. </p>" +
          "<p><span style='font-weight:600'>3</span> a quiet park near the station? / yes  #. </p>" +
          "<p><span style='font-weight:600'>4</span> any small shops in that street? / no #. </p>" +
          "<p><span style='font-weight:600'>5</span> how many cafés near here? / three  #. </p>" +
          "<p><span style='font-weight:600'>6</span> how many students in Anna’s class? / thirty #. </p></div>",
        answer: [
          "Is there a big cinema in your area? No, there isn't",
          'Are there any interesting monuments in this town? Yes, there are',
          'Is there a quiet park near the station? Yes, there is',
          "Are there any small shops in that street? No, there aren't",
          'How many cafés are there near here? There are three',
          "How many students are there in Anna's class? There are thirty"
        ],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    unit: 'Progress Review 1',
    id: 'SB6-PV-P34-E4',
    audio: 'img/FriendsPlus/Page34/E4/Audio/G6 SB Track 1.27.mp3',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page34/E4/Key/answerKey.png',
    titleImage: '',
    titleQuestion: [{ color: '#4F3A96', num: '4', title: 'Lucy is a new student at a school. Listen to Lucy talking to her teacher. Match the places in A with the adjectives in B.' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page34/E4/01.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page34/E4/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page34/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page34/E4/3.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page34/E4/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page34/E4/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page34/E4/6.jpg' },
        { url: 'img/FriendsPlus/Page34/E4/7.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page34/E4/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page34/E4/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page34/E4/10.jpg' },
        { url: 'img/FriendsPlus/Page34/E4/11.jpg', input: true, answer: 'f' },
        { url: 'img/FriendsPlus/Page34/E4/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page34/E4/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page34/E4/14.jpg' },
        { url: 'img/FriendsPlus/Page34/E4/15.jpg', input: true, answer: 'b' },
        { url: 'img/FriendsPlus/Page34/E4/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page34/E4/17.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page34/E4/18.jpg' },
        { url: 'img/FriendsPlus/Page34/E4/19.jpg', input: true, answer: 'c' },
        { url: 'img/FriendsPlus/Page34/E4/20.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page34/E4/21.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page34/E4/22.jpg' },
        { url: 'img/FriendsPlus/Page34/E4/23.jpg', input: true, answer: 'd' },
        { url: 'img/FriendsPlus/Page34/E4/24.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page34/E4/25.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
}
export default json