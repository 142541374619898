import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { Col, Row, Container } from 'reactstrap';
// import { CheckCircleOutlined } from '@ant-design/icons';

import FooterModal from '../../../FooterModal'
import TitleQuestion from '../../../TitleQuestion';
import classNames from 'classnames';

import styles from './styles.module.css';
import moment from 'moment';
import 'moment/locale/vi';

moment.locale('vi');
// const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const alphabet = 'abcdefghijklmnopqrstuvwxyz';


const Multiple1 = (question) => {

  const inputCount = React.useRef(0);

  const [state, setState] = React.useState({
    questions: [],
    booleanArray: [],
    studentAnswer: null,
    isPointed: false,
    isDisabledSubmit: true,
    isDisabledRetry: true,
    videoVisible: false,
    inputList: [],
    isDone_select: false
  });

  React.useEffect(() => {
    setState((prevState) => ({ ...prevState, questions: JSON.parse(JSON.stringify(question.questions)) }))
  }, [question]);

  const onSubmit = React.useCallback(() => {
    const arraySelect = state.questions.map(x => x.selectedItem);
    const listAnswers = state.questions.map(a => a.answers);

    const Inputs = state.inputList;
    const listtitleAnswer = state.questions.map(t => t.titleAnswer);

    let booleanArray = [];
    let studentAnswer = [];
    //check select
    listAnswers.forEach((item, i) => {
      const Answer_select = item[arraySelect[i]];
      booleanArray.push(Answer_select.isCorrect);
      studentAnswer.push(Answer_select.text);
    });
    //check input
    listtitleAnswer.forEach((item, i) => {
      const isCorrect = item === Inputs[i]
      booleanArray.push(isCorrect);
      studentAnswer.push(Inputs[i]);
    });
    //
    let correctAnswer = 0;
    booleanArray.forEach((item, index) => {
      if (item) {
        correctAnswer++
      }
    })
    const percent = parseInt(correctAnswer / booleanArray.length * 100)
    // const resultString = `${correctAnswer}/${booleanArray.length} (${percent}%)`
    const result_String = `${correctAnswer}/${booleanArray.length}`

    const params = {
      score: percent,
      unit: question.unit,
      results: result_String,
      exerciseId: question.id,
      sreatedDate: moment().format(),
      studentAnswer: JSON.stringify(studentAnswer),
    }
    question.postAnswerToApi(params)

    setState((prevState) => ({ ...prevState, booleanArray, studentAnswer, isPointed: true, isDisabledSubmit: true, isDisabledRetry: false }))
  }, [question, state.inputList, state.questions]);

  const onRetry = React.useCallback(() => {
    // clear input
    const arr_input = document.getElementsByTagName('INPUT');
    for (const input of arr_input) {
      input.value = null;
    }
    setState((prevState) => ({ ...prevState, isDone_select: false, isPointed: false, isDisabledRetry: true, inputList: [], questions: JSON.parse(JSON.stringify(question.questions)) }))
  }, [question.questions]);

  const onClickAnswer = React.useCallback((item, index) => {
    item.selectedItem = index;

    setState((prevState) => {
      const count = prevState.questions.reduce((total, countItem) => {
        if (countItem.selectedItem >= 0) {
          return total + 1;
        }
        return total;
      }, 0);

      const isDone_select = count === prevState.questions.length;

      if (isDone_select && state.isDisabledSubmit && state.inputList.length > 0) {
        setState((prevState) => ({ ...prevState, isDisabledSubmit: false }))
      }

      return { ...prevState, isDone_select, questions: prevState.questions };
    })
  }, [state.inputList.length, state.isDisabledSubmit]);

  const onHandleInput = (e, item, index) => {
    setState((prevState) => {
      const { inputList, isDone_select } = prevState;

      while (inputList.length < index + 1) {
        inputList.push(null)
      }
      inputList[index] = (e.target.value).toString()
      // if (typeof inputList[index] === 'string') {
      //   inputList[index] = (e.target.value).toString()
      // } else {
      //   inputList.push((e.target.value).toString())
      // }

      if (isDone_select) {
        return ({ ...prevState, inputList, isDisabledSubmit: false })
      }
      return ({ ...prevState, inputList })
    })

  }
  const renderTitle = React.useCallback((item) => {
    const titleSplit = item.title?.split(' ');
    if (!titleSplit) return null
    return titleSplit.map((itemTitle, index) => {
      let isCorrect = false;
      // let color = 'black';

      const inputIndex = inputCount.current;
      if (itemTitle === '#') {
        isCorrect = state.inputList[inputIndex] === item.titleAnswer;
        inputCount.current++;
      }

      if (inputCount.current > state.questions.length - 1) {
        inputCount.current = 0;
      }

      return itemTitle === '#' ? (
        <span key={index} style={{ width: 300 }}>
          <input type="text" disabled={state.isPointed} onChange={(e) => onHandleInput(e, item, inputIndex)} size="10" style={{ border: 'none', borderBottom: '1px solid black', padding: 0, cursor: state.isPointed && 'not-allowed', paddingLeft: 5, width: 170 }} />
          {!state.isPointed && (
            <i
              className={classNames({
                "fas fa-check": isCorrect,
                "fas fa-times": !isCorrect
              })}
              style={{
                color: 'white',
                marginLeft: 3,
                fontSize: 20,
              }}
            />
          )}
          {state.isPointed && (<i
            className={classNames({
              "fas fa-check": isCorrect,
              "fas fa-times": !isCorrect
            })}
            style={{
              color: isCorrect ? '#2ecc71' : '#E74C3C',
              marginLeft: 3,
              fontSize: 20,
            }}
          />)}
        </span>
      ) : (
        <span key={index} style={{ color: 'black', width: 300 }}>{' '}{itemTitle}{' '}</span>
      )
    })
  }, [state.inputList, state.isPointed, state.questions]);

  const renderAnswerItem = React.useCallback((qItem) => (answer, answerIndex) => {
    const isSelected = qItem.selectedItem === answerIndex;
    // Check answers
    let isCorrect = false;
    let color = 'black';

    if (state.isPointed) {
      isCorrect = answer.isCorrect;
      color = isSelected ? isCorrect ? '#2ecc71' : '#E74C3C' : 'white';
    }

    const customStyles = {
      alphabet: {
        fontSize: 25,
        marginRight: 8,
        color: isSelected ? 'white' : 'black',
        background: isSelected ? isCorrect ? '#2ecc71' : '#E74C3C' : 'white',
      },
    }

    return state.isPointed ? (
      <Button key={answerIndex} type='text' className={`${styles.answerButton} flex flex-1 ml-4`} style={{ paddingLeft: 20, paddingRight: 20, display: 'flex', alignItems: 'center', marginTop: 10, alignSelf: 'center' }}>
        <Row style={{ marginLeft: 4, fontSize: 25, display: 'flex', alignItems: 'center' }}>
          <strong className={styles.mutilpleKey} style={customStyles.alphabet}>{alphabet[answerIndex]}.</strong>
          <span style={{ display: 'block', justifyContent: 'center', alignItems: 'center' }}>{answer.text}</span>
          <i
            className={classNames({
              "fas fa-check": isCorrect,
              "fas fa-times": !isCorrect
            })}
            style={{
              color,
              marginLeft: 3,
              fontSize: 20,
            }}
          />
        </Row>
      </Button>
    ) : (
      <Button
        type='text'
        key={answerIndex}
        className={`${styles.answerButton} flex flex-1 ml-4`}
        style={{ paddingLeft: 20, paddingRight: 20, display: 'flex', alignItems: 'center', marginTop: 10, alignSelf: 'center' }}
        onClick={() => onClickAnswer(qItem, answerIndex)}
      >
        <Row style={{ marginLeft: 4, fontSize: 25, display: 'flex', alignItems: 'center' }}>
          {answer.text !== '' &&
            (< span className={isSelected ? styles.mutilpleKeySelected : styles.mutilpleKey}>
              <strong style={{ fontSize: 25 }}>{alphabet[answerIndex]}.</strong>
            </span>)
          }
          <span style={{ display: 'block', justifyContent: 'center', alignItems: 'center' }}>{answer.text}</span>
        </Row>
      </Button >
    );
  }, [state.isPointed, onClickAnswer])

  const renderQuestion = React.useCallback((item, index) => {
    return (
      <div key={index} style={{ fontSize: 25, marginLeft: question.marginLeft }} className='mb-3'>
        <b style={{ color: 'black' }}>{index + 1}</b> {renderTitle(item)}
        {item.title1.length !== 0 && (
          <Row style={{ fontSize: 25 }}>
            <span style={{ color: 'black', marginLeft: 35, marginTop: 10 }}>{item.title1}</span>
          </Row>
        )}
        <Row style={{ fontSize: 25 }}>
          {item.answers.map(renderAnswerItem(item, index))}
        </Row>
      </div>
    );
  }, [question.marginLeft, renderTitle, renderAnswerItem]);


  if (!state.questions) return null;

  return (
    <Container className='fluid' style={{ backgroundColor: '#fff', display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center' }}>
      <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 200 }}>
        <TitleQuestion titleQuestion={question.titleQuestion} />
        {/* <img src={question.titleImageQuestion} alt='...' style={{ width: '80%' }} /> */}
        {question.titleImageQuestion ? <img src={question.titleImageQuestion} alt='...' style={{ width: '80%' }} /> : ''}
        {/* <img src={question.titleImage} alt='...' style={{ width: question.width }} /> */}
        {question.titleImageQuestion ? <img src={question.titleImage} alt='...' style={{ width: question.width }} /> : ''}

      </Row>

      <Row className='d-flex justify-content-center'>
        <Col className='d-initial justify-content-center'>
          <span style={{ fontSize: 25 }}>
            {state.questions.map(renderQuestion)}
          </span>
        </Col>
      </Row>
      <Row >
        <FooterModal
          exerciseKey={question.exerciseKey}
          result={state.booleanArray}
          isDisabledSubmit={state.isDisabledSubmit}
          isDisabledRetry={state.isDisabledRetry} onSubmit={onSubmit}
          onRetry={onRetry}
        />
      </Row>
    </Container>
  );
};

Multiple1.propTypes = {
  question: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object),
  sessionId: PropTypes.string,
  classId: PropTypes.string,
};

export default Multiple1;
