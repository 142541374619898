import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import LT1 from '../../components/ExcerciseTypes/LineTo/LT1'
import T3 from '../../components/ExcerciseTypes/TypeIn/T3';

const json = {
  1: { // Exercise num
    unit : 'Option Song 7',
    id : 'SB6-OPS7-P121-E1',
    audio: 'img/FriendsPlus/Page121/E1/Audio/G6 SB Track 3.24.mp3',
    video: '',
    component: T6,
    titleImage: 'img/FriendsPlus/Page121/E1/1.png',
    imgSize: '70%',
    // inputSize: 300,
    exerciseKey: 'img/FriendsPlus/Page121/E1/Key/answerKey.png',
    titleQuestion: [{ color: '#BF2A32', num: '1', title: 'Look at the picture and listen to the song. Which boy is in the song?' }],
    questionImage: [

    ],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> Which boy is in the song? #  </p>",
        answer: [
          'Boy 1',
        ],
        type: 'longAnwser'

      },
    ]
  },
  2: { // Exercise num
    unit : 'Option Song 7',
    id : 'SB6-OPS7-P121-E2',
    audio: 'img/FriendsPlus/Page121/E1/Audio/G6 SB Track 3.24.mp3',
    video: '',
    component: T3,
    titleImage: 'img/FriendsPlus/Page121/E2/1.png',
    imgSize: '60%',
    // inputSize: 300,
    exerciseKey: 'img/FriendsPlus/Page121/E2/Key/answerKey.png',
    titleQuestion: [{ color: '#BF2A32', num: '2', title: 'Look at the song and correct the words in blue. Then listen again and check.' }],
    questionImage: [

    ],
    questions: [
      {
        title:
          "<p style='font-weight:600'>1 #  </p>" +
          "<p style='font-weight:600'>2 #  </p>" +
          "<p style='font-weight:600'>3 #  </p>" +
          "<p style='font-weight:600'>4 #  </p>" +
          "<p style='font-weight:600'>5 #  </p>" +
          "<p style='font-weight:600'>6 #  </p>" +
          "<p style='font-weight:600'>7 #  </p>" +
          "<p style='font-weight:600'>8 #  </p>" +
          "<p style='font-weight:600'>9 #  </p>" +
          "<p style='font-weight:600'>10 #  </p>",
        answer: [
          "on holiday", 'city', 'went', 'tall', 'moustache', 'left', 'be', 'got', 'travel', 'wanted'

        ],
        // type: 'longAnwser'

      },
    ]
  },

  3: { // Exercise num
    unit : 'Option Song 7',
    // audio: 'img/FriendsPlus/Page118/E3/Audio/Friends Plus for Vietnam G6 SB Track 3.21.mp3',
    id : 'SB6-OPS7-P121-E3',
    video: '',
    component: LT1,
    exerciseKey: 'img/FriendsPlus/Page121/E3/Key/answerKey.png',
    // titleImage: 'img/FriendsPlus/Page118/E3/title.jpg',
    titleQuestion: [{ num: '3', title: ' Match phrases 1–5 with phrases a–e to make questions.', color: '#db323d' }],
    questionImage: [],
    questions: [
      {
        right: [
          {
            id: 1,
            text: "1 Where did you",
          },
          {
            id: 2,
            text: "2 What did you look",
          },
          {
            id: 3,
            text: "3 Who was your best",
          },
          {
            id: 4,
            text: "4 How did you",
          },
          {
            id: 5,
            text: "5 When did you start",
          },
        ],
        left: [
          {
            id: 1,
            text: "a. friend when you were nine?",
          },
          {
            id: 2,
            text: "b. come to school this morning?",
          },
          {
            id: 3,
            text: "c. like when you were seven?",
          },
          {
            id: 4,
            text: "d. live when you were young?",
          },
          {
            id: 5,
            text: "e. learning English?",
          },
        ],
        answer: [
          {
            right: 1,
            left: 4,
          },
          {
            right: 2,
            left: 3,
          },
          {
            right: 3,
            left: 1,
          },
          {
            right: 4,
            left: 2,
          },
          {
            right: 5,
            left: 5,
          },
        ],
        type: ''
      },
    ]
  },

  4: { // Exercise num
    unit : 'Option Song 7',
    id : 'SB6-OPS7-P121-E4',
    audio: '',
    video: '',
    component: T2,
    // titleImage: "img/FriendsPlus/Page121/E4/1.png",
    imgSize: '70%',
    titleQuestion: [{ color: '#BF2A32', num: '4', title: 'Answer the questions in exercise 3. Compare our answers with a partner.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  5: { // Exercise num
    unit : 'Option Song 7',
    id : 'SB6-OPS7-P121-E5',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page121/E5/1.png",
    imgSize: '70%',
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json;