import UI from '../../components/ExcerciseTypes/UseIt';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  1: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P46-E1',
    audio: '',
    video: '',
    component: D1,
    fontSize: 15,
    exerciseKey: 'img/FriendsPlus/Page46/E1/Key/answerKey.png',
    titleImage: '',
    // titleQuestion: [{ color: '#C02A32', num: '1', title: 'Complete the text with the words. There are five extra words.' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page46/E1/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page46/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page46/E1/3.jpg', input: true, answer: 'feathers' },
        { url: 'img/FriendsPlus/Page46/E1/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page46/E1/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page46/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page46/E1/7.jpg', input: true, answer: 'scales' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page46/E1/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page46/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page46/E1/10.jpg', input: true, answer: 'legs' },
        { url: 'img/FriendsPlus/Page46/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page46/E1/12.jpg', input: true, answer: 'wings' },
        { url: 'img/FriendsPlus/Page46/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page46/E1/14.jpg', input: true, answer: 'fins' },
        { url: 'img/FriendsPlus/Page46/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page46/E1/16.jpg', input: true, answer: 'gills' },
        { url: 'img/FriendsPlus/Page46/E1/17.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page46/E1/18.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page46/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page46/E1/20.jpg', input: true, answer: 'hair' },
        { url: 'img/FriendsPlus/Page46/E1/21.jpg' },
        { url: 'img/FriendsPlus/Page46/E1/22.jpg', input: true, answer: 'lungs' },
        { url: 'img/FriendsPlus/Page46/E1/23.jpg' },
        { url: 'img/FriendsPlus/Page46/E1/24.jpg', input: true, answer: 'backbones' },
        { url: 'img/FriendsPlus/Page46/E1/25.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page46/E1/26.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P46-E2',
    audio: 'img/FriendsPlus/Page46/E2/Audio/G6 SB Track 1.38.wav',
    video: '',
    component: D1,
    fontSize: 20,
    paddingLeft: 5,
    exerciseKey: 'img/FriendsPlus/Page46/E2/Key/answerKey.png',
    titleImage: '',
    titleQuestion: [{ color: '#C02A32', num: '2', title: 'Read and listen to the text. Check your answers in exercise 1.' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page46/E2/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page46/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page46/E2/3.jpg', input: true, answer: 'fins' },
        { url: 'img/FriendsPlus/Page46/E2/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page46/E2/5.jpg' },
      ],
      // [
      //     //     // Column
      //     { url: 'img/FriendsPlus/Page46/E2/6.jpg' },
      //     { url: 'img/FriendsPlus/Page46/E2/8.jpg' },
      // ],
      [
        // Column
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page46/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page46/E2/7.jpg', input: true, answer: 'gills' },
        { url: 'img/FriendsPlus/Page46/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page46/E2/11.jpg', input: true, answer: 'legs' },
        { url: 'img/FriendsPlus/Page46/E2/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page46/E2/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page46/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page46/E2/15.jpg', input: true, answer: 'scales' },
        { url: 'img/FriendsPlus/Page46/E2/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page46/E2/17.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page46/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page46/E2/19.jpg', input: true, answer: 'lungs' },
        { url: 'img/FriendsPlus/Page46/E2/20.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page46/E2/21.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page46/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page46/E2/23.jpg', input: true, answer: 'hair' },
        { url: 'img/FriendsPlus/Page46/E2/24.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page46/E2/25.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page46/E2/26.jpg' },
        { url: 'img/FriendsPlus/Page46/E2/27.jpg', input: true, answer: 'feathers' },
        { url: 'img/FriendsPlus/Page46/E2/28.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page46/E2/29.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page46/E2/30.jpg' },
        { url: 'img/FriendsPlus/Page46/E2/31.jpg', input: true, answer: 'wings' },
        { url: 'img/FriendsPlus/Page46/E2/32.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page46/E2/33.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P46-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseWidth: '50%',
    exerciseKey: 'img/FriendsPlus/Page46/E3/Key/1.png',
    // titleImage: "img/FriendsPlus/Page45/E1/1.png",
    titleQuestion: [{ color: '#C02A32', num: '3', title: 'Read the text again and answer the questions.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1.</span>Which type of animal doesn’t live on land? # </p>" +
          "<p><span style='font-weight:600'>2.</span>Which types of animals have got scales? # </p>" +
          "<p><span style='font-weight:600'>3.</span>Which type of animal hasn't got lungs when it's young? # </p>" +
          "<p><span style='font-weight:600'>4.</span>Which type of animal feeds its babies milk? # </p>" +
          "<p><span style='font-weight:600'>5.</span>Which type of animal is different when it’s older? # </p>",
        answer: ['Fish', 'Fish and reptiles/Fish/reptiles', 'Amphibians', 'Mammals', 'Amphibians'],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P46-E4',
    // audio: 'img/FriendsPlus/Page8/E2/Audio/08- Starter Unit - Track 08.mp3',
    video: '',
    component: T6,
    imgSize: '100%',
    inputSize: 650,
    exerciseKey: 'img/FriendsPlus/Page46/E4/Key/Key.png',
    titleImage: 'img/FriendsPlus/Page46/E4/title.jpg',
    titleQuestion: [{ num: '4', title: ' Which group are these animals from? Use the information to classify them.', color: '#DA0727' }],
    questionImage: [],
    questions: [
      {
        title: '<p><b>1</b> #. </p><p><b>2</b> #. </p><p><b>3</b> #. </p><p><b>4</b> #. </p>',
        answer: ['A crocodile is a reptile', 'A shark is a fish', 'A camel is a mammal', 'An eagle is a bird'],
        type: 'longAnwser'
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 3',
    id: 'SB6-U3-P46-E5',
    audio: '',
    video: '',
    component: UI,
    exerciseKey: '',
    //titleImage: 'http://',
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page46/E5/1.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
}
export default json