import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';


const json = {

  1: { // Exercise num

    unit: 'Option 5',
    id: 'SB6-OPE5-P114-E1',
    // audio: 'img/FriendsPlus/Page9/E2/Audio/07- Starter Unit - Track 07.mp3',
    video: '',
    component: T6,
    titleImage: 'img/FriendsPlus/Page114/E1/1.jpg',
    imgSize: '60%',
    inputSize: 600,
    exerciseKey: 'img/FriendsPlus/Page114/E1/Key/answerKey.jpg',
    titleQuestion: [{ color: '#E26D28', num: '1', title: 'Study the key phrases. Then say weights 1–6.' }],
    questionImage: [

    ],
    questions: [
      {
        title:

          '<p>1. 650g. #  </p>' +
          '<p>2. 2kg.  # </p>' +
          '<p>3. 100g. # </p>' +
          '<p>4. 3½kg. # </p>' +
          '<p>5. 5kg.  # </p>' +
          '<p>6. 500g. # </p>',
        answer: [
          "six hundred and fifty grams",
          "two kilos",
          "a hundred grams / one hundred grams",
          'three and a half kilos',
          'five kilos',
          'five hundred grams'
        ],
        type: 'longAnwser'

      },
    ]
  },

  2: { // Exercise num

    unit: 'Option 5',
    id: 'SB6-OPE5-P114-E2',
    audio: 'img/FriendsPlus/Page114/E2/Audio/G6 SB Track 3.12.mp3',
    video: '',
    component: T6,
    inputSize: 650,
    exerciseKey: 'img/FriendsPlus/Page114/E2/Key/answerKey.png',
    // titleImage: "img/FriendsPlus/Page79/E2/1.png",
    imgSize: '60%',
    titleQuestion: [{ num: '2', title: 'Listen and write the weights you hear. Then listen and repeat.', color: '#E26D28' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p style='font-family:OneStrokeScriptStd-Bold'> 1<sup>1</sup>&frasl;<sub>2</sub>kg </p>" +
          "<p><span style='font-weight:600'>1. </span> #. </p>" +
          "<p><span style='font-weight:600'>2. </span> #. </p>" +
          "<p><span style='font-weight:600'>3. </span> #. </p>" +
          "<p><span style='font-weight:600'>4. </span> #. </p>" +
          "<p><span style='font-weight:600'>5. </span> #. </p>" +
          "<p><span style='font-weight:600'>6. </span> #. </p>",
        answer: [
          'three quarters of a kilo',
          '900 grams',
          'seven and a half kilos',
          'one kilo',
          '125 grams',
          '825 grams'
        ],
        type: 'longAnwserInLine'
      },
    ]
  },

  3: { // Exercise num

    unit: 'Option 5',
    id: 'SB6-OPE5-P114-E3',
    audio: 'img/FriendsPlus/Page114/E3/Audio/G6 SB Track 3.13.mp3',
    video: '',
    component: DesignUnderLine,
    isHiddenCheck: true,
    totalInput: 4,
    // titleImage: 'img/FriendsPlus/Page12/E2/04.jpg',
    exerciseKey: 'img/FriendsPlus/Page114/E3/Key/answerKey.png',
    // titleQuestion: [{ num: '2', title: 'Check the meanings of the words in blue. Then choose the correct words in the quiz.' }],
    questionImage: [ // Row


      [
        { url: 'img/FriendsPlus/Page114/E3/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page114/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page114/E3/3.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page114/E3/4.jpg' },
        { url: 'img/FriendsPlus/Page114/E3/5.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page114/E3/6.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page114/E3/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page114/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page114/E3/9.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page114/E3/10.jpg' },
        { url: 'img/FriendsPlus/Page114/E3/11.jpg', input: 4, isCorrect: false },
        { url: 'img/FriendsPlus/Page114/E3/12.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page114/E3/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page114/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page114/E3/15.jpg', input: 5, isCorrect: false },
        { url: 'img/FriendsPlus/Page114/E3/16.jpg' },
        { url: 'img/FriendsPlus/Page114/E3/17.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page114/E3/18.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page114/E3/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page114/E3/20.jpg' },
        { url: 'img/FriendsPlus/Page114/E3/21.jpg', input: 7, isCorrect: false },
        { url: 'img/FriendsPlus/Page114/E3/22.jpg' },
        { url: 'img/FriendsPlus/Page114/E3/23.jpg', input: 8, isCorrect: false },
        { url: 'img/FriendsPlus/Page114/E3/24.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page114/E3/25.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  4: { // Exercise num

    unit: 'Option 5',
    id: 'SB6-OPE5-P114-E4',
    audio: 'img/FriendsPlus/Page114/E3/Audio/G6 SB Track 3.13.mp3',
    video: '',
    component: T6,
    titleImage: 'img/FriendsPlus/Page114/E4/1.jpg',
    imgSize: '90%',
    stylesTextInput: {
      textTransform: 'capitalize'
    },
    exerciseKey: 'img/FriendsPlus/Page114/E4/Key/answerKey.png',
    titleQuestion: [{ color: '#E26D28', num: '4', title: 'Study the key phrases. Listen to the conversation again and write True or False.Correct the false sentences.' }],
    questionImage: [

    ],
    questions: [
      {
        title:
          '<p><span style="font-weight:600">&emsp;1&ensp;</span> Emma wants to make a fruit salad. #  </p>' +
          '<p><span style="font-weight:600">&emsp;2&ensp;</span> First she wants half a kilo of apples. # </p>' +
          '<p><span style="font-weight:600">&emsp;3&ensp;</span> The strawberries are cheap. # </p>' +
          '<p><span style="font-weight:600">&emsp;4&ensp;</span> One pear costs 40p. # </p>' +
          '<p><span style="font-weight:600">&emsp;5&ensp;</span> Emma buys some green apples. # </p>' +
          '<p><span style="font-weight:600">&emsp;6&ensp;</span> Emma also wants three oranges.  # </p>' +
          '<p><span style="font-weight:600">&emsp;7&ensp;</span> The shopping costs £7.55 # </p>',
        answer: [
          "True",
          "False",
          "False",
          'False',
          'False',
          'False',
          'True'
        ],
        // type: 'longAnwser'

      },
    ]
  },
  5: { // Exercise num

    unit: 'Option 5',
    id: 'SB6-OPE5-P114-E5',
    audio: 'img/FriendsPlus/Page114/E5/Audio/G6 SB Track 3.14.mp3',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page114/E5/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title: '',
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  6: { // Exercise num

    unit: 'Option 5',
    id: 'SB6-OPE5-P114-E6',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page114/E6/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json;