import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import S2 from '../../components/ExcerciseTypes/Select/Select2';
import T1 from '../../components/ExcerciseTypes/TypeIn/T1';

const json = {
  1: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P80-E1',
    audio: '',
    video: '',
    component: S2,
    width: 250,
    exerciseKey: 'img/FriendsPlus/Page80/E1/Key/answerKey.jpg',
    titleImage: 'img/FriendsPlus/Page80/E1/title.jpg',
    titleQuestion: [{ num: '1', title: 'Match the words in the box with 1–5.', color: '#5BB4C3' }],
    image: 'img/FriendsPlus/Page80/E1/1.jpg',
    image1: 'img/FriendsPlus/Page80/E1/2.jpg',
    image2: 'img/FriendsPlus/Page80/E1/3.jpg',
    questions: [
      {
        no: "1",
        text: "basketball player",
      },
      {
        no: "2",
        text: "captain",
      },
      {
        no: "3",
        text: "stadium",
      },
      {
        no: "4",
        text: "fan",
      },
      {
        no: "5",
        text: "teams",
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P80-E2',
    audio: 'img/FriendsPlus/Page80/E2/Audio/G6 SB Track 2.20.WAV',
    video: '',
    component: T1,
    fontSize: 30,
    exerciseKey: 'img/FriendsPlus/Page80/E2/Key/answerKey.png',
    // inputSize: 500,
    imgSize: '100%',
    titleImage: "img/FriendsPlus/Page80/E2/title.jpg",
    titleQuestion: [{ num: '2', title: 'Read and listen to the text. Then answer the questions.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> When did the Việt Nam Football Federation begin? #. </p>" +
          "<p><span style='font-weight:600'>2</span> What competitions does the team play in? #. </p>" +
          "<p><span style='font-weight:600'>3</span> Where is the Mỹ Đình stadium? #. </p>" +
          "<p><span style='font-weight:600'>4</span> Can you only watch football at Mỹ Đình? #. </p>" +
          "<p><span style='font-weight:600'>5</span> Is basketball a new sport in Việt Nam? #. </p>" +
          "<p><span style='font-weight:600'>6</span> How did lots of people watch the 2019 basketball competition? #. </p>" +
          "<p><span style='font-weight:600'>7</span> What other things can you see at basketball games? #. </p>",

        answer: [
          '1989',
          'The FIFA World Cup and the AFC Asian Cup',
          'In Hà Nội',
          'No, you can watch athletics events and concerts',
          'Yes, it is',
          'On the internet',
          'Music and dancing'
        ],
        type: 'longAnwser'
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P80-E3',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page80/E3/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P80-E4',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page80/E4/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json