import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import T1 from "../../components/ExcerciseTypes/TypeIn/T1";
import T2 from "../../components/ExcerciseTypes/TypeIn/T2";

const json = {
  1: {
    // Exercise num
    unit: "Unit 4",
    id: "SB6-U4-P50-E1",
    audio: "",
    video: "",
    component: T6,
    titleImage: "img/FriendsPlus/Page50/E1/1.png",
    exerciseKey: "img/FriendsPlus/Page50/E1/Key/answerKey.png",
    titleQuestion: [
      {
        color: "#D32931",
        num: "1",
        title:
          "Read the project. Where are the schools? Is the writer positive or negative about them?",
      },
    ],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>School 1</span> # </p>" +
          "<p><span style='font-weight:600'>School 2</span> # </p>" +
          "<p><span style='font-weight:600'>School 3</span> # </p>" +
          "<p><span style='font-weight:600'>School 4</span> # </p>",
        answer: [
          "Bangladesh, positive",
          "Britain, negative",
          "Việt Nam, positive",
          "Ireland, positive",
        ],
        type: "longAnwser",
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 4",
    id: "SB6-U4-P50-E2",
    audio: "img/FriendsPlus/Page50/E2/Audio/G6 SB Track 1.41.WAV",
    video: "",
    component: T1,
    exerciseKey: "img/FriendsPlus/Page50/E2/Key/1.png",
    titleImage: "img/FriendsPlus/Page50/E1/1.png",
    titleQuestion: [
      {
        color: "#D32931",
        num: "2",
        title: "Read and listen to the project again and answer the questions.",
      },
    ],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1.</span> When is transport a problem in Bangladesh? #. </p>" +
          "<p><span style='font-weight:600'>2.</span> What is a boarding school? #. </p>" +
          "<p><span style='font-weight:600'>3.</span> What is another learning way for Vietnamese students? #. </p>",
        answer: [
          "When there's a lot of rain",
          "A school where students study, eat and sleep",
          "Homeschooling",
        ],
        type: "longAnwser",
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 4",
    id: "SB6-U4-P50-E3",
    audio: "",
    video: "",
    component: T2,
    titleImage: "img/FriendsPlus/Page50/E3/1.png",
    // imgSize:'60%',
    // titleQuestion: [{ color: '#D32931', num: '2', title: 'Read and listen to the project again and answer the questions.' }],
    questionImage: [],
    questions: [
      {
        title: "",
        answer: [],
        type: "longAnwser",
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 4",
    id: "SB6-U4-P50-E4",
    audio: "",
    video: "",
    component: T2,
    titleImage: "img/FriendsPlus/Page50/E4/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: "longAnwser",
      },
    ],
  },
};
export default json;
