import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import WordsBox from '../../components/ExcerciseTypes/Design/WordBox';
import MC4 from '../../components/ExcerciseTypes/MultipleChoice/MC4';


const json = {
  1: { // Exercise num
    unit: 'Progress Review 4',
    id: 'SB6-PV4-P106-E1',
    audio: '',
    video: '',
    component: WordsBox,
    isAutofocus: true,
    fontSize: 32,
    paddingLeft: 0,
    // height: 30,
    exerciseKey: 'img/FriendsPlus/Page106/E1/Key/Key.png',
    titleImage: '',
    // titleQuestion: [{ num: '1', title: 'Complete the words.', color: '#4f3896' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E1/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page106/E1/3.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page106/E1/4.jpg', input: true, answer: 'i' },
        { url: 'img/FriendsPlus/Page106/E1/5.jpg', input: true, answer: 'g' },
        { url: 'img/FriendsPlus/Page106/E1/6.jpg', input: true, answer: 'h' },
        { url: 'img/FriendsPlus/Page106/E1/7.jpg', input: true, answer: 't' },
        { url: 'img/FriendsPlus/Page106/E1/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page106/E1/10.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page106/E1/11.jpg', input: true, answer: 'l' },
        { url: 'img/FriendsPlus/Page106/E1/12.jpg', input: true, answer: 'l' },
        { url: 'img/FriendsPlus/Page106/E1/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E1/14.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page106/E1/16.jpg', input: true, answer: 'u' },
        { url: 'img/FriendsPlus/Page106/E1/17.jpg', input: true, answer: 'r' },
        { url: 'img/FriendsPlus/Page106/E1/18.jpg', input: true, answer: 'l' },
        { url: 'img/FriendsPlus/Page106/E1/19.jpg', input: true, answer: 'y' },
        { url: 'img/FriendsPlus/Page106/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page106/E1/21.jpg', input: true, answer: 'l' },
        { url: 'img/FriendsPlus/Page106/E1/22.jpg', input: true, answer: 'o' },
        { url: 'img/FriendsPlus/Page106/E1/23.jpg', input: true, answer: 'n' },
        { url: 'img/FriendsPlus/Page106/E1/24.jpg', input: true, answer: 'd' },
        { url: 'img/FriendsPlus/Page106/E1/25.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page106/E1/26.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E1/27.jpg' },
        { url: 'img/FriendsPlus/Page106/E1/28.jpg', input: true, answer: 'r' },
        { url: 'img/FriendsPlus/Page106/E1/29.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page106/E1/30.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page106/E1/31.jpg', input: true, answer: 'n' },
        { url: 'img/FriendsPlus/Page106/E1/32.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E1/33.jpg' },
        { url: 'img/FriendsPlus/Page106/E1/34.jpg', input: true, answer: 'i' },
        { url: 'img/FriendsPlus/Page106/E1/35.jpg', input: true, answer: 'g' },
        { url: 'img/FriendsPlus/Page106/E1/36.jpg', input: true, answer: 'h' },
        { url: 'img/FriendsPlus/Page106/E1/37.jpg', input: true, answer: 't' },
        { url: 'img/FriendsPlus/Page106/E1/38.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E1/39.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E1/40.jpg' },
        { url: 'img/FriendsPlus/Page106/E1/41.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page106/E1/42.jpg', input: true, answer: 'l' },
        { url: 'img/FriendsPlus/Page106/E1/43.jpg', input: true, answer: 'd' },
        { url: 'img/FriendsPlus/Page106/E1/44.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E1/45.jpg' },
        { url: 'img/FriendsPlus/Page106/E1/46.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page106/E1/47.jpg', input: true, answer: 'c' },
        { url: 'img/FriendsPlus/Page106/E1/48.jpg', input: true, answer: 'k' },
        { url: 'img/FriendsPlus/Page106/E1/49.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E1/50.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E1/51.jpg' },
        { url: 'img/FriendsPlus/Page106/E1/52.jpg', input: true, answer: 'v' },
        { url: 'img/FriendsPlus/Page106/E1/53.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page106/E1/54.jpg', input: true, answer: 'r' },
        { url: 'img/FriendsPlus/Page106/E1/55.jpg', input: true, answer: 'w' },
        { url: 'img/FriendsPlus/Page106/E1/56.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page106/E1/57.jpg', input: true, answer: 'i' },
        { url: 'img/FriendsPlus/Page106/E1/58.jpg', input: true, answer: 'g' },
        { url: 'img/FriendsPlus/Page106/E1/59.jpg', input: true, answer: 'h' },
        { url: 'img/FriendsPlus/Page106/E1/60.jpg', input: true, answer: 't' },
        { url: 'img/FriendsPlus/Page106/E1/61.jpg' },
        { url: 'img/FriendsPlus/Page106/E1/62.jpg', input: true, answer: 'l' },
        { url: 'img/FriendsPlus/Page106/E1/63.jpg', input: true, answer: 'i' },
        { url: 'img/FriendsPlus/Page106/E1/64.jpg', input: true, answer: 'm' },
        { url: 'img/FriendsPlus/Page106/E1/65.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E1/66.jpg' },
        { url: 'img/FriendsPlus/Page106/E1/67.jpg', input: true, answer: 'o' },
        { url: 'img/FriendsPlus/Page106/E1/68.jpg', input: true, answer: 'u' },
        { url: 'img/FriendsPlus/Page106/E1/69.jpg', input: true, answer: 'n' },
        { url: 'img/FriendsPlus/Page106/E1/70.jpg', input: true, answer: 'd' },
        { url: 'img/FriendsPlus/Page106/E1/71.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E1/72.jpg' },
        { url: 'img/FriendsPlus/Page106/E1/73.jpg', input: true, answer: 'l' },
        { url: 'img/FriendsPlus/Page106/E1/74.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page106/E1/75.jpg', input: true, answer: 's' },
        { url: 'img/FriendsPlus/Page106/E1/76.jpg', input: true, answer: 's' },
        { url: 'img/FriendsPlus/Page106/E1/77.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page106/E1/78.jpg', input: true, answer: 's' },
        { url: 'img/FriendsPlus/Page106/E1/79.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  3: { // Exercise num
    unit: 'Progress Review 4',
    id: 'SB6-PV4-P106-E3',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page106/E3/Key/1.png',
    titleImage: '',
    titleQuestion: [{ num: '3', title: 'Write affirmative and negative sentences using the past simple.', color: '#4f3896' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E3/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page106/E3/3.jpg', input: true, answer: 'I saw a nice photo of you.' },
        { url: 'img/FriendsPlus/Page106/E3/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E3/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page106/E3/7.jpg', input: true, answer: "We didn't enjoy that boring film." },
        { url: 'img/FriendsPlus/Page106/E3/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E3/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E3/10.jpg' },
        { url: 'img/FriendsPlus/Page106/E3/11.jpg', input: true, answer: 'She taught her grandmother about computers.' },
        { url: 'img/FriendsPlus/Page106/E3/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E3/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page106/E3/15.jpg', input: true, answer: 'You ate a big pizza last night.' },
        { url: 'img/FriendsPlus/Page106/E3/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E3/17.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E3/18.jpg' },
        { url: 'img/FriendsPlus/Page106/E3/19.jpg', input: true, answer: "He didn't read that old book." },
        { url: 'img/FriendsPlus/Page106/E3/20.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E3/21.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Progress Review 4',
    id: 'SB6-PV4-P106-E2',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page106/E2/Key/answerKey.png',
    titleImage: '',
    titleQuestion: [{ num: '2', title: 'Complete the sentences using the past simple form of the verbs.', color: '#57489E' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E2/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page106/E2/3.jpg', input: true, answer: 'had' },
        { url: 'img/FriendsPlus/Page106/E2/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E2/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page106/E2/7.jpg', input: true, answer: 'took' },
        { url: 'img/FriendsPlus/Page106/E2/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E2/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page106/E2/11.jpg', input: true, answer: 'grew' },
        { url: 'img/FriendsPlus/Page106/E2/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E2/13.jpg' },
        { url: 'img/FriendsPlus/Page106/E2/14.jpg', input: true, answer: 'changed' },
        { url: 'img/FriendsPlus/Page106/E2/15.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E2/16.jpg' },
        { url: 'img/FriendsPlus/Page106/E2/17.jpg', input: true, answer: 'collected' },
        { url: 'img/FriendsPlus/Page106/E2/18.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E2/19.jpg' },
        { url: 'img/FriendsPlus/Page106/E2/20.jpg', input: true, answer: 'made' },
        { url: 'img/FriendsPlus/Page106/E2/21.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E2/22.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E2/23.jpg' },
        { url: 'img/FriendsPlus/Page106/E2/24.jpg', input: true, answer: 'gave' },
        { url: 'img/FriendsPlus/Page106/E2/25.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E2/26.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page106/E2/27.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

  4: { // Exercise num
    unit: 'Progress Review 4',
    id: 'SB6-PV4-P106-E4',
    audio: 'img/FriendsPlus/Page106/E4/Audio/G6 SB Track 2.40.WAV',
    video: '',
    component: MC4,
    exerciseKey: 'img/FriendsPlus/Page106/E4/Key/answerKey.png',
    titleQuestion: [{ num: '4', title: 'Listen and choose the correct answers.', color: '#40397B' }],
    //titleImage: 'http://',
    questionImage: [ // Row
      [
        // Column
        { url: 'http://' },
        { url: 'http://', input: true },
        { url: 'http://' },
      ],
    ],
    questions: [
      {
        titleStrong: 'Where was Jeanne Calment born?',
        title: 'She was born in the ....... of France.',
        answers: [
          { image: "", isCorrect: false, right: "", text: "north" },
          { image: "", isCorrect: true, right: "", text: "south" },
          { image: "", isCorrect: false, right: "", text: "east" },
        ],
        no: 1,
        question: '',
        type: '',
      },
      {
        titleStrong: 'Why was she an amazing person?',
        title: 'She was special because she was the ....... person who ever lived.',
        answers: [
          { image: "", isCorrect: false, right: "", text: "tallest" },
          { image: "", isCorrect: false, right: "", text: "richest" },
          { image: "", isCorrect: true, right: "", text: "oldest" },
        ],
        no: 2,
        question: '',
        type: '',
      },
      {
        titleStrong: 'What type of food did she eat a lot?',
        title: 'She ate a lot of ....... .',
        answers: [
          { image: "", isCorrect: false, right: "", text: "pasta" },
          { image: "", isCorrect: false, right: "", text: "crisps" },
          { image: "", isCorrect: true, right: "", text: "chocolate" },
        ],
        no: 3,
        question: '',
        type: '',
      },
      {
        titleStrong: 'What did she appear in?',
        title: 'She appeared in a film about ....... .',
        answers: [
          { image: "", isCorrect: false, right: "", text: "a singer" },
          { image: "", isCorrect: true, right: "", text: "an artist" },
          { image: "", isCorrect: false, right: "", text: "a teacher" },
        ],
        no: 4,
        question: '',
        type: '',
      },
      {
        titleStrong: 'When did she die?',
        title: `In ........ .`,
        answers: [
          { image: "", isCorrect: false, right: "", text: "1995" },
          { image: "", isCorrect: false, right: "", text: "1996" },
          { image: "", isCorrect: true, right: "", text: "1997" },
        ],
        no: 5,
        question: '',
        type: '',
      },
    ]
  },

}
export default json