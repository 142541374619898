import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import url from '../../configs/url';


const json = {
  1: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P92-E1',
    audio: 'img/FriendsPlus/Page92/E1/Audio/G6 SB Track 2.29.mp3',
    video: 'https://cloud.softech.vn/storage/SachSo/Videos/P92E1_2.mp4',
    videoSub: url.cdnUrl + '/P92E1_2.vtt',
    component: D1,
    fontSize: 30,
    exerciseKey: 'img/FriendsPlus/Page92/E1/Key/answerkey.jpg',
    titleImage: '',
    // titleQuestion: [{ num: '2', title: 'Complete the text with the words.', color: '#553f9f' }],
    questionImage: [ // Row

      [
        // Column
        { url: 'img/FriendsPlus/Page92/E1/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page92/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/3.jpg', input: true, answer: 'c' },
        { url: 'img/FriendsPlus/Page92/E1/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page92/E1/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page92/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/7.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page92/E1/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page92/E1/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page92/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/11.jpg', input: true, answer: 'd' },
        { url: 'img/FriendsPlus/Page92/E1/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page92/E1/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page92/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page92/E1/15.jpg', input: true, answer: 'b' },
        { url: 'img/FriendsPlus/Page92/E1/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page92/E1/17.jpg' },
      ],

    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P92-E2',
    audio: 'img/FriendsPlus/Page92/E1/Audio/G6 SB Track 2.29.mp3',
    video: 'https://cloud.softech.vn/storage/SachSo/Videos/P92E1_2.mp4',
    videoSub: url.cdnUrl + '/P92E1_2.vtt',
    component: T6,
    regex: /(\W+)/g,
    exerciseKey: 'img/FriendsPlus/Page92/E2/Key/answerKey.png',
    // inputSize: 500,
    titleImage: "img/FriendsPlus/Page92/E2/1.png",
    imgSize: '80%',
    // inputSize: 400,
    // textCenter: true,
    titleQuestion: [{ color: '#BF215C', num: '2', title: 'Look at the Key Phrases. Which does the interviewer use and which does the pop star use? Then watch or listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>Interviewer:</span> # </p>" +
          "<p><span style='font-weight:600'>Pop star:</span> # </p>",
        answer: [
          "1, 4, 6",
          "2, 3, 5",
        ],
        type: 'longAnwser'
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P92-E3',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page92/E3/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P92-E4',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page92/E4/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 7',
    id: 'SB6-U7-P92-E5',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page92/E5/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json