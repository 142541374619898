import S7 from '../../components/ExcerciseTypes/Select/Select7';
import UI from '../../components/ExcerciseTypes/UseIt';


const json = {
  1: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P72-E1',
    audio: 'img/FriendsPlus/Page72/E1/Audio/G6 SB Track 2.12.mp3',
    video: '',
    component: S7,
    exerciseKey: 'img/FriendsPlus/Page72/E1/Key/answerKey.jpg',
    titleImage: 'img/FriendsPlus/Page72/E1/title.jpg',
    width: 180,
    titleQuestion: [{ num: '1', title: 'Match the words in the box with pictures 1–16. Then listen and check. Which sports do you like?', color: '#115D98' }],
    questions: [
      {
        no: "1",
        text: "football",
        image: "img/FriendsPlus/Page72/E1/03.jpg",
      },
      {
        no: "2",
        text: "horse-riding",
        image: "img/FriendsPlus/Page72/E1/04.jpg",
      },
      {
        no: "3",
        text: "tennis",
        image: "img/FriendsPlus/Page72/E1/05.jpg",
      },
      {
        no: "4",
        text: "swimming",
        image: "img/FriendsPlus/Page72/E1/06.jpg",
      },
      {
        no: "5",
        text: "athletics",
        image: "img/FriendsPlus/Page72/E1/08.jpg",
      },
      {
        no: "6",
        text: "cycling",
        image: "img/FriendsPlus/Page72/E1/09.jpg",
      },
      {
        no: "7",
        text: "climbing",
        image: "img/FriendsPlus/Page72/E1/10.jpg",
      },
      {
        no: "8",
        text: "gymnastics",
        image: "img/FriendsPlus/Page72/E1/11.jpg",
      },
      {
        no: "9",
        text: "skiing",
        image: "img/FriendsPlus/Page72/E1/12.jpg",
      },
      {
        no: "10",
        text: "volleyball",
        image: "img/FriendsPlus/Page72/E1/13.jpg",
      },
      {
        no: "11",
        text: "golf",
        image: "img/FriendsPlus/Page72/E1/14.jpg",
      },
      {
        no: "12",
        text: "hockey",
        image: "img/FriendsPlus/Page72/E1/15.jpg",
      },
      {
        no: "13",
        text: "wrestling",
        image: "img/FriendsPlus/Page72/E1/16.jpg",
      },
      {
        no: "14",
        text: "sailing",
        image: "img/FriendsPlus/Page72/E1/17.jpg",
      },
      {
        no: "15",
        text: "rugby",
        image: "img/FriendsPlus/Page72/E1/18.jpg",
      },
      {
        no: "16",
        text: "basketball",
        image: "img/FriendsPlus/Page72/E1/19.jpg",
      },
    ]
  },

  2: { // Exercise num
    unit: 'Unit 6',
    id: 'SB6-U6-P72-E2',
    audio: 'img/FriendsPlus/Page72/E1/Audio/G6 SB Track 2.12.mp3',
    video: '',
    component: UI,
    // exerciseKey : 'img/FriendsPlus/Page72/E1/Key/1.jpg',
    // titleImage: 'img/FriendsPlus/Page72/E1/title.jpg',
    titleQuestion: [{ num: '2', title: ' Check the meanings of the words in blue in the Sports quiz. Then do the quiz in pairs or teams.', color: '#0f70b8' }],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page72/E2/1.png' },
      ]
    ]
  },

}

export default json;