import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 4',
    id: 'SB6-U4-P52-E1',
    audio: 'img/FriendsPlus/Page52/E1/Audio/G6 SB Track 1.43.mp3',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page52/E1/Key/answerKey.png',
    //titleImage: 'http://',
    // titleQuestion: [{ num: '1', title: 'Look at the quiz. Check the meanings of the verbs in blue. Match questions 1–10 to pictures a–j. Then listen and check.' }],
    typeInput: 'center',
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page52/E1/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page52/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/3.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page52/E1/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page52/E1/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page52/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/7.jpg', input: true, answer: 'h' },
        { url: 'img/FriendsPlus/Page52/E1/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page52/E1/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page52/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/11.jpg', input: true, answer: 'd' },
        { url: 'img/FriendsPlus/Page52/E1/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page52/E1/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page52/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/15.jpg', input: true, answer: 'c' },
        { url: 'img/FriendsPlus/Page52/E1/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page52/E1/17.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page52/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/19.jpg', input: true, answer: 'a' },
        { url: 'img/FriendsPlus/Page52/E1/20.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page52/E1/21.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page52/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/23.jpg', input: true, answer: 'i' },
        { url: 'img/FriendsPlus/Page52/E1/24.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page52/E1/25.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page52/E1/26.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/27.jpg', input: true, answer: 'j' },
        { url: 'img/FriendsPlus/Page52/E1/28.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page52/E1/29.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page52/E1/30.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/31.jpg', input: true, answer: 'b' },
        { url: 'img/FriendsPlus/Page52/E1/32.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page52/E1/33.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page52/E1/34.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/35.jpg', input: true, answer: 'f' },
        { url: 'img/FriendsPlus/Page52/E1/36.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page52/E1/37.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page52/E1/38.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/39.jpg', input: true, answer: 'g' },
        { url: 'img/FriendsPlus/Page52/E1/40.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page52/E1/41.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 4',
    id: 'SB6-U4-P52-E2',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page52/E2/1.png",
    // titleQuestion: [{ num: '1', title: 'Check the meanings of the words in the box. Then describe the photo using the words.', color: '#009241' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>" +
          "<p>#</p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 4',
    id: 'SB6-U4-P52-E3',
    audio: 'img/FriendsPlus/Page52/E3/Audio/G6 SB Track 1.44.mp3',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page52/E3/Key/1.png',
    //titleImage: 'http://',
    titleQuestion: [{ num: '3', title: 'Read the introduction. Then listen to the interviews. What are the people learning? Use the languages in the box.' }],
    // typeInput : 'center',
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page52/E3/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page52/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page52/E3/3.jpg', input: true, answer: 'English' },
        { url: 'img/FriendsPlus/Page52/E3/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page52/E3/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page52/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page52/E3/7.jpg', input: true, answer: 'Kanji' },
        { url: 'img/FriendsPlus/Page52/E3/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page52/E3/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page52/E3/10.jpg' },
        { url: 'img/FriendsPlus/Page52/E3/11.jpg', input: true, answer: 'Russian and Arabic' },
        { url: 'img/FriendsPlus/Page52/E3/12.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 4',
    id: 'SB6-U4-P52-E4',
    audio: 'img/FriendsPlus/Page52/E3/Audio/G6 SB Track 1.44.mp3',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page52/E4/Key/1.png',
    titleImage: "img/FriendsPlus/Page52/E4/1.png",
    imgSize: '60%',
    titleQuestion: [{ color: '#E26D28', num: '4', title: 'Listen again and answer the questions for Mario, Mark and Hannah.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p><span style='font-weight:600'>1</span> What is he / she doing now?.</p>" +
          "<p>#.</p>" +
          "<p>#.</p>" +
          "<p>#.</p>" +
          "<p><span style='font-weight:600'>2</span> What does he / she think is difficult?</p>" +
          "<p>#.</p>" +
          "<p>#.</p>" +
          "<p>#.</p>",
        answer: [
          'Mario is watching TV / Mark is checking new vocabulary on the internet / Hannah is revising for her exams',
          'Mario is watching TV / Mark is checking new vocabulary on the internet / Hannah is revising for her exams',
          'Mario is watching TV / Mark is checking new vocabulary on the internet / Hannah is revising for her exams',
          "Mario thinks listening is difficult / Mark thinks reading and writing are difficult / Hannah thinks pronunciation is difficult",
          "Mario thinks listening is difficult / Mark thinks reading and writing are difficult / Hannah thinks pronunciation is difficult",
          "Mario thinks listening is difficult / Mark thinks reading and writing are difficult / Hannah thinks pronunciation is difficult",
        ],
        type: 'longAnwser'
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 4',
    id: 'SB6-U4-P52-E5',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page52/E5/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
  6: { // Exercise num
    unit: 'Unit 4',
    id: 'SB6-U4-P52-E6',
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page52/E6/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },

}
export default json
